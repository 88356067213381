import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { Button, Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { GrFormPrevious } from "react-icons/gr";
import "../css/pagination.css";
import { MdOutlineNavigateNext } from "react-icons/md";
import { Server2 } from "../../redux/actions/auth/auth.actions";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import { BsCaretDownFill } from "react-icons/bs";

const PER_PAGE = 99;

const Ecommerce = ({ setPopupStatus, setPopupData }) => {
  const userData = useSelector((state) => state?.auth?.user?.user);
  const navigate = useNavigate();
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [q, setQ] = useState("");

  function searchData() {
    // return activities;
    return posts.filter((user) => {
      if (q === "") {
        return user;
      } else if (user?.status?.fname.toLowerCase().includes(q.toLowerCase())) {
        return user;
      } else if (user?.status?.lname.toLowerCase().includes(q.toLowerCase())) {
        return user;
      } else if (user?.status?.status_type.toLowerCase().includes(q.toLowerCase())) {
        return user;
      }
    });
  }
  // API'S MakeDeletionApproved Starts
  const getPosts = () => {
    const data = {
      id: userData?._id,
    };
    let config = {
      method: "get",
      url: Server2 + "/status/getStatusByUserType?type=true",
      data: data,
    };

    axios(config)
      .then(function (response) {
        response.data.Status.sort(
          (b, c) => new Date(c.status.time) - new Date(b.status.time)
        );
        console.log(response.data.Status);
        setPosts(response.data.Status);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error, "error Delete User");
      });
  };
  const handleDeletePost = (e) => {
    var data = JSON.stringify({
      id: e,
      from: userData._id,
      disable:true,
    });
    var config = {
      method: "POST",
      url: Server2 + "/status/delete-status",
      data: data,
      headers: { "Content-Type": "application/json" },
    };
    axios(config)
      .then((response) => {
        getPosts();
      })
      .catch((error) => {
        console.log("er", error);
      });
  };
  // API'S Approved request Starts
  const handelDeleteRequest = (e) => {
    console.log(e);
    const data = {
      id: e,
    };
    let config = {
      method: "post",
      url: Server2 + "/admin/disable-user",
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data, "i am res---");
        getPosts();
      })
      .catch(function (error) {
        console.log(error, "error Delete User");
      });
  };

  useEffect(() => {
    setLoading(true);
    getPosts();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //pagination function
  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }

  //pagination
  const offset = currentPage * PER_PAGE;
  const currentPageData =
    posts.length === 0
      ? null
      : searchData(posts).slice(offset, offset + PER_PAGE).map((user, index) => (
          <tbody key={index}>
            <tr>
              <td
                onClick={() =>
                  navigate("/Profiless", {
                    state: {
                      item: user?.user,
                    },
                  })
                }
                style={{ cursor: "pointer" }}
              >
                <div className="d-flex px-2 py-1">
                  <div>
                    <img
                      src={
                        user?.status?.profile_pic
                          ? user?.status?.profile_pic
                          : "/img/upload-photo.png"
                      }
                      className="avatar avatar-sm me-3"
                      alt="user1"
                    />
                  </div>
                  <div className="d-flex flex-column justify-content-center">
                    <h6 className="mb-0 text-sm text-capitalize ">
                      {user?.status?.fname} {user?.status?.lname}
                    </h6>
                    <p className="text-xs text-secondary mb-0">
                      {user?.status?.email}
                    </p>
                  </div>
                </div>
              </td>
              <td>
                <p className="align-middle ms-4 text-capitalize">
                  {user?.status?.status_type}
                </p>
              </td>
              <td>
                <p className="align-middle ">
                  {moment(user?.status?.time).format("MMMM Do, YYYY  h:mm A")}
                </p>
              </td>
              <td className="allign-middle">
                <div className="d-flex justify-content-center">
                  <Button
                    style={{ background: "#6148a1", border: "none" }}
                    onClick={() => {
                      setPopupData(user?.status?._id);
                      setPopupStatus(true);
                    }}
                  >
                    View Post
                  </Button>
                  {/* <Button style={{ background: '#6148a1', border: 'none' }} onClick={() => handelAprovedRequest(user)}></Button> */}
                  <Dropdown className="border-0 rounded-2 add-btn fw-bold ms-2">
                    <Dropdown.Toggle
                      className="border-0"
                      id="dropdown-basic"
                      aria-expanded="false"
                      style={{ backgroundColor: "#6148a1" }}
                    >
                      <button
                        type="button"
                        className="bg-transparent border-0 text-white"
                        data-bs-toggle="button"
                      >
                        <spam className="d-inline postition-relative text-white">
                          Take Action
                        </spam>
                      </button>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="w-100 text-center">
                      <Dropdown.Item
                        eventKey="1"
                        onClick={() => {
                          handleDeletePost(user?.status);
                        }}
                        style={{ fontSize: 13 }}
                      >
                        Delete Post
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey="1"
                        onClick={() => {
                          handelDeleteRequest(user?.user);
                        }}
                        style={{ fontSize: 13 }}
                      >
                        Disable User Account
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </td>
            </tr>
          </tbody>
        ));

  const pageCount = Math.ceil(searchData().length / PER_PAGE);

  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center">
          <div
            className="spinner-border"
            role="status"
            style={{ width: "6rem", height: "6rem", color: "#6148A1" }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <>
        <div className="container-fluid  ">
            <div className="row ">
              <div className="col-12 ">
                <div className="card  ">
                  <div className="card-header pb-0 d-flex justify-content-between">
                    <h6 className="pt-2"> E-Commerce </h6>
                    <div className="wrapper  ">
                      <div className="search-wrappe  ">
                        <label htmlFor="search-form">
                          <input
                            type="search"
                            name="search-form"
                            id="search-form"
                            className="form-control border-0 rounded  admin_search_bar"
                            style={{ backgroundColor: "#ECECEC" }}
                            placeholder=" Type to Search ..."
                            value={q}
                            onChange={(e) => setQ(e.target.value)}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div
                    className="card-body px-0 pt-0 pb-2 task"
                    style={{ height: "73vh", overflow: "auto" }}
                  >
                    <div className="table-responsive p-0">
                      <table className="table align-items-center mb-0">
                        <thead>
                          <tr>
                            <th className="text-uppercase">User</th>
                            <th className="">
                              <Dropdown className="">
                                <Dropdown.Toggle
                                  className=" border-0"
                                  variant=""
                                  id="dropdown-basic"
                                  aria-expanded="false"
                                >
                                  <span
                                    className="fs-6 fw-bold text-uppercase"
                                    style={{ color: "#67748e" }}
                                  >
                                  Type{" "}
                                  <BsCaretDownFill size={12}/>
                                  </span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-menu">
                                  <Dropdown.Item
                                    onClick={() => {
                                      setQ("");
                                    }}
                                  >
                                    <span className="fw-bold">All</span>
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => {
                                      setQ("text");
                                    }}
                                  >
                                    <span className="fw-bold">Text</span>
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => {
                                      setQ("image");
                                    }}
                                  >
                                    <span className="fw-bold">Images</span>
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => {
                                      setQ("video");
                                    }}
                                  >
                                    <span className="fw-bold">Video</span>
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </th>
                            <th className="text-uppercase">Created Date</th>
                            <th className="text-uppercase text-center">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        {currentPageData}
                      </table>
                    </div>

                    {searchData().length > 99 ? (
                      <div
                      className=" mt-4"
                      style={{
                        padding: 2,
                        boxSizing: "border-box",
                        width: "100%",
                      }}
                    >
                      <ReactPaginate
                        onPageChange={handlePageClick}
                        activeClassName={"item active "}
                        breakClassName={"item break-me "}
                        breakLabel={"..."}
                        containerClassName={"pagination"}
                        disabledClassName={"disabled-page"}
                        marginPagesDisplayed={2}
                        nextClassName={"item next "}
                        nextLabel={
                          <MdOutlineNavigateNext
                            style={{ fontSize: 18, width: 150 }}
                          />
                        }
                        pageCount={pageCount}
                        pageClassName={"item pagination-page "}
                        pageRangeDisplayed={2}
                        previousClassName={"item previous"}
                        previousLabel={
                          <GrFormPrevious
                            style={{ fontSize: 18, width: 150 }}
                          />
                        }
                      />
                    </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Ecommerce;
