import { Formik, Form } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col } from "reactstrap";
import * as Yup from "yup";
import { useDispatch, useSelector} from "react-redux";
import {
  loadUser,
  DashCitySelect
} from "../../../redux/actions/auth/auth.actions";
import { useNavigate } from "react-router-dom";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { IoMdLock } from "react-icons/io";
import { MdEmail } from "react-icons/md";

const LoginPage = ({ setForgotFalg }) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const linkUser = useSelector((state) => state?.auth?.linkuser);
  const [passwordShown, setPasswordShown] = useState(false);
  const [errorMsg, setError] = useState("");

  const handleSubmit = (values) => {
    let value = {
      email: values.email.toLowerCase(),
      password: values.password,
    };
    dispatch(loadUser(value)).then((res) => {
      if (res?.status === 200) {
        res?.user?.deactivated_by_admin === true? setError("Your account has been disabled by the administrator."): navigate(res?.user?.type === false ? "/firstStep" : "/businessStep");
        // Steps Navigate Bussiness
        if (res?.user?.type === true) {
         if (res?.user?.deactivated_by_admin === true) {
            setError("Your account has been disabled by the administrator.");
        }else if (res?.user?.activation_status === 0) {
            navigate("/businessStep");
          } else if (res?.user?.activation_status === 1) {
            navigate("/secondStep");
          } else if (res?.user?.activation_status === 2) {
            navigate("/thirdStep");
          } else if (res?.user?.activation_status === 3 && (res?.user?.agreedToTAC === undefined || res?.user?.agreedToTAC === false)) {
            console.log("i am working to third")
            navigate("/thirdStep");
          } else if (res?.user?.subscription === undefined) {
            navigate("/fourthStep");
          } else {
            if (new Date() > new Date(res?.user?.subscription?.end_date)) {
              navigate("/fourthStep");
            } else {
              const newCity = res?.user?.location?.extraCityToFollow0?.cityName.split(",");
              dispatch(DashCitySelect(newCity[0]))
              navigate("/home", {
                state: {
                  coord: null,
                },
              });
            }
          }
        }
        // Steps Navigate Personal
        if (res?.user?.type === false) {
          if (res?.user?.deactivated_by_admin === true) {
            setError("Your account has been disabled by the administrator.");
          }else if (res?.user?.activation_status === 0) {
            navigate("/firstStep");
          } else if (res?.user?.activation_status === 1) {
            navigate("/secondStep");
          } else if (res?.user?.activation_status === 2) {
            navigate("/thirdStep");
          } else if (res?.user?.activation_status === 3 && (res?.user?.agreedToTAC === undefined || res?.user?.agreedToTAC === false)) {
            console.log("i am working to third")
            navigate("/thirdStep");
          } else if (res?.user?.is_deactivated === true) {
            navigate("/setting", {
              state: {
                item: "2",
              },
            });
          } else {
            console.log("going to else home navigate")
            const newCity = res?.user?.location?.extraCityToFollow0?.cityName.split(",");
            dispatch(DashCitySelect(newCity[0]))
            navigate("/home", {
              state: {
                coord: [0, 0],
              },
            });
          }
        }
      } else if (res?.status === 201) {
        if (res?.status) {
          setError(res?.message);
        }
      } else if (res?.status === 403) {
        if (res?.status) {
          setError(res?.message);
        }
      }
    });
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string("Please fill in the field Thank You").required(),
    email: Yup.string("Please fill in the field Thank You").email().required(),
  });
  const [initialValues, setInitialValues] = useState({ email: '', password: '' });

  useEffect(() => {
    if (linkUser?.user?.email) {
      setInitialValues({ email: linkUser.user.email, password: "" });
    }
  }, [linkUser]);
  return (
    <>
      <Formik
         initialValues={initialValues}
         enableReinitialize
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          errors,
          touched,
          isValid,
          dirty,
        }) => (
          <Form onSubmit={handleSubmit}>
            {/* Response Message */}
            {(errors.email || errors.password) &&
            (touched.email || touched.password) ? (
              <div className="mt-2 mb-2 error-text">
                Please fill in the field Thank You
              </div>
            ) : null}
            {errorMsg !== null ? (
              <div
                className="error-text d-flex justify-content-center pb-2 px-3"
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  textAlign: "center",
                }}
              >
                {errorMsg}
              </div>
            ) : (
              ""
            )}
            <div
              className=""
              onClick={() => {
                setError();
              }}
            >
              <div className="position-relative mb-4 px-4">
                <label className="Label">Email Address</label>
                <MdEmail className="MdEmail" color="black" size={20} />
                <input
                  className="form-control input-btn padd"
                  name="email"
                  value={values.email}
                  type="email"
                  placeholder="Email Address"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div
              onClick={() => {
                setError();
              }}
              className="mt-2 position-relative px-4"
            >
              <label className="Label pt-2">Password</label>
              <IoMdLock className="IoMdLock" color="black" size={20} />
              <input
                className="form-control input-btn padd"
                name="password"
                value={values.password}
                type={passwordShown ? "text" : "password"}
                placeholder="Password"
                onChange={handleChange}
              />
              {passwordShown === true ? (
                <AiFillEye
                  onClick={() => setPasswordShown(false)}
                  className="eyeInvisible"
                  color="black"
                  size={20}
                />
              ) : (
                <AiFillEyeInvisible
                  onClick={() => setPasswordShown(true)}
                  className="eyeInvisible"
                  color="black"
                  size={20}
                />
              )}
            </div>
            <div className="d-flex flex-row justify-content-between">
              <div
                className="forgotPasswordtext"
                onClick={() => {
                  setForgotFalg(true);
                }}
              >
                Forgot Password?
              </div>
            </div>

            <Col
              lg="12"
              md="12"
              sm="12"
              xs="12"
              className="d-flex justify-content-center p-4 pb-3"
            >
              <Button
                type="submit"
                className="submit-btn ib-btn w-100"
                disabled={!(dirty && isValid)}
              >
                Log In to My Account
              </Button>
            </Col>
            <div className="d-flex justify-content-between">
              <p className="dontHave">Don't have an account?</p>
              <Button
                className="signup_button"
                onClick={() => {
                  setError();
                  navigate("/signup");
                }}
              >
                <b>Sign Up</b>
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
export default LoginPage;
