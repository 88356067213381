import React, { useEffect } from "react";
import './css/admin-dashboard.css'
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUser, faUserSecret } from "@fortawesome/free-solid-svg-icons";
import { MdPersonRemove } from "react-icons/md";
import { FiX } from "react-icons/fi";
import { IoMdMenu } from "react-icons/io";
import { RiDeleteBinFill, RiEarthLine, RiShieldStarLine } from "react-icons/ri";
import { HiOutlineChatAlt2 } from "react-icons/hi";
import { IoCardOutline } from "react-icons/io5";
import PersonalUserTable from "./pages/PersonalUserTable";
import UsersActivities from "./pages/UsersActivities";
import DeactivateAprovedUser from "./pages/DeleteUsers";
import { FaUserClock } from "react-icons/fa";
import StatusReports from "./pages/StatusReports";
import { StatusPopup } from "../Pages/home/NavbarNew/StatusPopup";
import { BsFilePost, BsFillPersonLinesFill } from "react-icons/bs";

import UsersReviewList from "./pages/UsersReviewList";
import GeoSocials from "./pages/GeoSocials";
import Ecommerce from "./pages/Ecommerce";
import Events from "./pages/Events";
import Microblogs from "./pages/Microblogs";
import DeletionActivities from "./pages/DeletionActivities";

const SideBar = () => {

  const [isOpen, setisOpen] = useState(true);
  const [navLink, setnavLink] = useState("1")
  const [PopupStatus, setPopupStatus] = useState(false)
  const [PopupData, setPopupData] = useState([])
  
  useEffect(() => {
    if (PopupStatus === true) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [PopupStatus])

  const ToggleSidebar = () => {
    isOpen === true ? setisOpen(false) : setisOpen(true);
  }

  return (
    <div className="d-flex" >
      <div className ={` ${isOpen === true ? 'd-none d-lg-block' : 'd-block admin-sidebar-false'}`}>
        <aside className= 'navside sidebar-first border-0 border-radius-xl mb-4 ms-3'>
        {/* <aside className={` ${isOpen === true ? 'navside sidebar-first border-0 border-radius-xl mb-3' : 'navside sidebar-first border-0 border-radius-xl mb-3'}`}> */}

          <div className="d-flex justify-content-center">
            <div className="navside-header ">
              <p className="navbaradmin-brand m-0  fs-4" style={{color:'#6148a1' }}>Dashboard</p>
            </div>
            <div className="pe-3 pt-2 ps-3 d-lg-none d-block ">
              <FiX color="" size={25} onClick={ToggleSidebar} style={{ cursor: "pointer" }} />
            </div>
          </div>

          <hr className="horizontal dark my-0" />

          <div className=" navbaradmin-collapse w-auto" id="navside-collapse-main">
           
            {/* Button Users Table */}
            <div
              className=" rounded-2 mt-3 p-3 mx-3"
              onClick={() => {
                setnavLink("1")
                setisOpen(!false)
              }}
              style={
                navLink === "1"
                  ? { backgroundColor: '#6148A1' , color:'white'  }
                  : { backgroundColor: ''}
              }
            >
              <div className="d-flex align-items-center" style={{ cursor: "pointer" }}>
                <div>
                  <FontAwesomeIcon icon={faCircleUser} className="fs-3"/>
                </div>
                <div className="ms-3  ">
                  <span className="fs-6">Users</span>
                </div>
              </div>
            </div>
            
            {/* New Business Users */}
            <div
              className=" rounded-2 p-3 mx-3 mt-2"
              onClick={() => {
                setnavLink("2")
                setisOpen(!false)
              }
              }
              style={
                navLink === "2"
                  ? { backgroundColor: '#6148A1' , color:'white'  }
                  : { backgroundColor: "" }
              }
            >
              <div className="d-flex align-items-center " style={{ cursor: "pointer" }}>
                <div >
                <BsFillPersonLinesFill className="fs-3"/>
                </div>
                <div className="ms-3  ">
                  <span className="fs-6">Reviewables</span>
                </div>
              </div>
            </div>
            {/* Button Deletion Applies */}
            <div
              className=" rounded-2 p-3 mx-3 mt-2"
              onClick={() => {
                setnavLink("3")
                setisOpen(!false)
              }
              }
              style={
                navLink === "3"
                  ? { backgroundColor: '#6148A1' , color:'white'  }
                  : { backgroundColor: "" }
              }
            >
              <div className="d-flex align-items-center " style={{ cursor: "pointer" }}>
                <div >
                  <MdPersonRemove className="fs-3" />
                </div>
                <div className="ms-3  ">
                  <span className="fs-6">Account Deletion Requests</span>
                </div>
              </div>
            </div>

            {/* Button User Activities */}
            <div
              className=" rounded-2 p-3 mx-3 mt-2"
              onClick={() => {
                setnavLink("4")
                setisOpen(!false)
              }
              }
              style={
                navLink === "4"
                  ? { backgroundColor: '#6148A1' , color:'white'  }
                  : { backgroundColor: "" }
              }
            >
              <div className="d-flex align-items-center " style={{ cursor: "pointer" }}>
                <div >
                  <BsFilePost className="fs-3" />
                </div>
                <div className="ms-3  ">
                  <span className="fs-6">Flagged Posts</span>
                </div>
              </div>
            </div>

            {/* Reported Post Activities */}
            <div
              className=" rounded-2 p-3 mx-3 mt-2"
              onClick={() => {
                setnavLink("5")
                setisOpen(!false)
              }
              }
              style={
                navLink === "5"
                  ? { backgroundColor: '#6148A1' , color:'white'  }
                  : { backgroundColor: "" }
              }
            >
              <div className="d-flex align-items-center " style={{ cursor: "pointer" }}>
                <div >
                  <FaUserClock className="fs-3" />
                </div>
                <div className="ms-3  ">
                  <span className="fs-6">Global User Activity</span>
                </div>
              </div>
            </div>

            {/* Deletion Activities */}
            <div
              className=" rounded-2 p-3 mx-3 mt-2"
              onClick={() => {
                setnavLink("6")
                setisOpen(!false)
              }
              }
              style={
                navLink === "6"
                  ? { backgroundColor: '#6148A1' , color:'white'  }
                  : { backgroundColor: "" }
              }
            >
              <div className="d-flex align-items-center " style={{ cursor: "pointer" }}>
                <div >
                  <RiDeleteBinFill className="fs-3"/>
                </div>
                <div className="ms-3">
                  <span className="fs-6">Admin Activity</span>
                </div>
              </div>
            </div>

            {/* Button Geo Social */}
            <div
              className=" rounded-2 p-3 mx-3 mt-2"
              onClick={() => {
                setnavLink("7")
                setisOpen(!false)
              }
              }
              style={
                navLink === "7"
                  ? { backgroundColor: '#6148A1' , color:'white'  }
                  : { backgroundColor: "" }
              }
            >
              <div className="d-flex align-items-center " style={{ cursor: "pointer" }}>
                <div >
                  <RiEarthLine className="fs-3" />
                </div>
                <div className="ms-3">
                  <span className="fs-6">Geo Social</span>
                </div>
              </div>
            </div>

            {/* Button E-Commerce */}
            <div
              className=" rounded-2 p-3 mx-3 mt-2"
              onClick={() => {
                setnavLink("8")
                setisOpen(!false)
              }
              }
              style={
                navLink === "8"
                  ? { backgroundColor: '#6148A1' , color:'white'  }
                  : { backgroundColor: "" }
              }
            >
              <div className="d-flex align-items-center " style={{ cursor: "pointer" }}>
                <div >
                  <IoCardOutline className="fs-3" />
                </div>
                <div className="ms-3">
                  <span className="fs-6">E-Commerce</span>
                </div>
              </div>
            </div>

            {/* Button Events */}
            <div
              className=" rounded-2 p-3 mx-3 mt-2"
              onClick={() => {
                setnavLink("9")
                setisOpen(!false)
              }
              }
              style={
                navLink === "9"
                  ? { backgroundColor: '#6148A1' , color:'white'  }
                  : { backgroundColor: "" }
              }
            >
              <div className="d-flex align-items-center " style={{ cursor: "pointer" }}>
                <div >
                  <RiShieldStarLine className="fs-3" />
                </div>
                <div className="ms-3  ">
                  <span className="fs-6">Events</span>
                </div>
              </div>
            </div>

            {/* Button Microblogs */}
            <div
              className=" rounded-2 p-3 mx-3 mt-2"
              onClick={() => {
                setnavLink("10")
                setisOpen(!false)
              }
              }
              style={
                navLink === "10"
                  ? { backgroundColor: '#6148A1' , color:'white'  }
                  : { backgroundColor: "" }
              }
            >
              <div className="d-flex align-items-center " style={{ cursor: "pointer" }}>
                <div >
                  <HiOutlineChatAlt2 className="fs-3" />
                </div>
                <div className="ms-3  ">
                  <span className="fs-6">Microblogs</span>
                </div>
              </div>
            </div>
          </div>

        </aside>
      </div>

      {/* <div className ='bg-danger admin-users-table w-100'> */}
      <div className ={` ${isOpen === true ? 'w-100' : 'admin-users-table-false'}`}>
       
        <div  className ={` ${isOpen === true ? 'd-block d-lg-none ms-4 pb-2' : 'd-none'}`}>
          <IoMdMenu color="" size={23} onClick={ToggleSidebar} style={{ cursor: "pointer" }} />
        </div>
      <div className="me-2">
        {
          navLink === '1' ? 
            (<PersonalUserTable/>) :
          navLink === '2' ?
            (<UsersReviewList/>) 
          : navLink === '3' ?
            (<DeactivateAprovedUser/>)
          : navLink === '4' ?
            (<StatusReports setPopupData={(e) => setPopupData(e)} setPopupStatus= {setPopupStatus}/>) 
          : navLink === '5' ?
            (<UsersActivities setPopupData={(e) => setPopupData(e)} setPopupStatus= {setPopupStatus}/>)
          : navLink === '6' ?
            (<DeletionActivities setPopupData={(e) => setPopupData(e)} setPopupStatus= {setPopupStatus}/>)
          : navLink === '7' ?
            (<GeoSocials setPopupData={(e) => setPopupData(e)} setPopupStatus= {setPopupStatus}/>)
          : navLink === '8' ?
            (<Ecommerce setPopupData={(e) => setPopupData(e)} setPopupStatus= {setPopupStatus}/>)
          : navLink === '9' ?
            (<Events/>)
          : (<Microblogs/>)
        }
      </div>

      </div>

      {PopupStatus && <StatusPopup
          setPopupStatus={setPopupStatus}
          Items={PopupData}
      />}

    </div>

  );
};

export default SideBar;