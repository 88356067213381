import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { Button, Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { GrFormPrevious } from "react-icons/gr";
import "../css/pagination.css";
import { MdOutlineNavigateNext } from "react-icons/md";
import { Server2 } from "../../redux/actions/auth/auth.actions";
import { useNavigate } from "react-router-dom";
import { BsCaretDownFill } from "react-icons/bs";

const PER_PAGE = 100;

const StatusReports = ({ setPopupStatus, setPopupData }) => {
  const navigate = useNavigate();
  const [allReports, setAllReports] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const userData = useSelector((state) => state?.auth?.user?.user);
  const [q, setQ] = useState("");
  const reportTypes = [
    "Child Exploitation",
    "Drugs, Weapons, or Regulated Goods",
    "False Information",
    "Fraud or Scam",
    "Hate Organizations",
    "Hate Speech",
    "Harassment",
    "Intellectual Property",
    "Nudity",
    "Privacy Violations",
    "Spam",
    "Violence",
    "Others",
  ];
  function searchData() {
    // return activities;
    return allReports.filter((user) => {
      if (q === "") {
        return user;
      } else if (user?.reports?.type.toLowerCase().includes(q.toLowerCase())) {
        return user;
      }
    });
  }

  // API'S MakeDeletionApproved Starts
  const getAllReports = () => {
    const data = {
      id: userData?._id,
    };
    let config = {
      method: "post",
      url: Server2 + "/status/reports",
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.status === 200) {
          setAllReports(response.data.reports);
        }
        setLoading(false);
        console.log(response.data.reports, "i am res---");
      })
      .catch(function (error) {
        console.log(error, "error Delete User");
      });
  };

  // API'S Approved request Starts
  const handleIngore = (from, statusId, userId) => {
    const data = {
      from: from,
      statusId: statusId,
      statusBy: userId,
    };
    let config = {
      method: "post",
      url: Server2 + "/status/ignore-report",
      data: data,
    };

    axios(config)
      .then(function (response) {
        getAllReports();
        console.log(response.data, "i am res---");
      })
      .catch(function (error) {
        console.log(error, "error Delete User");
      });
  };

  const handleDeletePost = (e) => {
    var data = JSON.stringify({
      id: e,
      from: userData._id,
      disable: true,
    });
    var config = {
      method: "POST",
      url: Server2 + "/status/delete-status",
      data: data,
      headers: { "Content-Type": "application/json" },
    };
    axios(config)
      .then((response) => {
        getAllReports();
      })
      .catch((error) => {
        console.log("er", error);
      });
  };

  // API'S Approved request Starts
  const handelAprovedRequest = (e) => {
    const data = {
      id: e,
      disable: true,
    };
    let config = {
      method: "post",
      url: Server2 + "/admin/enable-user",
      data: data,
    };

    axios(config)
      .then(function (response) {
        getAllReports();
        console.log(response.data, "i am res---");
      })
      .catch(function (error) {
        console.log(error, "error Delete User");
      });
  };

  // API'S Approved request Starts
  const handelRejectRequest = (e) => {
    const data = {
      id: e,
    };
    let config = {
      method: "post",
      url: Server2 + "/admin/disable-user",
      data: data,
    };

    axios(config)
      .then(function (response) {
        getAllReports();
        console.log(response.data, "i am res---");
      })
      .catch(function (error) {
        console.log(error, "error Delete User");
      });
  };

  useEffect(() => {
    setLoading(true);
    getAllReports();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //pagination function
  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }

  //Remaning Days functions
  function addHours(date, hours) {
    var result = new Date(date);
    result.setHours(result.getHours() + hours);
    // console.log(date, result, "time stamp")
    return result;
  }

  const calculateHours = (applied_hours) => {
    const hoursRemaining = new Date(applied_hours);
    let addedHours = addHours(hoursRemaining, 24);
    const newDate = new Date();
    const difference = addedHours.getTime() - newDate.getTime();
    var time_difference = Math.round((difference / (1000 * 60 * 60)) % 24);
    return time_difference;
  };
  //pagination
  const offset = currentPage * PER_PAGE;
  const currentPageData =
    allReports.length === 0
      ? null
      : searchData().slice(offset, offset + PER_PAGE).map((report, index) => (
          <tbody
            key={index}
            className={report.deactivated ? "py-4 bg-black" : "py-4"}
          >
            <tr>
              <td>
                <div
                  className="d-flex px-2 py-1"
                  onClick={() => {
                    navigate("/Profiless", {
                      state: {
                        item: report.reports.from,
                      },
                    });
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <div>
                    <img
                      src={
                        report.reports.profile_pic
                          ? report.reports.profile_pic
                          : "/img/upload-photo.png"
                      }
                      className="avatar avatar-sm me-3"
                      alt="user1"
                    />
                  </div>
                  <div className="d-flex flex-column justify-content-center">
                    <h6 className="mb-0 text-sm text-capitalize ">
                      {report.reports.fname} {report.reports.lname}
                    </h6>
                    <p className="text-xs text-secondary mb-0">
                      {report.reports.email}
                    </p>
                  </div>
                </div>
              </td>
              <td>
                <div
                  className="d-flex px-2 py-1"
                  onClick={() => {
                    navigate("/Profiless", {
                      state: {
                        item: report.status_by,
                      },
                    });
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <div>
                    <img
                      src={
                        report.profile_pic
                          ? report.profile_pic
                          : "/img/upload-photo.png"
                      }
                      className="avatar avatar-sm me-3"
                      alt="user1"
                    />
                  </div>
                  <div className="d-flex flex-column justify-content-center">
                    <h6 className="mb-0 text-sm text-capitalize ">
                      {report.fname} {report.lname}
                    </h6>
                    <p className="text-xs text-secondary mb-0">
                      {report.email}
                    </p>
                  </div>
                </div>
              </td>
              <td>
                <p className="align-middle ">{report.reports.type}</p>
              </td>
              <td>
                <p className="align-middle">
                  {calculateHours(report.reports.date) <= 10 ? (
                    <>
                      <spam className="text-danger">
                        {calculateHours(report.reports.date)} Hour(s)
                      </spam>
                    </>
                  ) : (
                    <>
                      <spam>{calculateHours(report.reports.date)} Hour(s)</spam>
                    </>
                  )}
                </p>
              </td>
              <td className="allign-middle">
                <div className="d-flex justify-content-around gap-2 gap-md-0 ">
                  <Button
                    style={{ background: "#6148a1", border: "none" }}
                    onClick={() => {
                      setPopupData(report.status);
                      setPopupStatus(true);
                    }}
                  >
                    View Post
                  </Button>
                  {/* <Button style={{ background: '#6148a1', border: 'none' }} onClick={() => handelAprovedRequest(user)}></Button> */}
                  <Dropdown className="border-0 rounded-2 add-btn fw-bold">
                    <Dropdown.Toggle
                      className="border-0"
                      id="dropdown-basic"
                      aria-expanded="false"
                      style={{ backgroundColor: "#6148a1" }}
                    >
                      <button
                        type="button"
                        className="bg-transparent border-0 text-white"
                        data-bs-toggle="button"
                      >
                        <spam className="d-inline postition-relative text-white">
                          Take Action
                        </spam>
                      </button>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="w-100 text-center">
                      <Dropdown.Item
                        eventKey="1"
                        onClick={() => {
                          handleDeletePost(report.status);
                        }}
                        style={{ fontSize: 13 }}
                      >
                        Delete Post
                      </Dropdown.Item>
                      {report.deactivated ? (
                        <Dropdown.Item
                          eventKey="1"
                          onClick={() => {
                            handelAprovedRequest(report.status_by);
                          }}
                          style={{ fontSize: 13 }}
                        >
                          Enable User Account
                        </Dropdown.Item>
                      ) : (
                        <Dropdown.Item
                          eventKey="1"
                          onClick={() => {
                            handelRejectRequest(report.status_by);
                          }}
                          style={{ fontSize: 13 }}
                        >
                          Disable User Account
                        </Dropdown.Item>
                      )}
                      <Dropdown.Item
                        eventKey="2"
                        onClick={() => {
                          handleIngore(
                            report.reports.from,
                            report.status,
                            report.status_by
                          );
                        }}
                        style={{ fontSize: 13 }}
                      >
                        Ignore
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </td>
            </tr>
          </tbody>
        ));

  const pageCount = Math.ceil(searchData().length / PER_PAGE);

  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center">
          <div
            className="spinner-border"
            role="status"
            style={{ width: "6rem", height: "6rem", color: "#6148A1" }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <div className="container-fluid ">
            <div className="row">
              <div className="col-12">
                <div className="card mb-4">
                  <div className="card-header pb-0">
                    <h6>Flagged Posts</h6>
                  </div>
                  <div
                    className="card-body px-0 pt-0 pb-2 task"
                    style={{ height: "73vh", overflow: "auto" }}
                  >
                    <div className="table-responsive p-0 pb-4">
                      <table className="table align-items-center mb-0">
                        {currentPageData !== null ? (
                          <>
                            <thead>
                              <tr>
                                <th className="text-uppercase">
                                  Reporting User
                                </th>
                                <th className="text-uppercase">Post User</th>
                                <th className="">
                                  <Dropdown className="m-0 p-0">
                                    <Dropdown.Toggle
                                      className=" border-0"
                                      variant=""
                                      id="dropdown-basic"
                                      aria-expanded="false"
                                    >
                                      <span
                                        className="fs-6 fw-bold text-uppercase"
                                        style={{ color: "#67748e" }}
                                      >
                                        Report Type{" "}
                                        <BsCaretDownFill size={12} />
                                      </span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdown-menu">
                                      <Dropdown.Item
                                        onClick={() => {
                                          setQ("");
                                        }}
                                      >
                                        <span className="fw-bold">
                                          All Types
                                        </span>
                                      </Dropdown.Item>
                                      {reportTypes.map((e) => {
                                        return (
                                          <Dropdown.Item
                                            onClick={() => {
                                              setQ(e);
                                            }}
                                          >
                                            <span className="fw-bold">{e}</span>
                                          </Dropdown.Item>
                                        );
                                      })}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </th>
                                <th className="text-uppercase">Time Left</th>
                                <th className="text-uppercase">Actions</th>
                              </tr>
                            </thead>
                            {currentPageData}
                          </>
                        ) : (
                          <div className="d-flex justify-content-center w-100">
                            <p>No Posts Reported Yet</p>
                          </div>
                        )}
                      </table>
                    </div>

                    {searchData().length > 99 ? (
                      <div
                        className=" mt-4"
                        style={{
                          padding: 2,
                          boxSizing: "border-box",
                          width: "100%",
                        }}
                      >
                        <ReactPaginate
                          activeClassName={"item active "}
                          breakClassName={"item break-me "}
                          breakLabel={"..."}
                          containerClassName={"pagination"}
                          t
                          disabledClassName={"disabled-page"}
                          marginPagesDisplayed={2}
                          nextClassName={"item next "}
                          nextLabel={
                            <MdOutlineNavigateNext
                              style={{ fontSize: 18, width: 150 }}
                            />
                          }
                          onPageChange={handlePageClick}
                          pageCount={pageCount}
                          pageClassName={"item pagination-page "}
                          pageRangeDisplayed={2}
                          previousClassName={"item previous"}
                          previousLabel={
                            <GrFormPrevious
                              style={{ fontSize: 18, width: 150 }}
                            />
                          }
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default StatusReports;
