import React,{useEffect, useState} from 'react'
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Server2 } from '../../redux/actions/auth/auth.actions'
import NavbarHome from '../home/NavbarNew/NavbarHome';
import FootNav from './Footer/Footer-nav/FootNav';
import UserProfile from './UserProfile'
import { LoadBoot } from '../../components/LoadBoot';

const Profilesssss = () => {
    const {state} = useLocation()
    const navigate = useNavigate()
    const userdata = useSelector((state) => state?.auth?.user?.user);
    const [data,setData] = useState(null)
    const [Loading,setLoading] = useState(false)
    const [Error,setError] = useState("")
    
    useEffect(()=>{
        if(userdata._id === state.item){
            navigate("/myProfile")
        }else{
            setData(null)
            GetUserData(state.item)
        }
    },[state.item]) // eslint-disable-line react-hooks/exhaustive-deps
    const GetUserData = async (e) => {
        setLoading(true)
        await fetch(
          Server2 + `/users/userprofile?id=${userdata._id}&user=${e}`,
          {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        }
        ).then(async (response) => {
            const data = await response.json();
            if(data.user.deactivated_by_admin){
              setError("Oops! This user account has been disabled by the administrator.")
            }else{
              setData(data.user);
            }
            setLoading(false)
        })
        .catch(function (error) {
          setLoading(false)
          setError("Oops! User Not Found")

          console.log(error, "error Delete User");
      });
      };
  return (
    <div style={{background:"white"}}>

        <div className="bg-white" style={{ background: "white", height: "100vh" }}>
      <NavbarHome />
      <div style={{height:data==null?"100vh":""}}>

    { Loading=== true? <LoadBoot/>:
        data!=null?
        <div>
       <UserProfile Data={data} />
    </div>
        :Error?<div className='text-center mt-5'>
          <p className='fw-bold text-dark' style={{color:"#00000"}}>{Error}</p>
        </div>:null
    }
      </div>
      <FootNav />
        </div>
    </div>
  )
}

export default Profilesssss
