import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { Button, Dropdown } from "react-bootstrap";
import moment from "moment/moment";
import { useSelector } from "react-redux";
import { GrFormPrevious } from "react-icons/gr";
import "../css/pagination.css";
import { MdOutlineNavigateNext } from "react-icons/md";
import { Server2 } from "../../redux/actions/auth/auth.actions";
import MicroblogActivities from "../../Pages/home/AddMicroblogPopup/MicroblogActivities";
import { BsCaretDownFill } from "react-icons/bs";

const PER_PAGE = 100;

const Microblogs = () => {
  const [Data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [ActivitiesOpen, setActivitiesOpen] = useState(false);
  const [MicroData, setMicroData] = useState([]);
  const userData = useSelector((state) => state?.auth?.user?.user);

  const MicroApi = (e) => {
    var config = {
      method: "get",
      url: Server2 + `/microblogs/allMicroblogs?id=${userData?._id}`,
      // headers: { "Content-Type": "application/json" },
    };
    axios(config)
      .then(function (response) {
        if (response.data) {
          console.log(response.data);
          setLoading(false);
          response.data.sort(
            (b, c) => new Date(c.created_date) - new Date(b.created_date)
          );
          if (e !== undefined) {
            let a = [];
            response.data.filter((user) => {
              if (user.is_private == e) {
                a.push(user);
              }
              setData(a);
            });
          } else {
            setData(response.data);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const deleteMicro = (e) => {
    const data = {
      by:userData?._id,
      room: e,
      disable:true
    };
    let config = {
      method: "post",
      url: Server2 + "/microblogs/disable",
      data: data,
    };
    axios(config)
      .then((response) => {
        MicroApi();
      })
      .catch((error) => console.error("Error fetching data:", error));
  };
  // API'S Approved request Starts
  const handelDeleteRequest = (e) => {
    const data = {
      id: e,
    };
    let config = {
      method: "post",
      url: Server2 + "/admin/disable-user",
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data, "i am res---");
      })
      .catch(function (error) {
        console.log(error, "error Delete User");
      });
  };
  useEffect(() => {
    setLoading(true);
    MicroApi();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //pagination function
  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }

  //Remaning Days functions
  function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  //pagination
  const offset = currentPage * PER_PAGE;
  const currentPageData =
    Data.length === 0
      ? null
      : Data.slice(offset, offset + PER_PAGE).map((user, index) => (
          <tbody key={index}>
            <tr>
              <td>
                <div className="d-flex px-2 py-1">
                  <div>
                    <img
                      src={
                        user.microblog_img
                          ? user.microblog_img
                          : "/img/upload-photo.png"
                      }
                      className="avatar avatar-sm me-3"
                      alt="user1"
                    />
                  </div>
                  <div className="d-flex flex-column justify-content-center">
                    <h6 className="mb-0 text-sm text-capitalize ">
                      {user.name}
                    </h6>
                    <p className="text-xs text-secondary mb-0">{user.email}</p>
                  </div>
                </div>
              </td>
              <td>
                <p className="align-middle ms-4">
                  {user.is_private ? "Private" : "Public"}
                </p>
              </td>
              <td>
                <p className="align-middle ">
                  {moment(user.created_date).format("MMMM Do, YYYY  h:mm A")}
                </p>
              </td>
              <td className="allign-middle">
                <div className="d-flex justify-content-center ">
                  <Button
                    style={{
                      background: "#6148a1",
                      border: "none",
                      marginRight: 10,
                    }}
                    onClick={() => {
                      setMicroData([user]);
                      setActivitiesOpen(true);
                    }}
                  >
                    View Microblog
                  </Button>
                  {/* <Button style={{ background: '#6148a1', border: 'none' }} onClick={() => handelAprovedRequest(user)}></Button> */}
                  <Dropdown className="border-0 rounded-2 add-btn fw-bold">
                    <Dropdown.Toggle
                      className="border-0"
                      id="dropdown-basic"
                      aria-expanded="false"
                      style={{ backgroundColor: "#6148a1" }}
                    >
                      <button
                        type="button"
                        className="bg-transparent border-0 text-white"
                        data-bs-toggle="button"
                      >
                        <spam className="d-inline postition-relative text-white">
                          Take Action
                        </spam>
                      </button>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="w-100 text-center">
                      <Dropdown.Item
                        eventKey="1"
                        onClick={() => deleteMicro(user?.room)}
                        style={{ fontSize: 13 }}
                      >
                        Delete Microblog
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey="2"
                        onClick={() => handelDeleteRequest(user.created_by)}
                        style={{ fontSize: 13 }}
                      >
                        Disable User Account
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </td>
            </tr>
          </tbody>
        ));

  const pageCount = Math.ceil(Data.length / PER_PAGE);

  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center">
          <div
            className="spinner-border"
            role="status"
            style={{ width: "6rem", height: "6rem", color: "#6148A1" }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <div className="container-fluid ">
            <div className="row">
              <div className="col-12">
                <div className="card mb-4">
                  <div className="card-header pb-0">
                    <h6>Microblogs </h6>
                  </div>
                  <div
                    className="card-body px-0 pt-0 pb-2 task"
                    style={{ height: "73vh", overflow: "auto" }}
                  >
                    <div className="table-responsive p-0">
                      <table className="table align-items-center mb-0">
                        <thead>
                          <tr>
                            <th className="text-uppercase ">
                              Microblog Name
                            </th>
                            <th className="">
                              <Dropdown className="">
                                <Dropdown.Toggle
                                  className=" border-0"
                                  variant=""
                                  id="dropdown-basic"
                                  aria-expanded="false"
                                >
                                  <span
                                    className="fs-6 fw-bold text-uppercase"
                                    style={{ color: "#67748e" }}
                                  >
                                    Category{" "}
                                    <BsCaretDownFill size={12}/>
                                  </span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-menu">
                                  <Dropdown.Item
                                    onClick={() => {
                                      MicroApi();
                                    }}
                                  >
                                    <span className="fw-bold">All Microblogs</span>
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => {
                                      MicroApi(true);
                                    }}
                                  >
                                    <span className="fw-bold">Private</span>
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => {
                                      MicroApi(false);
                                    }}
                                  >
                                    <span className="fw-bold">Public</span>
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </th>
                            <th className=" text-uppercase ps-5">
                              Created Date
                            </th>
                            <th className="text-uppercase text-center">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        {currentPageData}
                      </table>
                    </div>

                    {Data.length > 99 ? (
                      <div
                        className=" mt-4"
                        style={{
                          padding: 2,
                          boxSizing: "border-box",
                          width: "100%",
                        }}
                      >
                        <ReactPaginate
                          activeClassName={"item active "}
                          breakClassName={"item break-me "}
                          breakLabel={"..."}
                          containerClassName={"pagination"}
                          t
                          disabledClassName={"disabled-page"}
                          marginPagesDisplayed={2}
                          nextClassName={"item next "}
                          nextLabel={
                            <MdOutlineNavigateNext
                              style={{ fontSize: 18, width: 150 }}
                            />
                          }
                          onPageChange={handlePageClick}
                          pageCount={pageCount}
                          pageClassName={"item pagination-page "}
                          pageRangeDisplayed={2}
                          previousClassName={"item previous"}
                          previousLabel={
                            <GrFormPrevious
                              style={{ fontSize: 18, width: 150 }}
                            />
                          }
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {ActivitiesOpen && (
        <div className="main-event-bg-dash" style={{ left: 0 }}>
          <MicroblogActivities
            ActivitiesClose={(e) => setActivitiesOpen(e)}
            MicroData={MicroData}
          />
        </div>
      )}
    </>
  );
};

export default Microblogs;
