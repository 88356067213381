import React, { useEffect, useRef, useState } from "react";
import { Col } from "reactstrap";
import "../../assets/styles/Profile.css"
import "../../assets/styles/Settings/Settings.css"
import { AiFillCamera } from "react-icons/ai";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { firstStepPresonal, logout } from "../../redux/actions/auth/auth.actions";
import PresonalSecondStep from "./PresonalSecondStep";
import { BsPlusLg } from "react-icons/bs";
import InputTags from '@semcore/ui/input-tags';
import Tooltip from "@semcore/tooltip";
import { IoMdCamera } from "react-icons/io";
import ProfileImage from "../../Pages/My-Profile/ProfileImage";

function FirstStep() {
  // navigate
  let navigate = useNavigate();
  const UserData = useSelector((state) => state?.auth?.user?.user);
  const [Interests, SetInterests] = useState([]);
  const [value, updateValue] = useState("");
  const [Language, SetLanguage] = useState([]);
  const [value2, updateValue2] = useState("");

  //   dispatch
  const ref = useRef(null);
  const dispatch = useDispatch();
  // set state move to next page
  const [FirstP, setFirstP] = useState("1");
  // Image Selection Start
  const [Image, setImage] = useState("");
  const [IsUpload, setIsUpload] = useState(false);
  const [IsUploadCover, setIsUploadCover] = useState(false);
  const [ImageCover, setImageCover] = useState("");
  const [ProfilePicPopup, setProfilePicPopup] = useState(false);
  const [CoverPicPopup, setCoverPicPopup] = useState(false);
  const inputFile = useRef(null);
  const inputFileCover = useRef(null);
  // to open file explore
  const openInputFile = (e) => {
    inputFile.current.click();
  };
  const openInputFileCover = (e) => {
    inputFileCover.current.click();
  };
  useEffect(() => {
    if (!CoverPicPopup) {
      // Reset input value when CoverPicPopup is false
      inputFileCover.current.value = null;
    }
    if (!ProfilePicPopup) {
      // Reset input value when ProfilePicPopup is false
      inputFile.current.value = null;
    }
  }, [CoverPicPopup, ProfilePicPopup]);
  // to display image
  const handleImageChange = (e) => {
    inputFile.current.click();
    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader();
      reader.onload = function (e) {
        setImage(e.target.result);
        setIsUpload(true);
      };
      reader.readAsDataURL(e.target.files[0]);
      setProfilePicPopup(true)
    }
  };
  const handleImageChangeCover = (e) => {
    inputFileCover.current.click();
    // setFilesCover(e.target.files[0]);
    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader();
      reader.onload = function (e) {
        setImageCover(e.target.result);
        setIsUploadCover(true);
      };
      reader.readAsDataURL(e.target.files[0]);
      setCoverPicPopup(true)
    }
  };
  // initial values
  const inputValues = {
    status: 1,
    summary: "",
    intrest: [],
    lngs: [],
  };
  const payloadFirst = {
    languages: [],
    about_me: "",
    activation_status: 1,
    email: "",
    interests: [],
  };
  const payloadFirstFunc = (values) => {
    payloadFirst.languages = Language;
    payloadFirst.about_me = values.summary;
    payloadFirst.about_company = values.bussiness_offers;
    payloadFirst.activation_status = 1;
    payloadFirst.interests = Interests;
    payloadFirst.email = UserData?.email;
  };
  // function on button
  const handleSubmit = (values) => {
    payloadFirstFunc(values);
    dispatch(firstStepPresonal(payloadFirst));
    setFirstP();
    navigate("/secondStep")
  };

  const validationSchema = Yup.object().shape({
    // intrest: Yup.array("Please fill in the field Thank You").required(),
    summary: Yup.string("Please fill in the field Thank You").required(),
    // lngs: Yup.array("Please fill in the field Thank You").required(),
  });
  const handleClose = (e) => {
    dispatch(logout());
    navigate("/", { replace: true });
    localStorage.clear();
  };

  // Intersts Func Start Input Tags
  const handleAppendTags = (newTags) => {
    SetInterests((Interests) => [...Interests, ...newTags]);
    updateValue("");
  };
  const handleRemoveTag = () => {
    if (Interests.length === 0) return;
    SetInterests(Interests.slice(0, -1));
    updateValue(Interests.slice(-1)[0] + ` ${value}`);
  };
  const handleCloseTag = (e) => {
    e.preventDefault();
  };

  const handleEditTag = (e) => {
    const { dataset } = e.currentTarget;
    let allTags = [...Interests];
    if (value) {
      allTags = [...allTags, value];
    }
    SetInterests(allTags.filter((tag, ind) => ind !== Number(dataset.id)));
    if (!e.defaultPrevented) {
      updateValue(Interests[dataset.id]);
    }
    return false;
  };

  const handleBlurInput = (e) => {
    const { value } = e.currentTarget;
    if (value) handleAppendTags([value]);
  };
  // focus input on click
  const handleClick = () => {
    handleBlurInput()

  };

  // Language Func Start
  const handleAppendLangs = (newTags) => {
    SetLanguage((Language) => [...Language, ...newTags]);
    updateValue2("");
  };
  const handleRemoveLangs = () => {
    if (Language.length === 0) return;
    SetLanguage(Language.slice(0, -1));
    updateValue2(Language.slice(-1)[0] + ` ${value2}`);
  };
  const handleCloseLangs = (e) => {
    e.preventDefault();
  };

  const handleEditLangs = (e) => {
    const { dataset } = e.currentTarget;
    let allTags = [...Language];
    if (value2) {
      allTags = [...allTags, value2];
    }
    SetLanguage(allTags.filter((tag, ind) => ind !== Number(dataset.id)));
    if (!e.defaultPrevented) {
      updateValue2(Language[dataset.id]);
    }
    return false;
  };

  const handleBlurInputLangs = (e) => {
    const { value } = e.currentTarget;
    if (value) handleAppendLangs([value]);
  };


  const handleClicklang = () => {
    handleBlurInputLangs()
  };

  return (
    <div>
      {FirstP ? (
        <Formik
          initialValues={inputValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            touched,
            isValid,
            dirty,
          }) => (

            <Col md="12" lg="12" sm="12" xm="12">
              {/* First Step */}
              <div className="full-content">
                {/* Logo image */}
                <div className="text-center mt-5 mb-4 pt-4">
                  <img src="/img/Logo.png" height={60} alt="ibouge" />
                  {/* text */}
                </div>
                <div className="bg-white First-card-customize">
                  <div className="border_top"></div>
                  <h6 className="headingSteps">Setup 1/3</h6>
                  <p className="setupHeading text-center">
                    Setup your profile
                  </p>
                  <div className="position-relative text-center text-dark">
                    {/* user data */}
                    {/* <div className="prsonal">
                        {IsUpload ? (
                          <img
                            src={Image}
                            className="rounded-circle photouser"
                            height={110}
                            alt="uploaded"
                          />
                        ) : (
                          <div>
                            <img
                              src={ UserData.profile_pic
                    ? UserData.profile_pic
                    : "/img/upload-photo.png"}
                              className="rounded-circle photouser"
                              height={110}
                              alt="upload"
                            />
                          </div>
                        )}
                        <input
                          type="file"
                          id="file"
                          accept="image/png, image/jpeg"
                          className="rounded-circle photouser"
                          ref={inputFile}
                          onClick={openInputFile}
                          onChange={handleImageChange}
                          style={{ display: "none" }}
                        />
                    
                        <AiFillCamera
                          size={45}
                          type="file"
                          htmlFor="file"
                          className="rounded-circle logo-cam"
                          onClick={openInputFile}
                          onChange={handleImageChange}
                        />

                      </div> */}
                    <div className="overflow-hidden text-center mx-2" style={{ borderRadius: "10px" }}>
                      <div
                        style={{
                          position: "absolute",
                          right: "0px",
                          top: "0px",
                          padding: "5px",
                          cursor: "pointer",
                          borderRadius: "8px"
                        }}
                        onClick={openInputFileCover}
                        onChange={handleImageChangeCover}
                      >
                        <AiFillCamera size={28} color={"White"} />
                        <span className="ms-1"></span>
                      </div>

                      <div>
                        <img
                          className="Settingcover"
                          src={
                            UserData?.cover_pic
                              ? UserData?.cover_pic
                              : "/img/swiming105.png"
                          }

                          alt={`${UserData?.fname} ${UserData?.lname}`}
                        />
                      </div>

                      <input
                        type="file"
                        id="file"
                        className="rounded-circle photouser"
                        ref={inputFileCover}
                        onClick={openInputFileCover}
                        onChange={handleImageChangeCover}
                        accept="image/png, image/jpeg"
                        style={{ display: "none" }}
                      />

                      <div style={{ position: "absolute", top: "30px" }} className="w-100 mx-auto">
                        <img
                          className="ms-img img-fluid"
                          src={UserData?.profile_pic ? UserData?.profile_pic : "/img/upload-photo.png"}
                          alt="Icon"
                        />
                        <input
                          type="file"
                          id="file"
                          className="rounded-circle photouser"
                          ref={inputFile}
                          onClick={openInputFile}
                          accept="image/png, image/jpeg"
                          onChange={handleImageChange}
                          style={{ display: "none" }}
                        />
                        <IoMdCamera
                          className="s-camera p-2 rounded-5 "
                          onClick={openInputFile}
                          onChange={handleImageChange}
                        />
                      </div>
                    </div>
                  </div>
                  <h3 className="userNameStep text-center mt-5 pt-2">
                    {UserData?.fname} {UserData?.lname}
                  </h3>
                  {/* <h3 className="userNameStep">
                    {GoogleData?.fname} {GoogleData?.lname}
                  </h3> */}

                  <p className="genderStep text-center">{UserData?.gender}</p>
                  {/* side section */}
                  <div className="">
                    <label className="LabelStep">About me</label>
                    <div className="position-relative">
                      <div className="d-flex justify-content-center px-4 w-100">
                        <textarea
                          name="summary"
                          id="summery"
                          placeholder="Tell us a little bit about yourself"
                          rows={1}
                          className="form-control step-input-btn"
                          value={values.summary}
                          onChange={handleChange}
                        />
                      </div>
                      <label className="LabelStep">My Interests</label>
                      <div className="d-flex flex-column align-items-center px-4 w-100">
                        <InputTags size="l"
                          style={{ padding: "10px 10px" }}
                          className="step-input-btn form-control"
                          onAppend={handleAppendTags} onRemove={handleRemoveTag}>
                          {Interests.length < 0 ? null : Interests.map((tag, idx) => (
                            <Tooltip key={idx}>
                              <Tooltip.Trigger
                                tag={InputTags.Tag}
                                theme="primary"
                                data-id={idx}
                                onClick={handleEditTag}
                              >
                                <InputTags.Tag.Text>{tag}</InputTags.Tag.Text>
                                <InputTags.Tag.Close onClick={handleCloseTag} />
                              </Tooltip.Trigger>
                              {/* <Tooltip.Popper>tag</Tooltip.Popper> */}
                            </Tooltip>
                          ))}
                          <InputTags.Value
                            ref={ref}
                            value={value}
                            onChange={updateValue}
                            onBlur={handleBlurInput}
                          />
                        </InputTags>
                        <div className="Addrefinput w-100">
                          <p className="Addbtnp" onClick={() => handleClick(values.intrest)}>Add Interests
                            <BsPlusLg
                              className="BsPlusLg-icon"
                              color="white"
                              size={14}
                            />
                          </p>
                        </div>
                      </div>

                      <label className="LabelStep">My Languages</label>
                      {/* <textarea
                          name="lngs"
                          id="lngs"
                          type="text"
                          rows={1}
                          ref={reflng}
                          className="form-control step-input-btn"
                          placeholder="eg: English"
                          value={values.lngs}
                          onChange={handleChange}
                        /> */}
                      <div className="d-flex flex-column align-items-center px-4 w-100">
                        <InputTags size="l"
                          style={{ padding: "10px 10px" }}
                          className="step-input-btn form-control"
                          onAppend={handleAppendLangs}
                          onRemove={handleRemoveLangs}>
                          {Language.length < 0 ? null :
                            Language.map((tag, idx) => (
                              <Tooltip key={idx}>
                                <Tooltip.Trigger
                                  tag={InputTags.Tag}
                                  theme="primary"
                                  data-id={idx}
                                  onClick={handleEditLangs}
                                >
                                  <InputTags.Tag.Text>{tag}</InputTags.Tag.Text>
                                  <InputTags.Tag.Close onClick={handleCloseLangs} />
                                </Tooltip.Trigger>
                              </Tooltip>
                            ))}
                          <InputTags.Value
                            value={value2}
                            onChange={updateValue2}
                            onBlur={handleBlurInputLangs}
                          />
                        </InputTags>
                        <div className="Addrefinput w-100">
                          <p className="Addbtnp" onClick={handleClicklang}>Add Languages
                            <BsPlusLg
                              className="BsPlusLg-icon"
                              color="white"
                              size={14}
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-center px-4 pt-3 pb-2">
                    <button
                      style={Interests.length > 0  && Language.length > 0 ? {opacity: 1} : {opacity: 0.7}}
                      type="submit"
                      className="submit-butn w-100"
                      onClick={handleSubmit}
                    >
                      Continue
                    </button>

                  </div>
                </div>
              </div>
              <AiOutlineClose
                size={30}
                className="aioutline"
                onClick={handleClose}
              />
            </Col>

          )}
        </Formik>
      ) : (
        <PresonalSecondStep />
      )}
      {ProfilePicPopup === true ? (
        <ProfileImage
          src={Image}
          Full={inputFile.current.files[0]}
          handleClose={() => setProfilePicPopup(false)}
          type={"profile"}
        />
      ) : null}
      {CoverPicPopup === true ? (
        <ProfileImage
          src={ImageCover}
          Full={inputFileCover.current.files[0]}
          handleClose={() => setCoverPicPopup(false)}
          type={"Cover"}
        />
      ) : null}
    </div>
  );
}

export default FirstStep;