import axios from "axios";
import React, { useEffect, useState } from "react";
import { FiX } from "react-icons/fi";
// import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";
import { Server2 } from "../../redux/actions/auth/auth.actions";

function Friends({ setChatBox, setChatData, setGetGroupRoom }) {
  const userData = useSelector((state) => state?.auth?.user?.user);
  const [UserFriends, setUserFriends] = useState([]);
  const [Friend, setFriend] = useState([]);
  useEffect(() => {
    var config = {
      method: "get",
      url: Server2 + `/users/Friends?id=${userData?._id}`,
      headers: { "Content-Type": "application/json" },
    };
    axios(config)
      .then(function (response) {
        if (response.data) {
          setUserFriends(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const handleClick = (e) => {
    const isFound = Friend.some((element) => {
      if (element._id === e._id) {
        return true;
      }

      return false;
    });
    if (isFound) {
      alert("Already Exist In Group");
    } else {
      setFriend((current) => [...current, { _id: e._id, item: e }]);
    }
  };
  const closeFriend = (e) => {
    setFriend((current) =>
      current.filter((employee) => {
        return employee._id !== e._id;
      })
    );
  };
  const CreateGroup = () => {
    let a = [];
    Friend.map((e) => a.push(e))
    a.push({ _id: userData?._id, item: userData })
    var data = JSON.stringify({
      id: userData._id,
      users: a,
      name: "Group Chat",
    });
    var config = {
      method: "POST",
      url: Server2 + "/chats/group",
      data: data,
      headers: { "Content-Type": "application/json" },
    };
    axios(config)
      .then((response) => {
        setGetGroupRoom(response.data.room);
      })
      .catch((error) => {
        console.log("I am Group Error", error);
      });
  };
  return (
    <>
      <div className="mt-3 d-friends border rounded-3 shadow">
        <div className="d-flex justify-content-between">
          <p className="friends mt-2 ms-3  ">
          {userData?.fname}'s Connections ({UserFriends.length})
          </p>
        </div>
        <div className="friends_row px-3 text-center">
          {UserFriends.map((friends, index) => {
            return (
              <div
                className=" col-xl-1 col-lg-2 col-md-2 col-sm-2 col-3 px-1 mx-1 text-center"
                style={{ cursor: "pointer", lineBreak: "" }}
                key={index}
                onClick={() => handleClick(friends)}
              >
                <img
                  className="img rounded-5 "
                  src={
                    friends.profile_pic
                      ? friends.profile_pic
                      : "/img/upload-photo.png"
                  }
                  style={{ width: "40px", height: "40px" }}
                  alt="Admin"
                />
                {friends?.is_online === true ?
                  <img
                    src="/Images/contact-online.png"
                    className=""
                     style={{
                        position: "relative",
                        right: "11px"
                        , top: "17px"
                      }}
                    height={9}
                    alt="dot sign"
                  /> :
                  <img
                    src="/Images/contact-idle.png"
                    className=""
                     style={{
                        position: "relative",
                        right: "11px"
                        , top: "17px"
                      }}
                    height={9}
                    alt="dot sign"
                  />}

                <p className="jacob">
                  {friends.fname}
                  <br />
                  {friends.lname}
                </p>
              </div>
            );
          })}
        </div>
        <div className="px-4 py-3 w-100">
          <div className="form-div rounded-3 d-flex row py-2">
            <div className="col-xl-8 col-lg-7 col-md-12 col-sm-12 pb-2 pb-lg-0 pb-xl-0 d-flex">
              {Friend.length > 0 ? (
                <div className="friends_row ps-3">
                  {Friend.map((friends, index) => {
                    return (
                      <div
                        className=" col-xl-1 col-lg-2 col-md-2 col-sm-2 col-3 me-5 position-relative"
                        style={{ cursor: "pointer" }}
                        key={index}
                      >
                        <FiX
                          className="position-absolute"
                          color="black"
                          style={{
                            left: "25px",
                            backgroundColor: "white",
                            borderRadius: "10px",
                            padding: "3px",
                          }}
                          size={18}
                          onClick={() => closeFriend(friends)}
                        />
                        <img
                          className="img rounded-5 "
                          src={
                            friends?.item?.profile_pic
                              ? friends?.item?.profile_pic
                              : "/img/upload-photo.png"
                          }
                          style={{ width: "40px", height: "40px" }}
                          alt="Admin"
                        />
                        <p className="jacob">
                          {friends?.item?.fname}
                          {/* &nbsp; {friends?.item?.lname} */}
                        </p>
                      </div>
                    );
                  })}
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="d-flex justify-content-between">
              <h6 className="mt-4">{Friend.length} Friends(s) Selected</h6>
              <div className="">
                <button
                  className="friends-btn1"
                  type="button"
                  onClick={() => setFriend([])}
                >
                  Cancel
                </button>
                <button
                  className="friends-btn2"
                  type="button"
                  onClick={() => {
                    if (Friend.length === 0) {
                      alert("Please Select Atlest One Friend");
                    }
                    if (Friend.length > 0) {
                      if (Friend.length > 1) {
                        setChatData(Friend);
                        CreateGroup();
                        setTimeout(() => {
                          setChatBox();
                        }, 2000);
                      } else {
                        setChatBox();
                        setChatData(Friend);
                      }
                    }
                  }}
                >
                  {Friend.length > 1 ? "Create Group" : "Start Conversation"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Friends;
