import React, { useEffect, useState } from "react";
import Aside from "./Aside/Aside";
import Friends from "./Friends/Friends";
import Map from "./Map/Map";
import "../../assets/styles/Profile/Profile.css";
import { Server2 } from "../../redux/actions/auth/auth.actions";
import { MdApartment, MdLocalPhone, MdOutlineLanguage } from "react-icons/md";
import axios from 'axios'
import GalleryProfile from "../../components/GalleryProfile";
import ModalImage from "react-modal-image";
const ShareProfile = ({ userData }) => {
  if (userData.type === true) {
    var str = userData?.business_info?.address;
    if (str.length > 10) {
      // var address = str.substring(0, 25);
    }
  }
  const [GallaryImg, setGallaryImg] = useState([]);
  const [NewData, setNewData] = useState();
  useEffect(() => {
    let data = {
      user: userData._id,
    };
    var config = {
      method: "post",
      url: Server2 + "/users/get-gallery-images",
      headers: { "Content-Type": "application/json" },
      data: data,
    };
    axios(config)
      .then((response) => {
        setGallaryImg(response.data);
      })
      .catch((err) => {
        console.log(err, "error");
      });
    getOpeningHours()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getOpeningHours = () => {
    fetch(
      Server2 + `/users/userprofile?id=${userData._id}&user=${userData._id}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    ).then(async (response) => {
      const data = await response.json();
      setNewData(data.user)
    });
  }


  return (
    <div className="bg-white">
      <div className="px-3 py-2">
        <div>
          <div className="img-cover rounded-3 ">
            <ModalImage
            hideDownload	
              className="cover "
              small={
                userData?.cover_pic
                  ? userData?.cover_pic
                  : "/Images/image45.png"
              }
              large={
                userData?.cover_pic
                  ? userData?.cover_pic
                  : "/Images/image45.png"
              }
              alt={`${userData.fname} ${userData.lname}`}
            />
          </div>
          <div className="profile row d-flex px-0 justify-content-center align-items-center">
            <div className="col-lg-2 col-md-3 col-sm-12 order-lg-1 order-md-1 order-2 text-center text-md-end">
              <ModalImage
              hideDownload	
                className="img-admin img-fluid shadow"
                small={
                  userData.profile_pic
                    ? userData.profile_pic
                    : "/img/upload-photo.png"
                }
                large={
                  userData.profile_pic
                    ? userData.profile_pic
                    : "/img/upload-photo.png"
                }
                alt={`${userData.fname} ${userData.lname}`}
              />
            </div>
            <div className="col-lg-5 col-md-3 col-sm-12 order-lg-2 order-md-2 order-1 pt-3 ">
              <p
                className="fw-bold fs-3 text-lg-start text-md-start text-center text-white"
                style={{ textShadow: "1px 1px #000000" }}
              >
                {userData.fname} {userData.lname}
              </p>
            </div>
            <div className="col-lg-5 col-md-6 order-lg-2 order-md-2 "></div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8 col-md-12 col-sm-12 mt-5 ">
            <div
              style={{
                // height: "87px",
                // overflow: "auto",
                marginBottom: "15px",
              }}
            >
              <p
                className="fw-bold text-dark ps-2"
                style={{ fontSize: "16px" }}
              >
                Business Summary
              </p>
              <p
                className="text-dark ps-2 mt-1"
                style={{ fontSize: "12px", lineHeight: "18px" }}
              >
                {userData.business_info.business_summary === null
                  ? " "
                  : userData.business_info.business_summary}
              </p>
            </div>
            {/* Business Offers */}
            <div className="border1"></div>
            <div className="rounded-3 shadow  px-3 py-3 mb-3">
              {/* Contacts */}
              <div className="row text-dark">
                <div className="pt-2 pe-xxl-4 pe-xl-3 pe-lg-2">
                  <span className="fw-bold fs-6">Business Offer</span>
                  <p className="" style={{
                    minHeight: "40px",
                    maxHeight:"fit-content",
                    // overflow: "auto",
                    marginBottom: "5px",
                  }}>
                    {userData?.business_info?.about_company.map((about, Index) => {
                      const last = userData?.business_info?.about_company.slice(-1)[0]
                      return (
                        <span
                          key={Index}
                          className=""
                          style={{
                            fontSize: "12px",
                            font: "Roboto",
                            fontWeight: "300",
                          }}
                        >
                          {about}{about === last ? null : ","} &nbsp;
                        </span>
                      )
                    })}
                  </p>
                </div>
              </div>
            </div>
            <div className="border1"></div>
            <div className="rounded-3 shadow p-4">
              {/* Contacts */}
              <div className="row text-dark mb-2 text-size w-100">
                <div className="pe-xxl-4 pe-xl-3 pe-lg-2 col-6">
                  <>
                  {/* <span className="fw-bold fs-6">Business Offer</span>
                        <p className="" style={{
                          height: "40px",
                          overflow: "auto",
                          marginBottom: "15px",
                        }}>
                          {userData?.business_info?.about_company.map((about, Index) => {
                            const last = userData?.business_info?.about_company.slice(-1)[0]
                            return (
                              <span
                                key={Index}
                                className=""
                                style={{
                                  fontSize: "16px",
                                  font: "Roboto",
                                  fontWeight: "300",
                                }}
                              >
                                {about}{about === last ? null : ","} &nbsp;
                              </span>
                            )
                          })}
                        </p> */}
                  
                  </>
                  <span className="fw-bold text-heading-size">Opening Hours</span>
                  <table>
                    <tbody>
                      <tr>
                        <td>Monday</td>
                        <td>:&nbsp;</td>
                        {NewData?.business_info?.business_hours[0]?.Monday?.isOff === true ? 
                          <td>Closed</td> :                            
                          <td>{NewData?.business_info?.business_hours[0]?.Monday?.opening
                            ? NewData?.business_info?.business_hours[0]?.Monday?.opening : "09:00 AM"}
                            &nbsp;-&nbsp;
                            {NewData?.business_info?.business_hours[0]?.Monday?.closing
                              ? NewData?.business_info?.business_hours[0]?.Monday?.closing : "09:00 PM"}
                          </td>
                        }
                      </tr>
                      <tr>
                        <td>Tuesday</td>
                        <td>:&nbsp;</td>
                        {NewData?.business_info?.business_hours[1]?.Tuesday?.isOff === true ? 
                          <td>Closed</td> :                            
                          <td>{NewData?.business_info?.business_hours[1]?.Tuesday?.opening
                            ? NewData?.business_info?.business_hours[1]?.Tuesday?.opening : "09:00 AM"}
                            &nbsp;-&nbsp;
                            {NewData?.business_info?.business_hours[1]?.Tuesday?.closing
                              ? NewData?.business_info?.business_hours[1]?.Tuesday?.closing : "09:00 PM"}
                          </td>
                        }
                      </tr>
                      <tr>
                        <td>Wednesday&nbsp;</td>
                        <td>:&nbsp;</td>
                        {NewData?.business_info?.business_hours[2]?.Wednesday?.isOff === true ? 
                          <td>Closed</td> :                            
                          <td>{NewData?.business_info?.business_hours[2]?.Wednesday?.opening
                            ? NewData?.business_info?.business_hours[2]?.Wednesday?.opening : "09:00 AM"}
                            &nbsp;-&nbsp;
                            {NewData?.business_info?.business_hours[2]?.Wednesday?.closing
                              ? NewData?.business_info?.business_hours[2]?.Wednesday?.closing : "09:00 PM"}
                          </td>
                        }
                      </tr>
                      <tr>
                        <td>Thursday</td>
                        <td>:&nbsp;</td>
                        {NewData?.business_info?.business_hours[3]?.Thursday?.isOff === true ? 
                          <td>Closed</td> :                            
                          <td>{NewData?.business_info?.business_hours[3]?.Thursday?.opening
                            ? NewData?.business_info?.business_hours[3]?.Thursday?.opening : "09:00 AM"}
                            &nbsp;-&nbsp;
                            {NewData?.business_info?.business_hours[3]?.Thursday?.closing
                              ? NewData?.business_info?.business_hours[3]?.Thursday?.closing : "09:00 PM"}
                          </td>
                        }
                      </tr>
                      <tr>
                        <td>Friday</td>
                        <td>:&nbsp;</td>
                        {NewData?.business_info?.business_hours[4]?.Friday?.isOff === true ? 
                          <td>Closed</td> :                            
                          <td>{NewData?.business_info?.business_hours[4]?.Friday?.opening
                            ? NewData?.business_info?.business_hours[4]?.Friday?.opening : "09:00 AM"}
                            &nbsp;-&nbsp;
                            {NewData?.business_info?.business_hours[4]?.Friday?.closing
                              ? NewData?.business_info?.business_hours[4]?.Friday?.closing : "09:00 PM"}
                          </td>
                        }
                      </tr>
                      <tr>
                        <td>Saturday</td>
                        <td>:&nbsp;</td>
                        {NewData?.business_info?.business_hours[5]?.Saturday?.isOff === true ? 
                          <td>Closed</td> :                            
                          <td>{NewData?.business_info?.business_hours[5]?.Saturday?.opening
                            ? NewData?.business_info?.business_hours[5]?.Saturday?.opening : "09:00 AM"}
                            &nbsp;-&nbsp;
                            {NewData?.business_info?.business_hours[5]?.Saturday?.closing
                              ? NewData?.business_info?.business_hours[5]?.Saturday?.closing : "09:00 PM"}
                          </td>
                        }
                      </tr>
                      <tr>
                        <td>Sunday</td>
                        <td>:&nbsp;</td>
                        {NewData?.business_info?.business_hours[6]?.Sunday?.isOff === true ? 
                          <td>Closed</td> :                            
                          <td>{NewData?.business_info?.business_hours[6]?.Sunday?.opening
                            ? NewData?.business_info?.business_hours[6]?.Sunday?.opening : "09:00 AM"}
                            &nbsp;-&nbsp;
                            {NewData?.business_info?.business_hours[6]?.Sunday?.closing
                              ? NewData?.business_info?.business_hours[6]?.Sunday?.closing : "09:00 PM"}
                          </td>
                        }
                      </tr>
                    </tbody>
                  </table>
                  <>
                  {/* <p>
                        Monday : &nbsp;&nbsp;
                        <span style={{marginLeft:"22px" , fontSize:"16px"}}>
                        {NewData?.business_info?.business_hours[0]?.Monday?.opening
                          ? NewData?.business_info?.business_hours[0]?.Monday?.opening: "09:00 AM"}
                          &nbsp;-&nbsp; 
                        {NewData?.business_info?.business_hours[0]?.Monday?.closing
                          ? NewData?.business_info?.business_hours[0]?.Monday?.closing : "09:00 PM"}
                        </span>
                        <br />
                        Tuesday : &nbsp;&nbsp;
                        <span style={{marginLeft:"20px" , fontSize:"16px"}}>
                        {NewData?.business_info?.business_hours[1]?.Tuesday?.opening
                          ? NewData?.business_info?.business_hours[1]?.Tuesday?.opening: "09:00 AM"}
                        &nbsp;-&nbsp;
                        {NewData?.business_info?.business_hours[1]?.Tuesday?.closing 
                        ? NewData?.business_info?.business_hours[1]?.Tuesday?.closing : "09:00 PM"}
                        </span>
                        <br />
                        Wednesday : &nbsp;
                        {NewData?.business_info?.business_hours[2]?.Wednesday?.opening
                        ?NewData?.business_info?.business_hours[2]?.Wednesday?.opening:"09:00 AM"}
                         &nbsp;-&nbsp;
                        {NewData?.business_info?.business_hours[2]?.Wednesday?.closing
                        ?NewData?.business_info?.business_hours[2]?.Wednesday?.closing:"09:00 PM"}
                        <br />
                        Thursday : &nbsp;&nbsp;
                        <span style={{marginLeft:"12px" , fontSize:"16px"}}>
                        {NewData?.business_info?.business_hours[3]?.Thursday?.opening
                        ?NewData?.business_info?.business_hours[3]?.Thursday?.opening:"09:00 AM"}
                        &nbsp;-&nbsp; 
                        {NewData?.business_info?.business_hours[3]?.Thursday?.closing
                        ?NewData?.business_info?.business_hours[3]?.Thursday?.closing:"09:00 PM"}
                        </span>
                        <br />
                        Friday  : &nbsp;&nbsp;
                        <span style={{marginLeft:"34px" , fontSize:"16px"}}>
                        {NewData?.business_info?.business_hours[4]?.Friday?.opening
                        ?NewData?.business_info?.business_hours[4]?.Friday?.opening:"09:00 AM"}
                        &nbsp;-&nbsp;
                        {NewData?.business_info?.business_hours[4]?.Friday?.closing
                        ?NewData?.business_info?.business_hours[4]?.Friday?.closing:"09:00 PM"}
                        </span>
                        <br />
                        Saturday : &nbsp;&nbsp;
                        <span style={{marginLeft:"14px" , fontSize:"16px"}}>
                        {NewData?.business_info?.business_hours[5]?.Saturday?.opening
                        ?NewData?.business_info?.business_hours[5]?.Saturday?.opening:"09:00 AM"}
                         &nbsp;-&nbsp;
                          {NewData?.business_info?.business_hours[5]?.Saturday?.closing
                          ?NewData?.business_info?.business_hours[5]?.Saturday?.closing:"09:00 PM"}
                        </span>
                        <br />
                        Sunday  : &nbsp;&nbsp;
                        <span style={{marginLeft:"24px" , fontSize:"16px"}}>
                        {NewData?.business_info?.business_hours[6]?.Sunday?.opening
                        ?NewData?.business_info?.business_hours[6]?.Sunday?.opening:"09:00 AM"}
                         &nbsp;-&nbsp; 
                         {NewData?.business_info?.business_hours[6]?.Sunday?.closing
                         ?NewData?.business_info?.business_hours[6]?.Sunday?.closing:"09:00 PM"}
                         </span>
                      </p> */}
                  </>
                </div>

                <div className="ps-xxl-5 ps-xl-4 ps-lg-3 col-6">
                  <span className="fw-bold text-heading-size pb-2">
                    About Business
                  </span>
                  <div className="d-flex align-items-center about-b-divs pb-1 text-nowrap">
                    <MdLocalPhone
                      className="icon-size me-2"
                      style={{minWidth:"20px"}}
                      color="#6599AF"
                    />
                    <span className="fw-bold text-size">
                      Business Contact:
                    </span>
                    <span className="p-text-size">&nbsp;+{userData.business_info.phone_number}</span>
                  </div>
                  <div className="d-flex align-items-center about-b-divs pb-1 text-nowrap">
                    <MdApartment
                      className="icon-size me-2"
                      style={{minWidth:"20px"}}
                      color="#6599AF"
                    />
                    <span className="fw-bold text-size">
                      Business Category:
                    </span>
                    <span className="F-text-size">&nbsp;{userData.business_category}</span>
                  </div>
                  <div className="d-flex align-items-center about-b-divs pb-1 text-nowrap">
                    <MdOutlineLanguage
                      className="icon-size me-2"
                      style={{minWidth:"20px"}}
                      color="#6599AF"
                    />
                    <span className="fw-bold text-size">
                      Website URL:
                    </span>
                    {userData.business_info.address !== null ? (
                      <a
                        href={`https://${userData.business_info.address}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-decoration-none ms-2 adress-size"
                        style={{
                          color: "#6148a1",
                        }}
                      >
                        {userData?.business_info?.address}
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              {/* ^ Gallary */}
              <GalleryProfile GallaryImg={GallaryImg} id={userData?._id} />
            </div>

            {/* Map Start */}
            <div style={{ marginTop: "15px" }}>
              <Map userData={userData} />
            </div>
            {/* Friends */}
            <Friends userData={userData} />
            {/* ^ Map End */}
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12">
            <Aside userData={userData} />
            {/* ^ New Post Div */}
          </div>
          {/* ^ Col-4 */}
        </div>
      </div>
    </div>
  );
};

export default ShareProfile;