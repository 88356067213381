import { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import Protected from "./Protected";
import Login from "../logIn";
import CardComponetSignup from "../Pages/auth/SignUp/cardcompSignup";
import NewPassword from "../Pages/auth/newpassword";
import "../assets/styles/Profile/Profile.css";
import NavbarHome from "../Pages/home/NavbarNew/NavbarHome";
import { LoadBoot } from "../components/LoadBoot";
import Home from "../Pages/home";
import ThankYouModel from "../components/Bussiness Steps/ThankYouModel";
import TermsOfService from "../components/TermsOfService";
import Admin from "../Admin/Admin";
import FirstStep from "../components/Presonal Steps/FirstStep";
import BussinessSteps from "../components/Bussiness Steps/BussinessSteps";
import PresonalSecondStep from "../components/Presonal Steps/PresonalSecondStep";
import PresonalThirdStep from "../components/Presonal Steps/PersonalThirdStep";
import FourthStep from "../components/Bussiness Steps/FourthStep";
import Dashboard from "../Pages/Dashboard/Dashboard";
import MyProfile from "../Pages/My-Profile/MyProfile";
import UserProfile from "../Pages/User_Profile/UserProfile";
import Profilesssss from "../Pages/User_Profile/Profilesssss";
import EventScreen from "../Pages/home/AddEventsPopup/EventScreen";
import Main from "../Pages/bussinessShare/Main";
import MainSettings from "../Pages/Settings/MainSettings";
import About from "../Pages/auth/footerLogin/About";
import Blog from "../Pages/auth/footerLogin/Blog";
import Contact from "../Pages/auth/footerLogin/Contact";
import Mission from "../Pages/auth/footerLogin/Mission";
import Press from "../Pages/auth/footerLogin/Press";
import PressRelease from "../Pages/auth/footerLogin/PressRelease";
import BlogLandingPage from "../Pages/auth/footerLogin/BlogLandingPage";
import PressRelese2 from "../Pages/auth/footerLogin/PressRelese2";
import Privacy from "../Pages/auth/footerLogin/Privacy";
import Terms from "../Pages/auth/footerLogin/Terms";
import Demo from "../Pages/Demo/Demo";
const HighMap = lazy(() => import("../Pages/MapOverview/HighMap"));
const HighMap2 = lazy(() => import("../Pages/MapOverview/HighMap"));
const SettingRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<CardComponetSignup />} />
        <Route path="/new_password" element={<NewPassword />} />

        {/* presonal Steps routes */}
        <Route
          path="/firstStep"
          element={<Protected Component={FirstStep} />}
        />
        <Route
          path="/secondStep"
          element={<Protected Component={PresonalSecondStep} />}
        />
        <Route
          path="/thirdStep"
          element={<Protected Component={PresonalThirdStep} />}
        />
        {/* Bussiness Steps routes */}
        <Route
          path="/businessStep"
          element={<Protected Component={BussinessSteps} />}
        />
        <Route path="/fourthStep" element={<FourthStep />} />
        <Route path="/thankyoumodel" element={<ThankYouModel />} />
        <Route path="/TermsOfService" element={<TermsOfService />} />
        {/* Home */}
        <Route path="/home" element={<Protected Component={Home} />} />

        {/* New Password */}
        {/* My Profile */}
        <Route
          path="/myProfile"
          element={<Protected Component={MyProfile} />}
        />
        <Route path="/Demo" element={<Demo />} />
        <Route path="/profiless" element={<Profilesssss />} />
        <Route path="/profile" element={<UserProfile />} />
        <Route path="/BusinessProfile/:id" element={<Main />} />
        <Route
          path="/dashboard"
          element={<Protected Component={Dashboard} />}
        />
        <Route  path="/mapOverview" element={
          <Suspense fallback={
            <>
              <NavbarHome />
              <LoadBoot/>
            </>
          }>
            <Protected Component={HighMap} />
          </Suspense>
        } />
        {/* Events rouute */}
        <Route path="/Event" element={<EventScreen />} />
        {/* Setting rouute */}
        <Route
          path="/setting"
          element={<Protected Component={MainSettings} />}
        />
        {/* Login Footer */}
        <Route path="/about.html" element={<About />} />
        <Route path="/contact.html" element={<Contact />} />
        <Route path="/mission.html" element={<Mission />} />
        <Route path="/press" element={<Press />} />
        <Route path="/blog.html" element={<Blog />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/pressBlog" element={<PressRelease />} />
        <Route path="/blogLandingPage.html" element={<PressRelese2 />} />
        <Route path="/blogLanding" element={<BlogLandingPage />} />

        {/* Admin routes */}
        <Route path="/admin" element={<Protected Component={Admin} />} />

        {/* Mobile Route used */}
        <Route
          path="/to"
          element={
            <Suspense fallback={<LoadBoot />}>
              <HighMap2 />
            </Suspense>
          }
        />
      </Routes>
    </>
  );
};

export default SettingRoutes;
