import React from "react";
import { Container, Nav, Navbar, NavLink } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "../../../assets/styles/home.css";
import "../../../assets/styles/Footer/contactUs.css";

const FooterNav = () => {
  let navigate = useNavigate();
  return (
    <div className="m-3 mb-3">
      <Navbar
        collapseOnSelect
        expand="lg"
        bg=""
        variant="dark"
        className="rounded-3 position-relative pb-2"
      >
        <Container fluid>
          <Navbar.Brand className="pe-4 me-3" style={{ cursor: "pointer" }}   onClick={() => {
                navigate("/");
              }}>
            <img src="/img/LogoNav.png" height={30} alt="ibouge" />
            <span style={{color: "white", position: "relative", top: "-8px", fontWeight:"300", fontSize: "0.8rem"}}>&#174;</span>
          </Navbar.Brand>
          <Navbar.Toggle
            id="basic-navbar-nav"
            aria-controls="navbarScroll"
            data-bs-target="#navbarScroll"
            className="me-3"
          />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="Navfooter gap-2">
              <NavLink className="" eventKey="1" as={Link} to="/about.html">
                About
              </NavLink>
              <NavLink className="" eventKey="2" as={Link} to="/contact.html">
                Contact
              </NavLink>
              <NavLink className="" eventKey="3" as={Link} to="/mission.html">
                Mission
              </NavLink>
              <NavLink className="" eventKey="4"  as={Link} to="/Demo">
                Demo
              </NavLink>
              <NavLink className="" eventKey="5" as={Link} to="/press">
                Press
              </NavLink>
              <NavLink className="" eventKey="6" as={Link} to="/blog.html">
                Blog
              </NavLink>
              <NavLink className="" eventKey="7" as={Link} to="/privacy">
                Privacy
              </NavLink>
              <NavLink className="" eventKey="8" as={Link} to="/terms">
                Terms of Service
              </NavLink>
            </Nav>
            <button
              className="loginbtn text-white ms-auto"
              onClick={() => {
                navigate("/login");
              }}
            >
              Login
            </button>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default FooterNav;
