import React, { useEffect, useRef, useState } from "react";
import "../../../assets/styles/Dashboard/Dashboard.css";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/src/css/mapbox-gl.css";
import { AiFillSetting } from "react-icons/ai";
import { FiX } from "react-icons/fi";
import { FaBookmark, FaLock, FaLockOpen } from "react-icons/fa";
import { FaAngleDown } from "react-icons/fa";
import { IoMdPersonAdd } from "react-icons/io";
import { MdSend } from "react-icons/md";
import Icon from "@mdi/react";
import { mdiSleep } from "@mdi/js";
import { BsArrowLeftShort, BsPaperclip } from "react-icons/bs";
import ModalImage from "react-modal-image";
import { useSelector } from "react-redux";
import { Server2, Socket, thumbnailGen } from "../../../redux/actions/auth/auth.actions";
import axios from "axios";
import moment from "moment/moment";
import InputEmoji from "react-input-emoji";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../../components/Loader";
import LinkPreview from "../../../components/LinkPreview";
import Linkify from "linkify-react";
const MicroMap = ({ MicroData }) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [zoom] = useState(8);
  useEffect(() => {
    if (map.current) return; //stops map from intializing more than once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: `${process.env.REACT_APP_MAPBOX_STYLE}?key=${process.env.REACT_APP_MAPBOX_API_KEY}`,
      center: [
        MicroData[0]?.location?.coordinates[0]?.coords?.lng,
        MicroData[0]?.location?.coordinates[0]?.coords?.lat,
      ],
      zoom: zoom,
    });
    map.current.scrollZoom.disable();
    map.current.dragPan.disable();
    map.current.addControl(new mapboxgl.NavigationControl(), "bottom-right");
    new mapboxgl.Marker({ color: "#614A7E" })
      .setLngLat([
        MicroData[0]?.location?.coordinates[0]?.coords?.lng,
        MicroData[0]?.location?.coordinates[0]?.coords?.lat,
      ])
      .addTo(map.current);
  });
  return (
    <div className="mb-body my-3 ">
      <div className="map-wrap-microblog">
        <div ref={mapContainer} className="map-microblog" />
      </div>
    </div>
  );
};

function MicroblogActivities({ ActivitiesClose, MicroData }) {
  const userData = useSelector((state) => state?.auth?.user?.user);
  const navigate = useNavigate();
  const [mind, setMind] = useState("");
  const [microChat, setMicroChat] = useState([]);
  const [StatusBookMark, setStatusBookMark] = useState(false);
  const [AddUserOpen, setAddUserOpen] = useState(false);
  const [SettingOpen, setSettingOpen] = useState(false);
  const [Text, setText] = useState("");
  const [SearchUsers, setSearchUsers] = useState([]);
  const [SearchToggle, setSearchToggle] = useState(false);
  // images & video
  const [files, setFiles] = useState(null);
  const [image, setImage] = useState("");
  const [TypeFile, setTypeFile] = useState("");
  const [IsUpload, setIsUpload] = useState(false);
  const [videoSrc, setVideoSrc] = useState(null);
  const [loader, setLoader] = useState(false);
  const [MicroUsers, setMicroUsers] = useState([]);
  const [MicroUsersId, setMicroUsersId] = useState([]);
  const [ShowData, setShowData] = useState(false);
  const [ShowSilent, setShowSilent] = useState(false);
  const [MicroPublic, setMicroPublic] = useState(
    MicroData[0]?.is_private === true ? false : true
  );
  const [TypingIcon, setTypingIcon] = useState(false);
  const [DeleteUser, setDeleteUser] = useState({
    index: "",
    data: "",
  });
  const [isOpen, setIsOpen] = useState(false);
  const [readByNameOpen, setReadByNameOpen] = useState({ name: "", index: "" });
  const [isReadByOpen, setIsReadByOpen] = useState({state: 0, index: 0});
  const [thumbnail, setThumbnail] = useState("");
  
  useEffect(() => {
    GetChatMicro();
    GetUsersListApi();
    GetStatusBookMarkApi();
    getSilentMicro();
    const room = {
      id: userData?._id,
      room: MicroData[0].room,
    };
    Socket.emit("microblog-seen", room);
    Socket.emit("join-room", room);
    Socket.emit("open-microblog", room);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const GetUsersListApi = () => {
    // Get all users of microblogs
    var config = {
      method: "GET",
      url: Server2 + `/microblogs/room?room=${MicroData[0].room}`,
    };
    axios(config)
      .then((response) => {
        if (response.data) {
          setMicroUsers(response.data.users);
          let a = [];
          response.data.users.forEach((item) => {
            if (item?.user_id !== userData?._id || item?.user_id?.length > 0) {
              a.push(item?.user_id);
            }
          });
          setMicroUsersId(a);
        }
      })
      .catch(function (error) {
        console.log("error micro chat get ", error);
      });
  };
  useEffect(() => {
    const handler = (chat) => {
      Socket.emit("microblog-seen", {
        id: userData?._id,
        room: MicroData[0].room,
      });
      GetChatMicro();
    };
    const getSeen = () => {
      GetChatMicro();
      // getMicroblogSeenUsers();
    };

    Socket.on("new-microblogSeen", getSeen);

    Socket.on("microblogMessage", handler);
    return () => {
      Socket.off("microblogMessage", handler);
    };
  }, [Socket]); // eslint-disable-line react-hooks/exhaustive-deps

  //Typing show are hide
  useEffect(() => {
    Socket.on("typing", (data) => {
      if (data.room === MicroData[0]?.room) {
        if (data.statustyping === false) {
          setTypingIcon(false);
        } else if (data.statustyping === true) {
          setTypingIcon(true);
        }
      }
    });
    return () => {
      Socket.off("typing");
    };
  }, [mind]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    const prev = mind;
    if (mind.length > 0) {
      const data = {
        from: userData?._id,
        to: MicroUsersId,
        room: MicroData[0]?.room,
        statustyping: true,
      };
      Socket.emit("typing", data);
      const timer = setTimeout(() => {
        if (mind === prev) {
          const data = {
            from: userData?._id,
            to: MicroUsersId,
            room: MicroData[0]?.room,
            statustyping: false,
          };
          Socket.emit("typing", data);
        }
      }, 2000);

      return () => clearTimeout(timer);
    } else {
      const data = {
        from: userData?._id,
        to: MicroUsersId,
        room: MicroData[0]?.room,
        statustyping: false,
      };
      Socket.emit("typing", data);
    }
  }, [mind]); // eslint-disable-line react-hooks/exhaustive-deps
  const GetChatMicro = () => {
    // setMicroChat([]);
    var config = {
      method: "GET",
      url:
        Server2 +
        `/microblogs/history?room=${MicroData[0].room}&limit=20&offset=0`,
    };
    axios(config)
      .then((response) => {
        if (response.data) {
         let b=[]
          response.data.filter((e) => {
            if(!e.is_disabled){
             b.push(e) 
            }
          })
          setMicroChat(b);
        }
      })
      .catch(function (error) {
        console.log("error micro chat get ", error);
      });
  };
  const inputFile = useRef(null);
  const onButtonClick = () => {
    inputFile.current.click();
  };
  const videoRef = useRef();
  useEffect(() => {
    videoRef.current?.load();
    if (TypeFile === "video" && image) {
      thumbnailGen(files).then((thumb) => {
       setThumbnail(thumb)
     })
   }
  }, [videoSrc , image]);
  const handleImageChange = (e) => {
    if(e.target.files[0].size > 52428800){
      alert("File size is too big");
      return
    }
    inputFile.current.click();
    setFiles(e.target.files[0]);
    const type = e.target.files[0].type.split("/");
    setTypeFile(type[0]);
    if (e.target.files && e.target.files[0]) {
      if (TypeFile === "video") {
        setImage("");
        const file = e.target.files[0];
        if (!file) return;
        setImage(URL.createObjectURL(file));
        setIsUpload(true);
      } else {
        setImage("");
        let reader = new FileReader();
        reader.onload = function (e) {
          setImage(e.target.result);
          setIsUpload(true);
        };
        reader.readAsDataURL(e.target.files[0]);
      }
    }
  };
  const Add = () => {
    setIsUpload(false);
    StatusUpdate();
    setMind("");
    setFiles(null);
  };
  const AddUserMicroBlogApi = () => {
    var data = {
      user: userData._id,
      room: MicroData[0].room,
    };
    var config = {
      method: "POST",
      url: Server2 + "/microblogs/add-user",
      data: data,
    };
    axios(config)
      .then((response) => {
        if (response.data) {
          GetUsersListApi();
        }
      })
      .catch(function (error) {
        console.log("error Adduser micro blog api  ", error);
      });
  };
  const checkArray = () => {
    const x = MicroUsers.find((item) => item.user_id === userData?._id);
    return x ? true : false;
  };
  const StatusUpdate = () => {
    if (checkArray() !== true) {
      AddUserMicroBlogApi();
      console.log("Object found inside the array.");
    }
    if (mind !== "" || files !== null) {
      if (TypeFile === "video") {
        setLoader(true);
      }
      let data = new FormData();
      data.append("file", files !== null ? files : null);
      data.append("albumName", "microblogimage");
      data.append("thumbnail", thumbnail ? thumbnail : null);
      data.append("from", userData._id);
      data.append("message", mind);
      data.append("microblogRoom", MicroData[0].room);
      data.append("message_type", TypeFile ? TypeFile : "text");
      var config = {
        method: "post",
        url: Server2 + "/microblogs/new-message",
        data: data,
      };
      axios(config).then(function (response) {
        GetChatMicro();
        setLoader(false);
        var mssg = {
          room: MicroData[0].room,
          from: userData?._id,
          name: MicroData[0].name,
          message: mind,
        };
        Socket.emit("microblog-seen", {
          id: userData?._id,
          room: MicroData[0]?.room,
        });
        Socket.emit("microblog-message", mssg);
      });
    } else {
      alert("Enter Msg Firstly");
    }
  };
  // Add Bookmark Api
  const BookMarkApi = () => {
    var data = {
      id: userData?._id,
      room: MicroData[0].room,
    };
    var config = {
      method: "POST",
      url: Server2 + "/microblogs/bookmark-microblog",
      data: data,
    };
    axios(config)
      .then((response) => {
        GetStatusBookMarkApi();
      })
      .catch(function (error) {
        console.log("error bookmarkapi  ", error);
      });
  };
  const GetStatusBookMarkApi = () => {
    var config = {
      method: "GET",
      url:
        Server2 +
        `/microblogs/getbyid?user=${userData?._id}&room=${MicroData[0].room}`,
    };
    axios(config)
      .then((response) => {
        setStatusBookMark(response.data.if_boomarked);
      })
      .catch(function (error) {
        console.log("error micro chat get ", error);
      });
  };
  useEffect(() => {
    if (Text.length > 0) {
      var config = {
        method: "get",
        url: Server2 + `/users/globalSearch?id=${userData?._id}&q=${Text}`,
      };
      axios(config)
        .then(function (response) {
          setSearchUsers([]);
          setSearchUsers(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [Text]); // eslint-disable-line react-hooks/exhaustive-deps
  const InviteApi = (id) => {
    var data = {
      id: userData?._id,
      room: MicroData[0].room,
      name: MicroData[0].name,
      users: id,
    };
    var config = {
      method: "POST",
      url: Server2 + "/microblogs/invite-friend",
      data: data,
    };
    axios(config)
      .then((response) => {
        const data = {
          to: id,
        };
        Socket.emit("microblog-invite", data);
        setText("");
        setAddUserOpen(false);
        setSearchUsers([]);
        setSearchToggle(false);
      })

      .catch(function (error) {
        console.log("error", error);
      });
  };
  const silentMicro = () => {
    var data = {
      id: userData?._id,
      room: MicroData[0].room,
      silent: ShowSilent ? false : true,
    };
    var config = {
      method: "POST",
      url: Server2 + "/microblogs/silent-user",
      data: data,
    };
    axios(config)
      .then((response) => {
        getSilentMicro();
        console.log(response.data, "silent");
      })
      .catch(function (error) {
        console.log("error bookmarkapi  ", error);
      });
  };
  const getSilentMicro = () => {
    var config = {
      method: "GET",
      url:
        Server2 +
        `/microblogs/get-silent-user?room=${MicroData[0].room}&id=${userData._id}`,
    };
    axios(config)
      .then((response) => {
        console.log(response.data, "res");
        setShowSilent(response.data);
      })
      .catch(function (error) {
        console.log("error micro chat get ", error);
      });
  };
  const publicMicroblog = () => {
    const data = {
      id: userData?._id,
      room: MicroData[0]?.room,
      set: MicroData[0]?.is_private ? false : true,
    };
    Socket.emit("microblog-setPrivate", data);
    var config = {
      method: "POST",
      url: Server2 + `/microblogs/set-private`,
      data: data,
    };
    axios(config)
      .then((response) => {
        setSettingOpen(false);
        setMicroPublic(!MicroPublic);
        Socket.emit("microblog-setPrivate", data);
      })
      .catch(function (error) {
        console.log("error micro chat get ", error);
      });
  };
  const DeleteUserMicro = (e) => {
    console.log("click", e);
    const data = {
      user: e?.user_id,
      room: MicroData[0]?.room,
    };
    var config = {
      method: "POST",
      url: Server2 + `/microblogs/remove-user`,
      data: data,
    };
    axios(config)
      .then((response) => {
        console.log(response, "sala res");
        if (response.data) {
          GetUsersListApi();
        }
      })
      .catch(function (error) {
        console.log("error in Delete user", error);
      });
  };

  return (
    <>
      <div className="position-relative mb-activities-main pe-4 ps-2 m-auto">
        <div
          className="mb-activities border rounded-3 shadow bg-light position-relative d-flex flex-column w-100"
          style={{ zIndex: "300px" }}
        >
          <div className="mba-header py-1 d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <FaAngleDown
                className="mt-1 mx-2 text-light"
                size={16}
                onClick={() => setIsOpen(!isOpen)}
              />
              <div className="me-3 d-flex flex-column">
                <span
                  className="fw-bold text-white py-0 my-0 "
                  onClick={() => setShowData(true)}
                  style={{
                    cursor: "pointer",
                    position: "relative",
                    top: "4px",
                    fontSize: "14px",
                  }}
                >
                  {MicroData[0]?.name}
                </span>
                {/* {MicroPublic === false ? <>
                  <FaLock className="ms-2 mt-1 text-white" size={11} />
                </> : <>
                  <FaLockOpen className="ms-2 mt-1 text-white" size={11} />
                </>} */}
                <span
                  className="my-0 py-0 text-white"
                  style={{
                    fontSize: "12px",
                    position: "relative",
                    top: "-1px",
                  }}
                >
                  {MicroPublic === true ? "public" : "private"}
                </span>
              </div>
            </div>

            <div className="button-div gap-3 d-flex align-items-center">
              <Icon
                path={mdiSleep}
                style={{ cursor: "pointer", marginTop: "2px" }}
                className="fw-bold"
                size={1}
                color={ShowSilent ? "blue" : "white"}
                onClick={() => silentMicro()}
              />

              {MicroData[0].is_private === false ||
              MicroData[0].friendsInvited?.some((user) => {
                return user.to === userData._id;
              }) ||
              MicroData[0].created_by === userData?._id ? (
                <IoMdPersonAdd
                  style={{ cursor: "pointer", color: "white" }}
                  className="fw-bold"
                  size={18}
                  onClick={() => {
                    setIsOpen(false);
                    setAddUserOpen(true);
                    setSettingOpen(false);
                  }}
                />
              ) : null}

              <FaBookmark
                style={{ cursor: "pointer" }}
                className=""
                size={16}
                color={StatusBookMark === true ? "yellow" : "white"}
                onClick={() => {
                  BookMarkApi();
                }}
              />

              {MicroData[0]?.created_by === userData?._id ? (
                <AiFillSetting
                  className="text-white me-n1"
                  size={19}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSettingOpen(!SettingOpen);
                  }}
                />
              ) : null}
              {SettingOpen === true ? (
                <div
                  style={{
                    position: "absolute",
                    top: "49px",
                    right: "10px",
                    backgroundColor: "#fff",
                    width: "150px",
                    height: "fit-content",
                    maxHeight: "150px",
                    borderRadius: "6px",
                    overflow: "auto",
                    zIndex: 100,
                    padding: "5px",
                    textAlign: "center",
                    boxShadow: "0px 0px 7px 10px rgba(0,0,0,0.1)",
                  }}
                >
                  <p
                    className="text-dark m-auto py-2"
                    style={{ cursor: "pointer" }}
                    onClick={() => publicMicroblog()}
                  >
                    {MicroPublic ? (
                      <FaLock className="me-2" />
                    ) : (
                      <FaLockOpen className="me-2" />
                    )}
                    {MicroPublic ? "Make Private" : "Make Public"}
                  </p>
                </div>
              ) : null}
              <FiX
                className="fs-4 text-white p-0 me-2 ms-n1"
                type="button"
                data-bs-toggle="button"
                onClick={() => {
                  const data = {
                    id: userData?._id,
                    room: MicroData[0].room,
                  };
                  Socket.emit("close-microblog", data);
                  const data2 = {
                    from: userData?._id,
                    to: MicroUsersId,
                    room: MicroData[0]?.room,
                    statustyping: false,
                  };
                  Socket.emit("typing", data2);

                  ActivitiesClose();
                }}
              />
            </div>
          </div>
          {isOpen ? (
            <div
              className="d-flex flex-row overflow-scroll bg-light rounded-1 border-bottom border-right border-secondary border-1 py-2 px-3 gap-3"
              style={{
                position: "absolute",
                zIndex: "600",
                marginTop: "44px",
                width: "100%",
                boxShadow: "0px 10px 64px rgba(21, 48, 89, 0.3)",
              }}
            >
              {MicroUsers.length > 0
                ? MicroUsers.map((users, index) => {
                    return users ? (
                      <div
                        className="text-center mb-users-dd"
                        onMouseEnter={() => {
                          setDeleteUser({ index: index, data: users });
                        }}
                        onMouseLeave={() => {
                          setDeleteUser({ index: "", data: "" });
                        }}
                      >
                        <div
                          style={{ minWidth: "50px" }}
                          className="d-flex flex-column align-items-center"
                        >
                          <img
                            className="rounded-circle image mb-2"
                            src={
                              users?.profile_pic
                                ? users?.profile_pic
                                : "/img/upload-photo.png"
                            }
                            alt="usersDp"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              navigate("/Profiless", {
                                state: {
                                  item: users.user_id,
                                },
                              })
                            }
                          />
                          {DeleteUser.index === index &&
                          MicroData[0].created_by === userData._id ? (
                            <FiX
                              size={14}
                              className=" fw-bolder rounded-5"
                              style={{
                                padding: "2px",
                                position: "absolute",
                                top: "9px",
                                marginLeft: "31px",
                                cursor: "pointer",
                                backgroundColor: "#e40090",
                                color: "#fff",
                              }}
                              onClick={() => DeleteUserMicro(users)}
                            />
                          ) : null}
                        </div>
                        <p className="jacob" style={{ fontWeight: "600" }}>
                          {users?.fname}
                          <br />
                          {users?.lname}
                        </p>
                        {/* <span   style={{ wordBreak: users.fname.length > 10 ? "break-word" : "normal" }}>{ users?.fname }</span>
                        <br />
                        <span>{ users?.lname }</span> */}
                      </div>
                    ) : null;
                  })
                : null}
            </div>
          ) : null}
          {ShowData === false ? (
            <>
              <div
                className="chats d-flex flex-column-reverse"
                style={{
                  backgroundColor: "#fff",
                  height: "55vh",
                  overflowX: "hidden",
                  overflowY: "auto",
                }}
                onClick={() => setSettingOpen(false)}
              >
                {AddUserOpen === true ? (
                  <div className="d-flex flex-column-reverse">
                    <div style={{ width: "95%", marginLeft: "2%" }}>
                      <div className="">
                        <div className="notibox-Header text-white rounded-1 mt-1">
                          <span className="py-2 fw-bolder fs-6 px-4 ">
                            Invite Users
                          </span>
                          <FiX
                            className="fs-4 text-white me-3"
                            role="button"
                            onClick={() => {
                              setAddUserOpen(false);
                              setSearchUsers([]);
                              setSearchToggle(false);
                            }}
                          />
                        </div>
                        <input
                          style={{
                            backgroundColor: "#FFF",
                            margin: "3px 0px",
                            borderBottom: "1px solid #ECECEC",
                          }}
                          type="text"
                          placeholder="Type Here To Invite User"
                          className="form-control ps-3 py-1"
                          onChange={(e) => setText(e.target.value)}
                          onClick={() => {
                            setSearchToggle(true);
                          }}
                        />
                      </div>

                      {SearchToggle === true ? (
                        <div
                          className=""
                          style={{
                            boxShadow: "0px 10px 64px rgba(21, 48, 89, 0.3)",
                          }}
                        >
                          <div className="notifications pt-2">
                            {SearchUsers.length < 0
                              ? ""
                              : SearchUsers.map((noti, index) => {
                                  const intrest = noti.profile.interests[0]
                                    ? noti.profile.interests[0].length > 20
                                      ? noti.profile.interests[0].substring(
                                          0,
                                          20
                                        ) + "..."
                                      : noti.profile.interests[0]
                                    : "";
                                  return (
                                    <div key={index}>
                                      {noti?._id !== userData?._id ? (
                                        <div className="d-flex justify-content-between bg-light">
                                          <div
                                            className="d-flex align-items-center mx-3 py-2 rounded-3 ps-3  mb-1"
                                            style={{ cursor: "pointer" }}
                                          >
                                            <div className="pe-3">
                                              <img
                                                src={
                                                  noti.profile_pic
                                                    ? noti.profile_pic
                                                    : "/img/upload-photo.png"
                                                }
                                                height={40}
                                                className="rounded-circle image-user"
                                                alt="ibouge"
                                              />
                                            </div>
                                            <div className="namees text-dark">
                                              <span className="fw-bold">
                                                {noti.fname} {noti.lname}
                                              </span>
                                              <h6 className="info">
                                                {intrest}
                                              </h6>
                                            </div>
                                          </div>
                                          <button
                                            className="btn my-4 mx-2 text-white"
                                            style={{
                                              backgroundColor: "#fea115",
                                              fontSize: "12px",
                                            }}
                                            onClick={() => InviteApi(noti._id)}
                                          >
                                            Invite
                                          </button>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  );
                                })}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : null}

                {/* MB Messages Div */}
                <div style={{ position: "relative" }}>
                  {loader === true ? (
                    <div style={{ margin: "50% 40%" }}>
                      <Loader />
                    </div>
                  ) : microChat.length === 0 ? <div className="text-center align-items-center fs-6 fw-bold text-dark" 
                    style={{ margin: "40% 0px" }}>No Chat Found</div> : (
                    <>
                      { isReadByOpen.state === 1 ?
                        <div className="rounded-3 px-sm-3 px-1 bg-light mx-1" style={{
                            position: "absolute", bottom: "100px", 
                            boxShadow: "0px 0px 700px 30px rgba(0, 0, 0, 0.2), 0px 0px 50px 10px rgba(0, 0, 0, 0.19)",
                            zIndex: "105", width: "98%"
                        }}>
                          <div className="d-flex justify-content-between bg-white align-content-center border-0 border-bottom border-secondary border-opacity-25 p-3">
                            <span className="fw-bold">Message seen by following user</span>
                            <FiX
                              style={{cursor: "pointer"}}
                              color="black"
                              size={18}
                              onClick={() => {setIsReadByOpen({state: 0})}}
                            />
                          </div>
                          <div className="d-flex flex-column gap-3 px-1 px-sm-3 bg-white my-2" style={{maxHeight:"250px", overflowY: "scroll"}}>
                            {microChat[isReadByOpen.index].seen_by.map(user => { return (
                              <div className="gap-2 d-flex align-items-center">
                                <img src={user.profile} className="rounded-5"
                                  alt=""
                                  style={{
                                    width: "35px",
                                    height: "35px",
                                    marginLeft: "2px",
                                    cursor: "pointer",
                                  }}
                                  // title={user?.name? user.name : "Old User"}
                                  onClick={() =>
                                    navigate("/Profiless", {
                                      state: {
                                        item: user.user_id,
                                      },
                                    })
                                  }
                                />
                                <h6 className="ms-3">{user.name}</h6>
                              </div>
                            )})}
                          </div>
                        </div>
                      : null }
                      {microChat.map((data, index) => {
                      var ranges = data.message.match([
                        "(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])", // U+1F680 to U+1F6FF
                      ]);
                      console.log(data, "i am data");
                      return (
                        <div key={index}>
                          <div className="me-3">
                            {data.from === userData._id ? ( <>
                              <div className="sent p-3">
                                {/* ^ Outgoing Message Bubble */}
                                <div className="order-2 ">
                                  <img
                                    src={
                                      userData?.profile_pic
                                        ? userData?.profile_pic
                                        : "img/upload-photo.png"
                                    }
                                    height={35}
                                    className="rounded-circle image-user"
                                    style={{
                                      cursor: "pointer",
                                      position: "absolute",
                                      right: "10px",
                                    }}
                                    alt="ibouge"
                                    onClick={() => navigate("/myProfile")}
                                  />
                                </div>
                                <div
                                  className="msgBubble px-2 pt-1 rounded-3 order-1"
                                  style={{
                                    backgroundColor: "#6148a1",
                                    boxShadow:
                                      "0px 0px 6px rgba(0, 0, 0, 0.35)",
                                    maxWidth: "85%",
                                  }}
                                >
                                  <p
                                    className="text-white my-2"
                                    style={{
                                      fontFamily: "Roboto",
                                      fontStyle: "normal",
                                      fontWeight: "300",
                                      fontSize: "11px",
                                    }}
                                  >
                                    {data.name}
                                  </p>
                                  {data.fileName ? (
                                    data.message_type === "video" ? (
                                      <video
                                        width="98%"
                                        height="350px"
                                        style={{ borderRadius: "6px" }}
                                        controls
                                      >
                                        <source src={data.fileName} />
                                      </video>
                                    ) : (
                                      <ModalImage
                                      hideDownload	
                                        className="pro-img border rounded-3 w-100 mt-0"
                                        small={data.fileName}
                                        large={data.fileName}
                                        alt={""}
                                      />
                                    )
                                  ) : null}
                                  {data.message.match(/http[s]?:\/\/\S+/) ? (
                                    <>
                                      <Linkify>{data.message}</Linkify>
                                      <LinkPreview
                                        url={data.message}
                                        color={"white"}
                                      />
                                    </>
                                  ) : (
                                    <p
                                      className="mb-1"
                                      style={{
                                        fontFamily: "Roboto",
                                        fontStyle: "normal",
                                        fontWeight: "500",
                                        fontSize:
                                          ranges?.input.length < 7
                                            ? "40px"
                                            : "13px",
                                        color: "white",
                                        wordWrap: "break-word",
                                      }}
                                    >
                                      {data.message}
                                    </p>
                                  )}
                                  <p
                                    className="text-light mb-2"
                                    style={{
                                      fontFamily: "Roboto",
                                      fontStyle: "normal",
                                      fontWeight: "300",
                                      fontSize: "11px",
                                    }}
                                  >
                                    <span
                                      className="pe-1"
                                      style={{ fontSize: "10px" }}
                                    >
                                      {moment(data.time).format(
                                        "MMMM Do, YYYY  h:mm a"
                                      )}
                                    </span>
                                  </p>
                                  <div></div>
                                </div>
                              </div>
                              <div
                                className="d-flex justify-content-end px-3 text-secondary"
                                style={{
                                  marginTop: "-10px",
                                  marginLeft: "15px",
                                }}
                              >
                                { data?.seen_by.length == 0 ? null 
                                : data?.seen_by.length <= 7 ? (
                                  data?.seen_by?.map((user, index) => {
                                    return (
                                      <div className="d-flex flex-column justify-content-between gap-2">
                                        { readByNameOpen.length > 0 ? 
                                          <div className="text-dark mb-3 px-3 position-absolute" style={{bottom:"65px", }} key={index}>
                                              <span>{readByNameOpen}</span>
                                          </div>
                                        : "" }
                                        <img
                                          src={user.profile}
                                          alt=""
                                          className="rounded-5 img-fluid"
                                          style={{
                                            width: "20px",
                                            height: "20px",
                                            marginLeft: "2px",
                                            cursor: "pointer",
                                          }}
                                          // title={user.lname? user.fname + " " + user.lname: user.fname}
                                          onClick={() =>
                                            navigate("/Profiless", {
                                              state: {
                                                item: user.user_id,
                                              },
                                            })
                                          }
                                          onMouseEnter={() => {
                                            setReadByNameOpen({
                                              name: user.lname
                                                ? user.fname +
                                                  " " +
                                                  user.lname
                                                : user.fname,
                                            });
                                          }}
                                          onMouseLeave={() => {
                                            setReadByNameOpen({ name: "" });
                                          }}
                                        />
                                      </div>
                                    );
                                  })
                                ) : (
                                  <div className="d-flex">
                                    {data?.seen_by.slice(0, 4).map((user, index) => {
                                      return (
                                        <div className="d-flex flex-column justify-content-between gap-2">
                                          { readByNameOpen.length > 0 ? 
                                            <div className="text-dark mb-3 px-3 position-absolute" style={{bottom:"65px", }} key={index}>
                                                <span>{readByNameOpen}</span>
                                            </div>
                                          : "" }
                                          <img
                                            src={user.profile}
                                            alt=""
                                            className="rounded-5 img-fluid"
                                            style={{
                                              width: "20px",
                                              height: "20px",
                                              marginLeft: "2px",
                                              cursor: "pointer",
                                            }}
                                            // title={user.lname? user.fname + " " + user.lname: user.fname}
                                            onClick={() =>
                                              navigate("/Profiless", {
                                                state: {
                                                  item: user.user_id,
                                                },
                                              })
                                            }
                                            onMouseEnter={() => {
                                              setReadByNameOpen({
                                                name: user.lname
                                                  ? user.fname +
                                                    " " +
                                                    user.lname
                                                  : user.fname,
                                              });
                                            }}
                                            onMouseLeave={() => {
                                              setReadByNameOpen({ name: "" });
                                            }}
                                          />
                                        </div>
                                      );
                                    })}
                                    <p
                                      className="px-1 text-decoration-underline text-dark"
                                      style={{
                                        fontFamily: "Roboto",
                                        fontStyle: "normal",
                                        fontWeight: "300",
                                        fontSize: "11px",
                                      }}
                                      role="button"
                                      onClick={() => {
                                        setIsReadByOpen({state:1,index: index})
                                      }}
                                    >
                                      + {data?.seen_by.length - 4} others
                                    </p>
                                    
                                  </div>
                                )}
                              </div>
                            </> ) : ( <>
                              <div className="recieved p-3">
                              {/* ^ Incoming Message Bubble */}
                                <div className="order-1">
                                  <img
                                    src={
                                      data?.profile
                                        ? data?.profile
                                        : "img/upload-photo.png"
                                    }
                                    height={35}
                                    className="rounded-circle image-user opacity-100"
                                    style={{ cursor: "pointer" }}
                                    alt="ibouge"
                                    onClick={() =>
                                      navigate("/Profiless", {
                                        state: {
                                          item: data.from,
                                        },
                                      })
                                    }
                                  />
                                </div>
                                <div
                                  className="msgBubble rounded-3 px-2 py-1 ps-4 order-2"
                                  style={{
                                    boxShadow:
                                      "0px 0px 4px rgba(0, 0, 0, 0.35)",
                                    maxWidth: "85%",
                                  }}
                                >
                                  <p
                                    className="text-dark ps-1 my-2"
                                    style={{
                                      fontFamily: "Roboto",
                                      fontStyle: "normal",
                                      fontWeight: "300",
                                      fontSize: "11px",
                                    }}
                                  >
                                    {data.name}
                                  </p>
                                  {data.fileName ? (
                                    data.message_type === "video" ? (
                                      <video
                                        width="98%"
                                        height="350px"
                                        style={{ borderRadius: "6px" }}
                                        controls
                                      >
                                        <source src={data.fileName} />
                                      </video>
                                    ) : (
                                      <ModalImage
                                      hideDownload	
                                        className="pro-img border rounded-3 w-100 mt-0"
                                        small={data.fileName}
                                        large={data.fileName}
                                        alt={""}
                                      />
                                    )
                                  ) : null}
                                  {data.message.match(/http[s]?:\/\/\S+/) ? (
                                    <>
                                      <Linkify>{data.message}</Linkify>
                                      <LinkPreview url={data.message} />
                                    </>
                                  ) : (
                                    <p
                                      className="ps-1 mb-1"
                                      style={{
                                        fontFamily: "Roboto",
                                        fontStyle: "normal",
                                        fontWeight: "500",
                                        fontSize:
                                          ranges?.input.length < 7
                                            ? "50px"
                                            : "13px",
                                        color: "black",
                                        wordWrap: "break-word",
                                      }}
                                    >
                                      {data.message}
                                    </p>
                                  )}
                                  <p
                                    className="text-dark ps-1 mb-1"
                                    style={{
                                      fontFamily: "Roboto",
                                      fontStyle: "normal",
                                      fontWeight: "300",
                                      fontSize: "11px",
                                    }}
                                  >
                                    <span
                                      className="pe-1"
                                      style={{ fontSize: "10px" }}
                                    >
                                      {moment(data.time).format(
                                        "MMMM Do, YYYY  h:mm a"
                                      )}
                                    </span>
                                  </p>
                                </div>
                                </div>
                                <div
                                className="d-flex justify-content-end px-3 text-secondary"
                                style={{
                                  marginTop: "-10px",
                                  marginLeft: "15px",
                                }}
                              >
                                { data?.seen_by.length == 0 ? null 
                                : data?.seen_by.length <= 5 ? (
                                  data?.seen_by?.map((user, index) => {
                                    return (
                                      <div className="d-flex flex-column justify-content-between gap-2">
                                        { readByNameOpen.length > 0 ? 
                                          <div className="text-dark mb-3 px-3 position-absolute" style={{bottom:"65px", }} key={index}>
                                              <span>{readByNameOpen}</span>
                                          </div>
                                        : "" }
                                        <img
                                          src={user.profile}
                                          alt=""
                                          className="rounded-5 img-fluid"
                                          style={{
                                            width: "20px",
                                            height: "20px",
                                            marginLeft: "2px",
                                            cursor: "pointer",
                                          }}
                                          // title={user.lname? user.fname + " " + user.lname: user.fname}
                                          onClick={() =>
                                            navigate("/Profiless", {
                                              state: {
                                                item: user.user_id,
                                              },
                                            })
                                          }
                                          onMouseEnter={() => {
                                            setReadByNameOpen({
                                              name: user.lname
                                                ? user.fname +
                                                  " " +
                                                  user.lname
                                                : user.fname,
                                            });
                                          }}
                                          onMouseLeave={() => {
                                            setReadByNameOpen({ name: "" });
                                          }}
                                        />
                                      </div>
                                    );
                                  })
                                  ) : (
                                  <div className="d-flex">
                                    {data.seen_by.slice(0, 4).map((user, index) => {
                                      return (
                                        <div className="d-flex flex-column justify-content-between gap-2">
                                          { readByNameOpen.length > 0 ? 
                                            <div className="text-dark mb-3 px-3 position-absolute" style={{bottom:"65px", }} key={index}>
                                                <span>{readByNameOpen}</span>
                                            </div>
                                          : "" }
                                          <img
                                            src={user.profile}
                                            alt=""
                                            className="rounded-5 img-fluid"
                                            style={{
                                              width: "20px",
                                              height: "20px",
                                              marginLeft: "2px",
                                              cursor: "pointer",
                                            }}
                                            // title={user.lname? user.fname + " " + user.lname: user.fname}
                                            onClick={() =>
                                              navigate("/Profiless", {
                                                state: {
                                                  item: user.user_id,
                                                },
                                              })
                                            }
                                            onMouseEnter={() => {
                                              setReadByNameOpen({
                                                name: user.lname
                                                  ? user.fname +
                                                    " " +
                                                    user.lname
                                                  : user.fname,
                                              });
                                            }}
                                            onMouseLeave={() => {
                                              setReadByNameOpen({ name: "" });
                                            }}
                                          />
                                        </div>
                                      );
                                    })}
                                    <p
                                      className="px-1 text-decoration-underline text-dark"
                                      style={{
                                        fontFamily: "Roboto",
                                        fontStyle: "normal",
                                        fontWeight: "300",
                                        fontSize: "11px",
                                      }}
                                      role="button"
                                      onClick={() => {
                                        setIsReadByOpen({state: 1, index: index})
                                      }}
                                    >
                                      + {data?.seen_by.length - 4} others
                                    </p>
                                  </div>
                                )}
                              </div>
                            </> )}
                          </div>
                        </div>
                      );
                      })
                      .reverse()}
                    </>
                  )}

                  {TypingIcon === true ? (
                    <div className="chat-bubble">
                      <div className="typing">
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                      </div>
                    </div>
                  ) : (
                    <div className="chat-bubble" style={{ margin: "-4.1px" }}>
                      <div className="typing"></div>
                    </div>
                  )}

                  {IsUpload === true ? (
                    <div>
                      {TypeFile === "video" ? (
                        <div
                          className="position-relative"
                          style={{ width: "93%" }}
                        >
                          <video
                            ref={videoRef}
                            style={{ marginLeft: "13px", borderRadius: "5px" }}
                            width="100%"
                            height="200px"
                            controls
                          >
                            <source src={image} />
                          </video>
                          <FiX
                            className=""
                            color="black"
                            style={{
                              position: "absolute",
                              top: "-2px",
                              right: "-18px",
                              zIndex: "100",
                              backgroundColor: "#ECECEC",
                              borderRadius: "10px",
                              padding: "3px",
                              cursor: "pointer",
                            }}
                            size={22}
                            onClick={() => {
                              setIsUpload(false);
                              setVideoSrc(null);
                              setFiles(null);
                            }}
                          />
                        </div>
                      ) : (
                        <div
                          className="position-relative"
                          style={{ width: "95%" }}
                        >
                          <ModalImage
                          hideDownload	
                            className="chatImage"
                            small={image}
                            large={image}
                            alt={"Global Newsfeed"}
                          />
                          <FiX
                            className="position-absolute"
                            color="black"
                            style={{
                              right: "-10px",
                              top: "-3px",
                              zIndex: "1",
                              backgroundColor: "#ECECEC",
                              borderRadius: "10px",
                              padding: "3px",
                              cursor: "pointer",
                            }}
                            size={22}
                            onClick={() => {
                              setIsUpload(false);
                              setImage("");
                              setFiles(null);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              {/* {readByNameOpen.name.length > 0 ? 
              <div className="text-dark mb-3 d-flex align-items-end justify-content-end px-3 position-absolute w-100" 
              style={{
                bottom:"65px",
                marginLeft: readByNameOpen.index + "0px" }} >
                  <div className="d-flex justify-content-between bg-white">
                    <span>{readByNameOpen.name}</span>
                  </div>
              </div>
              : "" } */}

              <div className="mba-body mb-2 me-3 ">
                <div className="form-category">
                  <div id="test" className="d-flex position-relative">
                    <BsPaperclip
                      style={{
                        zIndex: "10",
                        padding: "4px",
                        position: "absolute",
                        top: "7px",
                        left: "15px",
                        backgroundColor: "#6148A1",
                        borderRadius: "4px",
                        cursor: "pointer",
                      }}
                      color="white"
                      className=""
                      size={27}
                      onClick={onButtonClick}
                    />
                    <input
                      type="file"
                      id="file"
                      ref={inputFile}
                      // accept="'png/*,jpg,video/mp4,video/x-m4v,video/*"
                      onChange={handleImageChange}
                      style={{ display: "none" }}
                    />
                    <InputEmoji
                      value={mind}
                      onChange={(e) => {
                        setMind(e);
                      }}
                      onEnter={() => Add()}
                      placeholder="What's on your mind?"
                      borderRadius={5}
                    />

                    <button
                      className=""
                      style={{
                        width: "40px",
                        height: "40px",
                        backgroundColor: "#6148A1",
                        border: "none",
                        borderRadius: "4px",
                      }}
                      type="button"
                      onClick={() => Add()}
                    >
                      <MdSend color="white" size={19} className="mb-send" />
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="text-center pt-3 bg-white position-relative">
                {MicroData[0]?.cover_pic ? (
                  <ModalImage
                  hideDownload	
                    className="img-fluid rounded-2 CoverImgMicro"
                    small={MicroData[0]?.cover_pic}
                    large={MicroData[0]?.cover_pic}
                    alt={MicroData[0].name}
                  />
                ) : (
                  <img
                    className="img-fluid "
                    src={"/img/mb-banner.png"}
                    alt="Icon"
                  />
                )}
                <BsArrowLeftShort
                  color="White"
                  size={30}
                  style={{
                    position: "absolute",
                    left: "20px",
                    top: "15px",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowData(false)}
                />
                <div className="mb-profile d-flex">
                  <div>
                    <ModalImage
                    hideDownload	
                      className=" microblogProfilePic rounded-circle"
                      style={{ borderColor: "#6148a1" }}
                      small={MicroData[0].microblog_img}
                      large={MicroData[0].microblog_img}
                      alt={MicroData[0].name}
                    />
                  </div>
                </div>
              </div>

              <MicroMap MicroData={MicroData} />
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default MicroblogActivities;