import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";

function FootNav() {
  return (
    <>
      <div className="">
        <Navbar className="foot-nav" expand="sm">
          <Container fluid>
            <Navbar.Toggle aria-controls="basic-navbar-nav"  onClick={() =>   window.scrollTo(0,document.body.scrollHeight)}/>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="foot-nav ms-2 gap-3">
                <Nav.Link className="" eventKey="1"
                  as={Link}
                  to="/about.html">
                  About
                </Nav.Link>
                <Nav.Link className="" eventKey="2"
                  as={Link}
                  to="/contact.html">
                  Contact
                </Nav.Link>
                <Nav.Link className="" eventKey="3"
                  as={Link}
                  to="/mission.html">
                  Mission
                </Nav.Link>
                <Nav.Link className="" eventKey="4"
                  as={Link}
                  to="/Demo">
                  Demo
                </Nav.Link>
                <Nav.Link className="" eventKey="5"
                  as={Link}
                  to="/press">
                  Press
                </Nav.Link>
                <Nav.Link className="" eventKey="6"
                  as={Link}
                  to="/blog.html">
                  Blog
                </Nav.Link>
                <Nav.Link className="" eventKey="7"
                  as={Link}
                  to="/privacy">
                  Privacy
                </Nav.Link>
                <Nav.Link className="" eventKey="8"
                  as={Link}
                  to="/terms">
                  Terms of Service
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </>
  );
}

export default FootNav;
