import React, { useEffect, useRef, useState } from "react";
import { IoMdSend } from "react-icons/io";
import {
  BsChatLeftTextFill,
  BsHeartFill,
  BsPaperclip,
  BsThreeDotsVertical,
} from "react-icons/bs";
import { AiOutlineHeart } from "react-icons/ai";
import InputEmoji from "react-input-emoji";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ModalImage from "react-modal-image";
import { Dropdown, Modal } from "react-bootstrap";
import { Server2, Socket } from "../../../redux/actions/auth/auth.actions";
import { useSelector } from "react-redux";
import { ChatBox } from "../../home/NavbarNew/ChatBox";
import { FiX } from "react-icons/fi";
import { Loader } from "../../../components/Loader";
import moment from "moment/moment";
import Linkify from 'linkify-react';
import LinkPreview from "../../../components/LinkPreview";
import { thumbnailGen } from "../../../redux/actions/auth/auth.actions";
import ReactPlayer from 'react-player';

function Aside({ chatBox, setChatBox, item, UserFriend, UserFollower }) {
  const userData = useSelector((state) => state?.auth?.user?.user);
  const navigate = useNavigate();
  const [cityname, setCityName] = useState("");
  const citynew = cityname?cityname.split(","):null
  const cityname2 = citynew?citynew[0]:null
  const [text, setText] = useState("");
  const [date, setdate] = useState("");
  const [files, setFiles] = useState(null);
  const [image, setImage] = useState("");
  const [TypeFile, setTypeFile] = useState("");
  const [IsUpload, setIsUpload] = useState(false);
  const [videoSrc, setVideoSrc] = useState(null);
  const [LikesPopup, setLikesPopup] = useState({ index: 0, type: false });
  const [getStatus, setGetStatus] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [comment, setComment] = useState({text:"",index:0,statusId:null});
  const [BtnDisable, setBtnDisable] = useState(false);
  const inputFile = useRef(null);
  const [typinglist, SetTypingList] = useState([]);
  const [reportPopup, setReportPopup] = useState(false);
  const [reportData, setReportData] = useState({id: "", type: "", statusBy: ""});
  const [thumbnail, setThumbnail] = useState("");
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [mediaUrl, setMediaUrl] = useState("");
  useEffect(() => {
    const handler = (chat) => {
      Get();
    };
    Socket.on("newStatus", handler);
    Socket.on("com-typing", (data) => {
      if (data.statustyping === true) {
        SetTypingList((prev) => [...prev, data]);
        isTyping(data.statusId)
      } else if (data.statustyping == false) {
        var lists = typinglist.filter((x) => {
          return x.from !== data.from && x.index !== data.index;
        });
        SetTypingList(lists);
      }
    });
    return () => {
      Socket.off("newStatus", handler);
    };
  }, [Socket]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    const prev = comment.text;
    if (comment.text.length > 0) {
      const data2 = {
        from: userData?._id,
        statustyping: true,
        index: comment.index,
        statusId:comment.statusId,
        name:userData.type?userData?.fname:userData?.fname +" "+ userData?.lname
      };
      Socket.emit("comment-typing", data2);
      const timer = setTimeout(() => {
        if (comment.text === prev) {
          const data2 = {
            from: userData?._id,
            statustyping: false,
            index: comment.index,
            statusId:comment.statusId,
            name:userData.type?userData?.fname:userData?.fname +" "+ userData?.lname
          };
          Socket.emit("comment-typing", data2);
        }
      }, 1000);

      return () => clearTimeout(timer);
    } else {
      const data2 = {
        from: userData?._id,
        statustyping: false,
        index: comment.index,
        statusId:comment.statusId,
        name:userData.type?userData?.fname:userData?.fname +" "+ userData?.lname
      };
      Socket.emit("comment-typing", data2);
    }
    // isTyping(comment.statusId)
  }, [comment]);
  const isTyping = (postId) => {
    return typinglist.some(item => item.statusId === postId && item.statustyping==true );
  };
  useEffect(() => {
    if(getStatus.length===0){
      setLoading(false)
    }
    let d = new Date();
    let newDate = d.toString();
    setdate(newDate);
    // get City Name
    var config = {
      method: "GET",
      url: `https://api.mapbox.com/geocoding/v5/mapbox.places/${userData?.location?.coordinates[0]?.coords.lng},${userData.location.coordinates[0]?.coords.lat}.json?access_token=` + process.env.REACT_APP_MAPBOX_KEY,
    };
    axios(config).then((response)=>{
      setCityName(response.data.features[2].place_name);
    })
    .catch(function (error) {
      console.log(error);
    });
    Get();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps
  const onButtonClick = () => {
    inputFile.current.click();
  };
  const videoRef = useRef();
  useEffect(() => {
    
    videoRef.current?.load();
    
    if (TypeFile === "video" && image) {
      thumbnailGen(files).then((thumb) => {
       setThumbnail(thumb);
     })
    }

    if(image){
      mediaUpload();
    }
    
  }, [videoSrc, image])

  // media upload before posting
  const mediaUpload = () => {
    if (TypeFile !== "") {

      if (TypeFile === "video" && image) {
        thumbnailGen(files).then((thumb) => {
         setThumbnail(thumb)
       })
      }

      let data = new FormData();
      data.append("from", userData._id);
      data.append("thumbnail",thumbnail?thumbnail:null)
      data.append("file", files !== null ? files : null);
      data.append("filename", "");

      var config = {
        method: "post",
        url: Server2 + "/status/status-media",
        data: data,
      };

      axios(config).then(function (response) {
        setThumbnailUrl(response.data.thumbnail);
        setMediaUrl(response.data.media);
      });

    } else {
      // alert("Please select a file first");
    }
  };

  const clearUpload = () => {
    const url = mediaUrl
    setIsUpload(false);
    setVideoSrc(null);
    setImage("");
    setFiles(null);
    setMediaUrl("");
    setTypeFile("");
    setThumbnailUrl("");

    var config = {
      method: "post",
      url: Server2 + "/status/delete-from-bucket",
      data: {fileName: url},
    };

    axios(config)
    .then((response) => {
      // deteted
    })
    .catch((error) => {
      console.log("er", error);
    });

  }

  const StatusUpdate = () => {
    if (text !== "" || (files !== null && mediaUrl !== "")) {
      // if (TypeFile === "video") {
      //   setLoader(true);
      // }
      let data = new FormData();
      data.append("from", item._id);
      data.append("userid", userData._id);
      data.append(
        "profile_pic",
        userData.profile_pic ? userData.profile_pic : ""
      );
      data.append("thumbnail",thumbnailUrl ? thumbnailUrl : "")
      data.append("fname", userData.fname);
      data.append("lname", userData.type === false ? userData.lname : "");
      data.append("status_type", files !== null ? TypeFile : "text");
      data.append("message", text);
      data.append("time", date);
      data.append("size", "");
      data.append("filename", mediaUrl ? mediaUrl : "");
      data.append("platform", "web");
      data.append("caption", text);
      data.append("likes", "");
      data.append("location_id", cityname);
      var config = {
        method: "post",
        url: Server2 + "/status/new-status",
        data: data,
      };
      axios(config)
        .then(function (response) {
          setGetStatus((current) => [response.data.status[0], ...current]);
          setLoader(false);
          setIsUpload(false)
          inputFile.current.value = null;
          setTypeFile("");
          setMediaUrl("");
          setThumbnailUrl("");
          setText("");
          const data = {
            from:item._id,
            userid:userData._id,
            statusId:response.data.status[0]._id,
            city:cityname2
          }
          Socket.emit("add-new-status" , data);    
        })
        .catch(function (error) {
          console.log(error);
          setLoader(false);
        });
    } else if(TypeFile !== "" && mediaUrl === "") {
      // alert("Please wait for media to upload");
    } else {
      alert("Please Enter Text or Select Image");
    }
  };

  const handleImageChange = (e) => {
    if(e.target.files[0].size > 52428800){
      alert("File size is too big");
      return
    }
    inputFile.current.click();
    setFiles(e.target.files[0]);
    const type = e.target.files[0].type.split("/");
    setTypeFile(type[0]);

    if (e.target.files && e.target.files[0]) {
      if (TypeFile === "video") {
        setImage("");
        const file = e.target.files[0];
        if (!file) return;
        setImage(URL.createObjectURL(file));
        setIsUpload(true);
        // handlePost()
      } else {
        setImage("");
        let reader = new FileReader();
        reader.onload = function (e) {
          setImage(e.target.result);
          setIsUpload(true);
        };
        reader.readAsDataURL(e.target.files[0]);
      }
    }
  };
  const handlePost = () => {
    // setIsUpload(false);
    // setVideoSrc(null)
    StatusUpdate();
    // setText("");
    // setFiles(null);
  };
  function handleOnEnter() {
    // setIsUpload(false);
    StatusUpdate();
    // setText("");
    // setFiles(null);
  }
  const DeletePost = (e) => {
    var data = JSON.stringify({
      id: e,
      from: userData._id,
      disable:true
    });
    var config = {
      method: "POST",
      url: Server2 + "/status/delete-status",
      data: data,
      headers: { "Content-Type": "application/json" },
    };
    axios(config)
      .then((response) => {
        Get();
      })
      .catch((error) => {
        console.log("er", error);
      });
  };
  const handleThreeDots = (e) => {
    DeletePost(e);
  };
  const reportPost = () => {
    var data = JSON.stringify({
      from: userData._id,
      statusId: reportData.id,
      statusBy: reportData.statusBy,
      type: reportData.type,
    });
    var config = {
      method: "POST",
      url: Server2 + "/status/report",
      data: data,
      headers: { "Content-Type": "application/json" },
    };
    axios(config)
      .then((response) => {
        setReportPopup(false)
        setReportData({id: "", type: "", statusBy: ""})
        // setGetStatus((current) => [response.data.status[0], ...current]);
      })
      .catch((error) => {
        console.log("er", error);
      });
  };
  const handleReport = (id, statusby) => {
    // reportPost(e);
    setReportPopup(true)
    setReportData({id: id, type: "Nudity", statusBy: statusby})
  };
  const Get = () => {
    // setLoading(true);
    var config = {
      method: "get",
      url: Server2 + `/status/getStatusById?id=${item._id}`,
      headers: {},
    };

    axios(config)
      .then(function (response) {
        setLoading(true);
        setGetStatus(response.data[0].status);
        console.log(response.data[0].status);
      })
      .catch(function (error) {
        console.log(error, "error");
      });
  };
  const getlikestatus = (likes, ststus) => {
    let status = false;
    likes.map((item) => {
      if (item.from === userData?._id ) {
        status = true;
      }
      return("");
    });
    return status;
  };
  const Likes = (e) => {
    setBtnDisable(true);
    let data = JSON.stringify({
      status: e.status._id,
      from: userData._id,
      type: e.type,
      statuslike: false,
    });

    var config = {
      method: "post",
      url: Server2 + "/status//new-like",
      headers: { "Content-Type": "application/json" },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Get();
        setBtnDisable(false);
        if(e.type === true){
          let data = {
            me: userData._id,
            createdBy:e.status.from,
            reply:false,
            userId:e.status.userid,
            status:e.status._id,
            city:cityname2
        }
        Socket.emit('add-status-like', data)
        const data2 = {
          to: item?._id,
        };
        Socket.emit("new-notification", data2);
          }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const CommentLike = (e) => {
    var data = JSON.stringify({
      from: userData._id,
      status: e.status,
      type: e.type,
      commentId:e.CommentId,
    });
    console.log("Working", data)
    var config = {
      method: "post",
      url: Server2 + "/status/new-comment-like",
      headers: { "Content-Type": "application/json" },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Get();
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const CommentUpdate = (e) => {
    if (comment.text !== "") {
      let data = JSON.stringify({
        status: e._id,
        userId :e.userid,
        from: userData._id,
        replyType: "Text",
        message: comment.text,
        fname: userData.fname,
        lname: userData.type === false ? userData.lname : "",
        profile_pic: userData.profile_pic,
      });

      var config = {
        method: "post",
        url: Server2 + "/status/new-comment",
        headers: { "Content-Type": "application/json" },
        data: data,
      };

      axios(config)
        .then(function (response) {
          if (response.data) {
            setComment({text:"",index:0,statusId:null});
            Get();
            let data = {
              me: userData._id,
              createdBy:e.from,
              reply:false,
              status:e._id,
              userId:e.userid,
              city:cityname2
          }
          Socket.emit('add-status-comment', data)
          const data2 = {
            to: item?._id,
          };
          Socket.emit("new-notification", data2);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      alert("please add some text in comments");
    }
  };
  const DeleteComment = (e) => {
    let data = JSON.stringify({
      status_id: e.status,
      reply_id: e.Comment,
    });

    var config = {
      method: "post",
      url: Server2 + "/status/delete-comment",
      headers: { "Content-Type": "application/json" },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Get();
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const Data = [
    {
      _id: item?._id,
      item: {
        _id: item?._id,
        fname: item?.fname,
        is_online: item?.is_online,
        lname: item?.lname,
        location: item?.location,
        profile: item?.profile,
        profile_pic: item?.profile_pic,
      },
    },
  ];
  const reportTypes = [
    "Child Exploitation",
    "Drugs, Weapons, or Regulated Goods",
    "False Information",
    "Fraud or Scam",
    "Hate Organizations",
    "Hate Speech",
    "Harassment",
    "Intellectual Property",
    "Nudity",
    "Privacy Violations",
    "Spam",
    "Violence",
    "Others"
  ]
  return (
    <>
      { reportPopup ?
      <Modal
        show={reportPopup}
        onHide={() => setReportPopup(false)}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title style={{color:"black", fontSize:"20px"}}>Flag Objectionable Content</Modal.Title>
        </Modal.Header>
        <Modal.Body>
         Select report type
          <center>
            <select 
              className="form-select mt-2" aria-label="Default select example"
              onChange={(e) => setReportData({id: reportData.id, type: e.target.value, statusBy: reportData.statusBy})}
            >
              {
                reportTypes.map((data) =>{
                  return(
                    <option value={data}>{data}</option>
                  )
                })
              }
            </select>
            <button type="button" className="btn mx-auto my-4" style={{backgroundColor:"#6148a1", color:"white", width:"100%"}} onClick={() => reportPost()}>submit</button>
          </center>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={reportPost()}>
            Submit
          </Button>
        </Modal.Footer> */}
      </Modal>
      : "" }
      <div className="border rounded-3 shadow px-3 mb-4 ">
        <div className="mt-2">
          <p className="fw-bold">{`${item?.fname}'s wall`}</p>
        </div>
        {UserFriend === "Friend" || UserFollower === "Follower" ? (
          <div id="status" className="d-flex position-relative mb-2">
            <BsPaperclip
              className="clip-icon p-1 rounded-1 "
              size={28}
              onClick={onButtonClick}
            />
            <input
              type="file"
              id="file"
              ref={inputFile}
              // accept="'png/*,jpg,video/mp4,video/x-m4v,video/*"
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
            
            <InputEmoji
              value={text}
              onChange={(e) => setText(e)}
              onEnter={handleOnEnter}
          
              placeholder= {`Write on ${item?.fname}'s wall`}
              borderRadius={5}
            />
            <button
              className="Post-btn-Aside"
              type="button"
              data-bs-toggle="button"
              onClick={handlePost}
            >
              Post
            </button>
          </div>
        ) :null}
        <div
          className="allpost-user mb-4"
          style={{ height: item?.type === true ? UserFollower === "Follower"? "1018px":"1070px": UserFriend === "Friend"? "755px" :"805px" }}
        >
        { item?.privacy?.new_messages === true && UserFollower === "" && UserFriend === "" ? (
        
          <div className="d-flex justify-content-center">
            <p className="fs-5 my-5">This wall is private</p>
          </div>
        
        ) : <>
          
          {IsUpload === true ? (
            <div>
              { TypeFile !== "" && mediaUrl === "" ? ( 
                <div className="d-flex justify-content-center">
                  <Loader/>
                </div>
              ) : TypeFile === "video" && mediaUrl !== "" ? (
                <div className="position-relative" style={{ width: "90%" }}>
                  <video
                    ref={videoRef}
                    style={{ marginLeft: "20px", borderRadius: "5px" }}
                    width="100%"
                    height="280px"
                    controls
                  >
                    <source src={image} />
                  </video>
                  <FiX
                    className=""
                    color="black"
                    style={{
                      position: "absolute",
                      top: "-2px",
                      right: "-10px",
                      zIndex: "100",
                      backgroundColor: "#ECECEC",
                      borderRadius: "10px",
                      padding: "3px",
                      cursor: "pointer",
                    }}
                    size={22}
                    onClick={() => {clearUpload()}}
                  />
                </div>
              ) : TypeFile !== "" && TypeFile !== "video" && mediaUrl !== "" ? (
                <div className="position-relative" style={{ width: "95%" }}>
                  <ModalImage
                  hideDownload	
                    className="statusImage"
                    small={image}
                    large={image}
                    alt={"Uploaded File"}
                  />
                  <FiX
                    className="position-absolute"
                    color="black"
                    style={{
                      right: "0px",
                      top: "-3px",
                      zIndex: "1",
                      backgroundColor: "#ECECEC",
                      borderRadius: "10px",
                      padding: "3px",
                      cursor: "pointer",
                    }}
                    size={22}
                    onClick={() => {clearUpload()}}
                  />
                </div>
              ) : "" }
            </div>
          ) : null }

          {loader === true ? (
            <div style={{ margin: "0 40%" }}>
              <Loader />
            </div>
          ) :null} 
          {Loading === false ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "5%",
              }}
            >
              <div className="loading2"></div>
            </div>
          ) : getStatus.length > 0 ? (
            getStatus.map((items, index) => {
              
              /* console.log(items,"items") */
              
              const string=items?.message.match(/[a-zA-Z0-9.,/'";:!@$%&*()_+=-±~?#€[{}^><|/]+/)
              var ranges = items?.message.match([
                "(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])", // U+1F680 to U+1F6FF
              ]);
              /* console.log(items) */
              // let date = new Date(items.time).toString().split(" ");
              return (
                <div
                  className="border rounded-2 jdp  me-3 py-3 mt-2"
                  key={index}
                >
                  <div className="d-flex  d-flex justify-content-between ">
                    <div
                      className="user-bprofile d-flex justify-content-start align-items-center"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (items.userid === userData?._id) {
                          navigate("/myProfile");
                        } else {
                          navigate("/Profiless", {
                            state: {
                              item: items.userid,
                            },
                          });
                        }
                      }}
                    >
                      <img
                        className="img-profile"
                        src={
                          items.profile_pic
                            ? items.profile_pic
                            : "/img/upload-photo.png"
                        }
                        alt="Admin"
                      />
                      <div className="lh-1">
                        <span className="fw-bold lh-1 title">
                          {items.fname}&nbsp; {items.lname}
                        </span>
                        <br />
                        <span className="lh-1 date">
                          {/* {date[0]} {date[1]} {date[2]} {date[3]} */}
                          {moment(items.time).format("MMMM Do, YYYY  h:mm A")}
                        </span>
                      </div>
                    </div>
                      <Dropdown>
                        <Dropdown.Toggle
                          className="border-0 bg-transparent togglebtn d-flex"
                          variant=""
                          id="dropdown-basic"
                          aria-expanded="false"
                          >
                          <BsThreeDotsVertical className="mt-3 me-3" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu">
                          {items.userid === userData?._id ? (
                            <Dropdown.Item
                              onClick={() => handleThreeDots(items._id)}
                            >
                              Delete Post
                            </Dropdown.Item>
                          ) : null}
                          {items.userid !== userData?._id ? (
                            <Dropdown.Item
                              onClick={() => handleReport(items._id, items.userid)}
                            >
                              Report Post
                            </Dropdown.Item>
                          ) : null }
                        </Dropdown.Menu>
                      </Dropdown>
                  </div>

                  <div className="ms-3 ps-5 me-3 ">
                    
                  {items.message.match(/http[s]?:\/\/\S+/)? 
                    <div style={{wordWrap: "break-word",textWrap:"wrap",inlineSize: "100%"}}>
                      <Linkify>{items.message}</Linkify>
                      <LinkPreview url={items.message} />
                    </div>
                    :  <p
                    className="desc"
                    style={{
                      wordWrap: "break-word",
                      fontSize: string ? "13px" : ranges?.input.length < 7 ? "60px" : "13px",
                    }}
                  >
                    {items.message}
                  </p> }
                    {items.filename ? (
                      items.status_type === "video" ? (
                        // <video
                        //   width="98%"
                        //   height="350px"
                        //   style={{ borderRadius: "6px" }}
                        //   controls
                        // >
                        //   <source src={items.filename} />
                        // </video>
                        <div className="d-flex justify-content-center">
                          <ReactPlayer url={items.filename} controls={true} style={{borderRadius:"6px" , maxWidth:"100%" , maxHeight:"350px"}} />
                        </div>
                      ) : (
                        <ModalImage
                        hideDownload	
                          className="pro-img border rounded-3 w-100 mt-0"
                          small={items.filename}
                          large={items.filename}
                          alt={"Status Updates"}
                        />
                      )
                    ) : null}
                    {/* <img
                          className="pro-img border rounded-3 "
                          src={items.filename}
                          alt="Admin"
                          style={{ width: "100%", marginTop: "0px" }}
                        /> */}
                    <div className="desc-icons p-1 d-flex justify-content-end align-items-center position-relative" onMouseLeave={() => {
                            setLikesPopup({
                              index: 0,
                              type: false
                            })
                          }}>
                      {items.replies.length}
                      <BsChatLeftTextFill className="icons-chat m-2" />
                      {<div style={{cursor:"pointer"}} onMouseEnter={() => {
                          setLikesPopup({
                            index: index,
                            type: true
                          })
                        }}
                         >{items.likes.length}</div>}
                      {/* {getlikestatus(items.likes, items.status) ? ( */}
                      {items.likes.length > 0 ? (
                        <div>
                        <button
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                          }}
                          disabled={BtnDisable === true ? true : false}
                          onClick={() => {
                            Likes({
                              status: items,
                              type: false,
                            });
                          }}
                        >
                          <BsHeartFill
                            // key={index}
                            size={14}
                            className="icons-heart m-2"
                            color="#D84076"
                          />
                        </button>
                        {
                            LikesPopup.type === true && LikesPopup.index === index ?
                              <div style={{
                                position: "absolute",
                                top: "30px",
                                right: "1px",
                                backgroundColor: "#fff",
                                width: "100px",
                                height: "fit-content",
                                maxHeight: "150px",
                                borderRadius: "4px",
                                overflow: "auto",
                                zIndex: 100,
                                padding: "5px 5px",
                                textAlign: "center"
                              }}>
                                {
                                  items.likes.map((e, index) => {
                                    return (
                                      <>
                                        <span style={{ color: "#000000", lineHeight: 0.7, marginbottom: "0px" }}>{e.fname} {e.lname}</span><br />
                                      </>

                                    )
                                  })
                                }
                              </div>
                              :
                              null
                          }
                        </div>
                      ) : (
                        <button
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                          }}
                          disabled={BtnDisable === true ? true : false}
                          onClick={() => {
                            Likes({
                              status: items,
                              type: true,
                            });
                            // setLike(true);
                          }}
                        >
                          <AiOutlineHeart
                            color="#D84076"
                            className="icons-heart m-2"
                            size={16}
                          />
                        </button>
                      )}
                    </div>
                    {items.replies.length > 0 ? (
                      <div className="border"></div>
                    ) : (
                      ""
                    )}
                    {items.replies.map((Com) => {
                      
                      const string=Com?.message.match(/[a-zA-Z0-9.,/'";:!@$%&*()_+=-±~?#€[{}^><|/]+/)
                      var ranges = Com?.message.match([
                        "(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])", // U+1F680 to U+1F6FF
                      ]);
                      return (
                        <div key={Com.time}>
                          <div className="d-flex justify-content-between ">
                            <div
                              className="user-bprofile d-flex justify-content-start align-items-center"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                if (Com.from === userData?._id) {
                                  navigate("/myProfile");
                                } else {
                                  navigate("/Profiless", {
                                    state: {
                                      item: Com.from,
                                    },
                                  });
                                }
                              }}
                            >
                              <img
                                className="img-profile"
                                src={
                                  Com.profile_pic
                                    ? Com.profile_pic
                                    : "/img/upload-photo.png"
                                }
                                alt="Admin"
                              />
                              <div className="lh-1">
                                <span className="fw-bold lh-1 title">
                                  {Com.fname}&nbsp; {Com.lname}
                                </span>
                              </div>
                            </div>
                            {userData?._id === Com.from ? (
                              <Dropdown>
                                <Dropdown.Toggle
                                  className="border-0 bg-transparent togglebtn d-flex"
                                  variant=""
                                  id="dropdown-basic"
                                  aria-expanded="false"
                                >
                                  <BsThreeDotsVertical className="mt-3 me-3" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-menu">
                                  <Dropdown.Item
                                    onClick={() =>
                                      DeleteComment({
                                        status: items._id,
                                        Comment: Com._id,
                                      })
                                    }
                                  >
                                    Delete Comment
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="d-flex justify-content-between">
                          <p
                            className="text-start ms-5 ps-3"
                            style={{
                              fontSize: string?"13px": ranges?.input.length < 8 ? "60px" : "13px",
                              wordWrap: "break-word",
                              textWrap:"wrap",
                              inlineSize: "60%"
                            }}
                          >
                            {Com.message}
                          </p>
                          <div className="desc-icons p-1 d-flex justify-content-end align-items-center">
                      {Com.likes.length}
                      {Com.likes.length > 0 ? (
                        <button
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                          }}
                          onClick={() => {
                            CommentLike({
                              userId:items.userid,
                              status: items._id,
                              CommentId: Com._id,
                              type:false
                            });
                          }}
                        >
                          <BsHeartFill
                            // key={index}
                            size={14}
                            className="icons-heart m-2"
                            color="#D84076"
                          />
                        </button>
                      ) : (
                        <button
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                          }}
                          onClick={() => {
                            CommentLike({
                              userId:items.userid,
                              status: items._id,
                              CommentId: Com._id,
                              type:true
                            });
                            // setLike(true);
                          }}
                        >
                          <AiOutlineHeart
                            color="#D84076"
                            className="icons-heart m-2"
                            size={16}
                          />
                        </button>
                      )}
                    </div>
                        </div>
                        </div>
                      );
                    })}
                        {isTyping(items._id) ? (
                  <div className="typing">
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                  </div>
              ) : (
                  <div className="typing"></div>
              )}
                    <div
                      id="comment"
                      className="d-flex align-items-center position-relative"
                    >
                      <InputEmoji
                        value={index === comment.index ? comment.text : ""}
                        onChange={(e) => setComment({text:e, index:index, statusId:items._id})}
                        placeholder={"Write a comment"}
                        width="100%"
                        onEnter={() => CommentUpdate(items)}
                        cleanOnEnter
                        borderRadius={5}
                      />
                      <IoMdSend
                        className="send-btn"
                        size={39}
                        onClick={() => CommentUpdate(items)}
                      />
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className=" bg-light text-center h-25 m-auto d-flex flex-column justify-content-center fw-bolder fs-4 text-dark">
              No Status Updates Here
            </div>
          )}

        </> }
        </div>
      </div>
      <div
        className=""
        style={{
          position: "fixed",
          right: "50px",
          bottom: "55px",
          width: "400px",
          zIndex: "1000",
        }}
      >
        {chatBox && (
          <ChatBox setChatBox={() => setChatBox((e) => !e)} Data={Data} />
        )}
      </div>
      {/* ^ All Posts Div */}
    </>
  );
}
export default Aside;