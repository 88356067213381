import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Server2 } from "../../../redux/actions/auth/auth.actions";

function Friends({userData}) {
  let params = useParams();
  let newId = params?.id.split("=")
  const [UserFriends, setUserFriends] = useState([]);
  useEffect(() => {
    var config = {
      method: "get",
      url: Server2 + `/users/Friends?id=${newId[1]}`,
      headers: { "Content-Type": "application/json" },
    };
    axios(config)
      .then(function (response)  {
        
        if (response.data) {
          setUserFriends(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      <div className=" friends border rounded-3 shadow" style={{marginTop:"25px" , paddingRight:"10px"}}>
        <div className="border1 "></div>
        <div className="d-flex justify-content-between">
          <p className="friends mt-2 ms-3" >
          {userData?.fname}'s Connections ({UserFriends.length})
          </p>
        </div>
        <div className="friends_row ps-3" style={{height:"fit-content" , marginBottom:"0px" , paddingBottom:"0px"}}>
          {UserFriends.map((friends, index) => {
            return (
              <div
                className=" col-xl-1 col-lg-2 col-md-2 col-sm-2 col-3 px-1 mx-1 text-center"
                style={{ cursor: "pointer" }}
                key={index}
              >
                <img
                  className="img rounded-5 "
                  src={
                    friends.profile_pic
                      ? friends.profile_pic
                      : "/img/upload-photo.png"
                  }
                  style={{ width: "40px", height: "40px" }}
                  alt="Admin"
                />
                <p className="jacob">
                  {friends.fname} <br/>
                  {friends.lname}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Friends;
