import React from "react";
import { Card, Button } from "reactstrap";
import Signup from "./signup";
import "../../../assets/styles/signup.css";
import Footer from "../../../components/Footer";
import {
  googleSignUpAuth,
  facebookSignUpAuth,
} from "../../../redux/actions/auth/auth.actions";
import {
  signInWithapple,
  signInWithfacebook,
  signInWithGoogle,
} from "../../../services/firebase";
import { useDispatch } from "react-redux";
import { useState } from "react";

const CardComponetSignup = () => {
  const dispatch = useDispatch();
  const [resMsg, setresMsg] = useState("");
  // Setting all Responce
  // Google Social Func
  function googleSignup() {
    setresMsg("");
    signInWithGoogle().then(({ user }) => {
      let name = user?._delegate?.displayName;
      const UserName = name.split(" ");
      const firstname = UserName[0];
      const lastname = UserName[1];
      if (user) {
        dispatch(
          googleSignUpAuth({
            email: user?._delegate?.email,
            fname: firstname,
            lname: lastname,
          })
        ).then((res) => {
          if (res?.user?.message) {
            setresMsg(res?.user?.message);
          }
        });
      }
    });
  }
  // Facebook Social Func
  function facebookSignup() {
    setresMsg("")
    signInWithfacebook().then(({ user }) => {
      let DisplayName = user?._delegate?.displayName;
      const FBName = DisplayName.split(" ");
      const FirstName = FBName[0];
      const LastName = FBName[1];
      if (user) {
        dispatch(
          facebookSignUpAuth({
            email: user?._delegate?.email,
            fname: FirstName,
            lname: LastName,
          })
        ).then((res) => {
          if (res?.user?.message) {
            setresMsg(res?.user?.message);
          }
        });
      }
    });
  }
  const appleSignup = () =>{
    signInWithapple().then(({user})=>{

    })
  }

  return (
    <>
      <div className="text-center img-logo">
        <img src="/img/Logo.png" alt="" className="FormLogo-Signup" />
        <span style={{color: "#9f47a0", position: "relative", top: "15px", fontSize: "1.2rem"}}>&#174;</span>
      </div>
    <div className="d-flex justify-content-center px-3">
      <Card className="card-customize-signup">
        <div className="border_top"></div>
        <div className="m-3 d-flex justify-content-center">
          <span className="LoginHeading">Sign up in a few simple steps</span>
        </div>
        <>
          {/* Setting all Responce Messages */}
          {resMsg !== "" ? (
            <div
            className={`${resMsg.includes("18")?'text-danger':"text-success"}`}
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                margin: "2px 28px 10px 28px",
                textAlign: "center",
              }}
            >
              {resMsg}
            </div>
          ) : (
            ""
          )}

          {/* <div className="d-flex justify-content-around flex-row mt-2 ps-2 pe-2">
            <Button className="social-btn" onClick={facebookSignup}>
              <img src="/img/facebook.png" height={24} alt="facebook" />
              <span className="m-1">Facebook</span>
            </Button>

            <Button className="social-btn" onClick={googleSignup}>
              <div>
                <img src="/img/google.png" height={20} alt="Google" />
              </div>
              <span className="m-1">Google</span>
            </Button>
            <Button className="social-btn" onClick={() => appleSignup()}>
              <div>
                <img src="/img/Apple.png" height={30} alt="Apple" />
              </div>
              <span className="m-1">Apple</span>
            </Button>
          </div>

          <div className="text-center mt-3 ">
            <p className="mute-text">Or</p>
          </div> */}
          <div>
            <Signup setresMsg={(e) => setresMsg(e)} />
          </div>
        </>
      </Card>
    </div>
      <Footer />
      <div className="mt-3"></div>
    </>
  );
};

export default CardComponetSignup;