import React from 'react'
import { useNavigate } from 'react-router-dom'
import '../../../assets/styles/Footer/footerAbout.css'
import { Helmet } from "react-helmet";

const About = () => {
  const navigate = useNavigate()
  return (
    <>
      <Helmet>
        <meta property="og:url" content="about.html" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="About" />
        <meta property="og:description" content="iBouge, a geo-network. We use maps to connect the global community" />
      </Helmet>
    <div id="wrapper" className='bg-light'>
    <div className="pre-content "></div>
    <div className="content ">
      <header className='aboutheader'>
        <img src="/Images/logo.png" alt="Ibouge Logo" style={{cursor:"pointer"}} onClick={() => navigate('/')}  />
        <span style={{color: "#9f47a0", position: "relative", top: "-2px", fontSize: "1.1rem"}}>&#174;</span>
      </header>
      <main className='aboutmain'>
        <section className='aboutsection' id="what">
          <div className="about-what-left">
            <div className="about-what-left-bg"></div>
            <img src="/Images/ipad.png" alt="" />
          </div>
          <div className="what-right topper">

            <span>Introducing iBouge</span>
            <h1 className='about-h1' >What is iBouge<small style={{fontSize:"14px", position:"relative", top: "-10px"}} >&#174;</small> ?</h1>
            <p>iBouge is a mapping platform that places people, products and information on a global map interface to become more accessible and easier to find by the global internet population, allowing them to interact in new ways.</p>
            <p>Currently we offer 4 products on our map interface: </p>
            <ul>
              <li><strong>1</strong> Meeting people nearby</li>
              <li><strong>2</strong> Global business pages</li>
              <li><strong>3</strong> Map based chat conversations about points of interest</li>
              <li><strong>4</strong> Local events that are pinned on our map and broadcast to anyone who is following that area.</li>
            </ul>
          </div>
        </section>

        <section className='aboutsection' id="diff">
          <div className="diff-content topper">
            <h1 className='about-h1'><span>What differentiates <br/>
            the platform </span>from <br/>
            other social media <br/>
            companies?</h1>

            <p>Our patented and unique global map interface <br/>
            categorizes content in a new way and allows <br/>
            people to be found and content to be seen<br/>
            on a global scale. iBouge &#174; users can<br/>
            connect with anyone in the world and <br/>
            businesses can reach any audience<br/>
            around the globe no matter where<br/>
            they’re located. </p>

            <img src="/Images/world.png" alt="" />
          </div>
        </section>

        <section className='aboutsection' id="who">
          <div className="who-image">
            <img src="/Images/cards.png" alt="" />
          </div>
          <div className="who-content">
            <h1 className='about-h1'>Who should sign up for<br />
            an account and why?<br/>
            <span>We offer two types<br/>
            of accounts.</span></h1>

            <img src="/Images/cards.png" alt="" />

            <p style={{color:"black"}}>Individual personal accounts which are free and paid business accounts.</p>
          </div>
          <div className="who-content">
            <ul>
              <li>Our global business pages are tailored to small businesses looking for broad marketing exposure and instant international visibility</li>
              <li>Our business pages list your company info just like Google Maps with map findability but also with the added benefit of group chat, direct messaging, social media features and your own personal wall for company branding and updates.</li>
              <li>Our global business pages are currently $25 USD offered as an annual recurring membership.</li>
            </ul>
          </div>
        </section>

        <section className='aboutsection' id="sign-up">
          <div className="sign-up-content">
            <div className="suc-title">
              <h1 className='about-h1'>Ok, I’m sold, <br/>
              <span>how do I sign up?</span></h1>
              <p>You can sign up as a free user or paid business user on our registration sign up page and select which type of account you’d like in our account Type dropdown</p>
            </div>
            <div className="sup-process">
              <div className="step step1">
                <img src="/Images/app1.png" alt="" />
                <div className="desc">
                  <span className='px-1 px-sm-3 py-0 py-sm-1 py-md-2'>Step 1</span>
                  <p>Fill out the company details, add the company’s website and a company profile photo.</p>
                </div>
              </div>
              <div className="step step2">
                <img src="/Images/app2.png" alt="" />
                <div className="desc">
                  <span className='px-1 px-sm-3 py-0 py-sm-1 py-md-2'>Step 2</span>
                  <p>Enable your browser to share its location with iBouge &#174; so we can tag your business where it’s located.</p>
                </div>
              </div>
              <div className="step step3">
                <img src="/Images/app3.png" alt="" />
                <div className="desc">
                  <span className='px-1 px-sm-3 py-0 py-sm-1 py-md-2'>Step 3</span>
                  <p>Select your top 3 favorite cities to follow for updates to your newsfeed. You can change these cities at any time to explore new places.</p>
                </div>
              </div>
              <div className="step step4">
                <img src="/Images/app4.png" alt="" />
                <div className="desc">
                  <span className='px-1 px-sm-3 py-0 py-sm-1 py-md-2'>Step 4</span>
                  <p>Fill out your credit card details to begin your new membership using our secure transaction partner Stripe.</p>
                </div>
              </div>
              <div className="step step5">
                <img src="/Images/app5.png" alt="" />
                <div className="desc done">
                  <span className='px-1 px-sm-3 py-0 py-sm-1 py-md-2'>Done</span>
                  <p>Your business page will automatically activate and then log into the platform.</p>
                </div>
              </div>

              <div className="form">
                <h2>Sign Up Now</h2>
                <p>and start enjoying the benefits of using our platform</p>
                <span onClick={() => navigate('/signup')}>Sign Up</span>
              </div>  
            </div>
          </div>
        </section>
      </main>
    </div>
  </div>
         <footer>
          <p>iBouge ® • Copyright © 2024 iBouge Inc. • All Rights Reserved.</p>
          <p>Patent numbers US D921,652; US D921,653; US D921,032; US D921,006; US D921,005; US D921,004.</p>
        </footer>
  </>
  )
}

export default About