import React from 'react'
import './Pp.css';

const Pp = () => {
    return (
        <div style={{background:"white", maxWidth: 850}}>
          <div className="MainContact bg-white">
            <>
      <h5  style={{ paddingTop: "40px", textIndent: "0pt", textAlign: "center" }}>
        iBouge Privacy Policy
      </h5>
      <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "center" }}>
        January 25th, 2024
      </p>
      <p style={{ paddingTop: "2pt", textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
        Welcome to the iBouge Platform. The Platform is owned and operated by
        iBouge, Inc., (“iBouge”).
      </p>
      <p style={{ paddingTop: "2pt", textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p
        className="s1"
        style={{
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "109%",
          textAlign: "left"
        }}
      >
        At <span style={{ color: "#000" }}>iBouge</span>, your privacy is of the
        utmost importance to us. To provide our users with the highest levels of
        data protection, as well as assurance that we will not misuse your
        information, <span style={{ color: "#000" }}>iBouge </span>is compliant with
        the General Data Protection Regulation (GDPR) as stated below.
      </p>
      <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p
        style={{
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "109%",
          textAlign: "left"
        }}
      >
        This Privacy Policy governs the use of the iBouge Platform. When using or
        accessing the iBouge Platform, you are agreeing to be bound to the terms
        within this Privacy Policy and the Terms of Use policy. This policy may
        refer to users as “you” or “user” and may refer to iBouge as “us,” “we,” or
        “our.” If you do not agree to the terms within this Privacy Policy and the
        corresponding Terms of Use, do not access or use the iBouge Platform.
      </p>
      <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p
        style={{
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "109%",
          textAlign: "justify"
        }}
      >
        Please read this Privacy Policy carefully to understand our policies and
        practices regarding your personal information and how we will treat it.{" "}
        <u>If you do not agree with the terms of this Privacy</u>{" "}
        <u>Policy, please do not use our Platform and services</u>. Please also read
        our Terms of Service.
      </p>
      <p
        style={{
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "109%",
          textAlign: "justify"
        }}
      >
        Capitalized terms not otherwise defined herein have the meaning assigned to
        them in the Terms of Service.
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <h5  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
        INFORMATION COLLECTED AND STORED BY IBOUGE
      </h5>
      <p style={{ paddingTop: "2pt", textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p
        style={{
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "109%",
          textAlign: "justify"
        }}
      >
        In order to carry out the services of iBouge and to provide a great user
        experience, we will collect and store the following information about you:
      </p>
      <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p
        className="s3"
        style={{
          paddingLeft: "5pt",
          textIndent: "36pt",
          lineHeight: "109%",
          textAlign: "left"
        }}
      >
        Personal Information, that may be used to identify you as an individual{" "}
        <span className="p">
          (Personal information is any information related to an identified or
          identifiable natural person).
        </span>
      </p>
      <ul id="l1">
        <li data-list-text="-">
          <p
            style={{
              paddingLeft: "12pt",
              textIndent: "-6pt",
              lineHeight: "14pt",
              textAlign: "left"
            }}
          >
            First and Last Name;
          </p>
        </li>
        <li data-list-text="-">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "11pt",
              textIndent: "-6pt",
              textAlign: "left"
            }}
          >
            Address
          </p>
        </li>
        <li data-list-text="-">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "12pt",
              textIndent: "-6pt",
              textAlign: "left"
            }}
          >
            Location
          </p>
        </li>
        <li data-list-text="-">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "12pt",
              textIndent: "-6pt",
              textAlign: "left"
            }}
          >
            Email Address
          </p>
        </li>
        <li data-list-text="-">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "12pt",
              textIndent: "-6pt",
              textAlign: "left"
            }}
          >
            Phone Number
          </p>
        </li>
        <li data-list-text="-">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "12pt",
              textIndent: "-6pt",
              textAlign: "left"
            }}
          >
            IP Address
          </p>
        </li>
        <li data-list-text="-">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "11pt",
              textIndent: "-6pt",
              textAlign: "left"
            }}
          >
            Age
          </p>
        </li>
        <li data-list-text="-">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "12pt",
              textIndent: "-6pt",
              textAlign: "left"
            }}
          >
            Gender
          </p>
        </li>
        <li data-list-text="-">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "12pt",
              textIndent: "-6pt",
              textAlign: "left"
            }}
          >
            Business Information (Businesses Only)
          </p>
          <p style={{ paddingTop: "2pt", textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p
            className="s3"
            style={{ paddingLeft: "41pt", textIndent: "0pt", textAlign: "left" }}
          >
            Other information that may be linked to personal information
            <span className="p">.</span>
          </p>
        </li>
        <li data-list-text="-">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "12pt",
              textIndent: "-6pt",
              textAlign: "left"
            }}
          >
            Content you post publicly or privately on the Platform
          </p>
        </li>
        <li data-list-text="-">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "12pt",
              textIndent: "-6pt",
              textAlign: "left"
            }}
          >
            Searches Completed through the Platform;
          </p>
        </li>
        <li data-list-text="-">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "12pt",
              textIndent: "-6pt",
              textAlign: "left"
            }}
          >
            Preference Settings;
          </p>
        </li>
        <li data-list-text="-">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              lineHeight: "109%",
              textAlign: "left"
            }}
          >
            Credit Card or Other Payment Information (this is all handled through
            our payment-processor, Stripe)
          </p>
        </li>
        <li data-list-text="-">
          <p
            style={{
              paddingLeft: "12pt",
              textIndent: "-6pt",
              lineHeight: "14pt",
              textAlign: "left"
            }}
          >
            Stored Information or Files Created or Stored on Platform Through Your
            Account
          </p>
        </li>
        <li data-list-text="-">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "12pt",
              textIndent: "-6pt",
              textAlign: "left"
            }}
          >
            Content you Post to the Platform;
          </p>
        </li>
        <li data-list-text="-">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "11pt",
              textIndent: "-6pt",
              textAlign: "left"
            }}
          >
            Any Emails You Send to iBouge
          </p>
        </li>
        <li data-list-text="-">
          <p
            style={{
              paddingTop: "3pt",
              paddingLeft: "12pt",
              textIndent: "-6pt",
              textAlign: "left"
            }}
          >
            Times of Access to Platform;
          </p>
        </li>
        <li data-list-text="-">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "12pt",
              textIndent: "-6pt",
              textAlign: "left"
            }}
          >
            Platform that Referred to iBouge;
          </p>
        </li>
        <li data-list-text="-">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "12pt",
              textIndent: "-6pt",
              textAlign: "left"
            }}
          >
            Your Operating System Type
          </p>
        </li>
      </ul>
      <p style={{ paddingTop: "2pt", textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p
        style={{
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "109%",
          textAlign: "left"
        }}
      >
        <a href="http://www.youtube.com/t/terms)" className="a"  >
          This policy does not apply to the privacy practices of third parties that
          we do not own or control, including but not limited to any third-party
          websites, services, applications, online resources to which the Platform
          may link or otherwise reference (collectively “Third Party Services” or
          “TPS”) that you may access through the Platform. For example, the Platform
          may utilize the YouTube API as a TPS, and you are agreeing to be bound by
          the YouTube Terms of Service (https://{" "}
        </a>
        <a
          href="http://www.google.com/policies/privacy)"
          className="a"
           
        >
          www.youtube.com/t/terms), Privacy Policy ({" "}
        </a>
        http://www.google.com/policies/privacy), and API Services Terms of Service
        (https://developers.google.com/youtube/terms/api-services-terms-of- service)
        when engaging YouTube content and services through the Platform. Our use of
        information received from Gmail APIs will adhere to Google’s Limited Use
        Requirements (https://
        developers.google.com/terms/api-services-user-data-policy). We take no
        responsibility for the content or privacy practices of any TPS. We encourage
        you to carefully review the privacy policies of any TPS you access.
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p
        style={{
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "109%",
          textAlign: "left"
        }}
      >
        You may have the option to register an Account with us using a TPS, and you
        may connect your Account to a TPS such as Instagram, Facebook, Twitter or
        YouTube. By authorizing us to access your TPS account, you authorize us to
        obtain certain information from your TPS account, which may including your
        name, email address, birthday, work history, education history, current
        city, shared media, and the names, profile pictures, relationship status,
        and current cities of your TPS contacts. We only obtain information from
        your TPS account that you specifically authorize and grant us permission to
        obtain. Our use of information received via Google Restricted Scopes, such
        as from Gmail APIs, will adhere to Google’s Limited Use Requirements
        (https:// developers.google.com/terms/api-services-user-data-policy).
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p
        style={{
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "109%",
          textAlign: "left"
        }}
      >
        iBouge does not consider personally identifiable information to include
        information that has been anonymized so that it does not allow a third party
        to identify a specific individual or household. We collect and use your
        personally-identifiable information to: provide the Platform; operate and
        improve our Platform; provide customer service; perform research and
        analysis aimed at improving our products, Platform and technology; and
        display content that is customized to your interests and preferences.
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p
        style={{
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "109%",
          textAlign: "left"
        }}
      >
        You may always choose not to provide personally identifiable information,
        but if you so choose, certain parts of the Platform may not be available to
        you. If you have registered an Account with us, you will have agreed to
        provide your personally identifiable information in order to access the
        Platform. This consent provides us with the legal basis we require under
        applicable law to process your data. You maintain the right to withdraw such
        consent at any time. If you do not agree to our use of your personal data in
        line with this Policy, please do not use the Platform.
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <h5  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
        HOW WE COLLECT YOUR PERSONAL INFORMATION<span className="p">.</span>
      </h5>
      <p style={{ paddingTop: "2pt", textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p
        style={{
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "109%",
          textAlign: "left"
        }}
      >
        iBouge collects personal information from you upon your registration for our
        services and upon your log in. We collect any additional information about
        you when you interact with us electronically, when you access our Platform
        and when we provide our services to you.
      </p>
      <p
        style={{
          paddingTop: "3pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "109%",
          textAlign: "left"
        }}
      >
        It may not be readily apparent to you when such data is being collected. For
        instance, when you visit our Platform, your IP address is collected so that
        we know where to send information you are requesting. An IP address is a
        number that is used by computers on the network to identify your computer
        every time you log on to the Internet.
      </p>
      <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <h5  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
        COOKIES, LOCAL STORAGE AND TRACKING TECHNOLOGIES.
      </h5>
      <p
        style={{
          paddingTop: "1pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "109%",
          textAlign: "justify"
        }}
      >
        We collect information about you using "cookies." Cookies are small data
        files stored on your hard drive by a Platform. The cookies that we use are
        for authentication purposes (to facilitate your log- in) and session cookies
        (to recognize your preferences while navigating through the Platform).
      </p>
      <p
        style={{
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "109%",
          textAlign: "justify"
        }}
      >
        Usually the information that we collect automatically through cookies is
        statistical data and cannot be used to identify you as an individual. It
        helps us to improve our Platform and to deliver a better and more
        personalized service.
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p
        style={{
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "109%",
          textAlign: "left"
        }}
      >
        Additionally, there might be third party cookies used on our Platform by
        third party advertisers, those cookies are not related to your profile, they
        cannot identify who you are and therefore are not linked to your personal
        data.
      </p>
      <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p
        style={{
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "109%",
          textAlign: "left"
        }}
      >
        When you first visit our site, we ask you whether you wish us to use
        cookies. If you choose not to accept them, we shall not use them for your
        visit except to record that you have not consented to their use for any
        other purpose. If you choose not to use cookies or you prevent their use
        through your browser settings, you will not be able to use all the
        functionality of our site.
      </p>
      <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p
        style={{
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "109%",
          textAlign: "justify"
        }}
      >
        We also use local storage, which is an industry-standard technology which
        allows a website or application to store information locally on your
        computer or mobile device which allows us to customize your experience and
        the content you may see.
      </p>
      <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <h5  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
        WHY WE COLLECT YOUR PERSONAL INFORMATION.
      </h5>
      <p
        style={{
          paddingTop: "1pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "109%",
          textAlign: "left"
        }}
      >
        We process your personal information because we have a contractual
        obligation with you. Once you create an account to use our Platform and
        Services, and agree to our Terms of Service, a contract is formed between
        you and us. In order to carry out our contractual obligations we need to
        process the information you provide, which also includes personal
        information. Even if there is no direct contractual obligation between us,
        we may process your information based on your explicit consent you have
        provided (to us or a third-party data controller) for the processing of that
        information.
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <h5  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
        HOW WE USE YOUR PERSONAL INFORMATION<span className="p">.</span>
      </h5>
      <p
        style={{
          paddingTop: "1pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "left"
        }}
      >
        We will use the personal information collected from you:
      </p>
      <ul id="l2">
        <li data-list-text="–">
          <p
            style={{ paddingLeft: "41pt", textIndent: "-36pt", textAlign: "left" }}
          >
            to administer your account with us and provide you with our Services;
          </p>
        </li>
        <li data-list-text="–">
          <p
            style={{ paddingLeft: "41pt", textIndent: "-36pt", textAlign: "left" }}
          >
            to respond to your inquiries and deliver notifications and reminder
            e-mails to you;
          </p>
        </li>
        <li data-list-text="–">
          <p
            style={{
              paddingLeft: "41pt",
              textIndent: "-36pt",
              lineHeight: "109%",
              textAlign: "left"
            }}
          >
            to provide you with information regarding updates and changes to our
            Platform, our Terms of Service or any services we offer or provide
            through it;
          </p>
        </li>
        <li data-list-text="–">
          <p
            style={{
              paddingLeft: "41pt",
              textIndent: "-36pt",
              lineHeight: "14pt",
              textAlign: "left"
            }}
          >
            to improve our product and services;
          </p>
        </li>
        <li data-list-text="–">
          <p
            style={{ paddingLeft: "41pt", textIndent: "-36pt", textAlign: "left" }}
          >
            to contact you regarding your order;
          </p>
        </li>
        <li data-list-text="–">
          <p
            style={{ paddingLeft: "41pt", textIndent: "-36pt", textAlign: "left" }}
          >
            To provide you advertising within the Platform; and
          </p>
        </li>
        <li data-list-text="–">
          <p
            style={{ paddingLeft: "41pt", textIndent: "-36pt", textAlign: "left" }}
          >
            to provide personalized experience for you upon use of our services.
          </p>
        </li>
      </ul>
      <p style={{ paddingTop: "2pt", textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p
        style={{
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "109%",
          textAlign: "left"
        }}
      >
        Additionally, we may use anonymized information we have collected from you
        to enable us to display advertisements to our advertisers’ target audiences.
        Even though we do not disclose your
      </p>
      <p
        style={{
          paddingTop: "3pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "109%",
          textAlign: "left"
        }}
      >
        personal information for these purposes without your consent, if you click
        on or otherwise interact with an advertisement, the advertiser may assume
        that you meet its target criteria.
      </p>
      <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <h5  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
        DISCLOSURE OF PERSONAL INFORMATION<span className="p">.</span>
      </h5>
      <p
        style={{
          paddingTop: "1pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "109%",
          textAlign: "left"
        }}
      >
        We will not disclose your personal information to any third parties and we
        do not share, sell, rent, or trade your personal information with third
        parties for their commercial purposes, however, we reserve the right to
        disclose information about you to third parties to third-parties for
        assistance in improving the iBouge products and services. Additionally, we
        may disclose personal and non- personally identifiable information with
        third parties service providers, engaged by us to provide technical support,
        hosting services, testing, network security or statistical services, any
        such disclosure will be subject to confidentiality obligations.
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p
        style={{
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "109%",
          textAlign: "left"
        }}
      >
        iBouge may also disclose information about you to third-parties where we
        have a good faith belief that such disclosure is necessary in order to: (i)
        protect, enforce, or defend the legal rights, privacy, safety, or property
        of iBouge, our affiliates or their employees, agents and contractors
        (including enforcement of our agreements and our terms of use); (ii) protect
        the safety, privacy, and security of users of the iBouge employees, owners,
        or members of the public; (iii) protect against fraud or for risk management
        purposes; (iv) comply with the law or legal process; or (v) respond to
        requests from public and government authorities.
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p
        style={{
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "109%",
          textAlign: "left"
        }}
      >
        iBouge may use third-party payment processors including PayPal, Stripe,
        Apple Pay and Square for payment transactions. Please visit their Platforms
        for their privacy and terms of use policies as all payment transactions are
        governed by them.
      </p>
      <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <h5  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
        HOW LONG WE KEEP YOUR INFORMATION<span className="p">.</span>
      </h5>
      <p
        style={{
          paddingTop: "1pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "109%",
          textAlign: "left"
        }}
      >
        We will keep your personal information for as long as you use our Platform
        and services. You have the right to request that your personal information
        is permanently removed from our system and database at any time. We will
        accommodate such requests within 45 (forty-five) days or less.
      </p>
      <p
        style={{
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "14pt",
          textAlign: "left"
        }}
      >
        <a href="mailto:info@ibouge.com" className="a"  >
          You can e-mail such requests for permanent removal to{" "}
        </a>
        <a href="mailto:info@ibouge.com"  >
          info@ibouge.com.
        </a>
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <h5  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
        RIGHT TO ACCESS AND CONTROL YOUR DATA
      </h5>
      <p
        style={{
          paddingTop: "1pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "109%",
          textAlign: "left"
        }}
      >
        We provide you with many choices about the collection, use and sharing of
        your data, from deleting or correcting data you include in your profile and
        controlling the visibility of your posts and communication controls. We will
        provide you with access to your personal information that we store and allow
        you to:
      </p>
      <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <ol id="l3">
        <li data-list-text="1)">
          <p
            style={{
              paddingLeft: "5pt",
              textIndent: "0pt",
              lineHeight: "109%",
              textAlign: "left"
            }}
          >
            Delete your data - you can request that we erase or delete all or some
            of your personal data (e.g., if it is no longer necessary to provide
            Services to you);
          </p>
          <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
        </li>
        <li data-list-text="2)">
          <p
            style={{
              paddingLeft: "5pt",
              textIndent: "0pt",
              lineHeight: "109%",
              textAlign: "left"
            }}
          >
            Change or correct your data - you have the option to edit some of your
            personal data through your account. You can also ask us to change,
            update or fix your data in certain cases, particularly if it’s
            inaccurate;
          </p>
          <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
        </li>
        <li data-list-text="3)">
          <p
            style={{
              paddingLeft: "5pt",
              textIndent: "0pt",
              lineHeight: "109%",
              textAlign: "left"
            }}
          >
            Object to, or limit or restrict, use of data - you can ask us to stop
            using all or some of your personal data (e.g., if we have no legal right
            to keep using it) or to limit our use of it (e.g., if your personal data
            is inaccurate or unlawfully held);
          </p>
        </li>
        <li data-list-text="4)">
          <p
            style={{
              paddingTop: "3pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              lineHeight: "109%",
              textAlign: "left"
            }}
          >
            Right to access and/or take your data - you can ask us for a copy of
            your personal data and can ask for a copy of personal data you provided
            in machine readable form.
          </p>
          <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
        </li>
        <li data-list-text="5)">
          <p
            style={{
              paddingLeft: "5pt",
              textIndent: "0pt",
              lineHeight: "109%",
              textAlign: "left"
            }}
          >
            We provide our California consumers with detailed information about the
            categories of personal information you have collected in the past 12
            months.
          </p>
        </li>
      </ol>
      <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
        <a href="mailto:info@ibouge.com" className="a"  >
          You may send us an e-mail at{" "}
        </a>
        info@ibouge.com to request any or all of the above.
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
        SECURITY OF YOUR PERSONAL INFORMATION.
      </p>
      <p
        style={{
          paddingTop: "1pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "109%",
          textAlign: "justify"
        }}
      >
        iBouge is committed to ensuring that the information you provide to us is
        secure from accidental loss and from unauthorized access, use, alteration
        and disclosure. We have implemented suitable physical, electronic and
        managerial procedures to safeguard and secure information and protect it
        from misuse, interference, loss and unauthorized access, modification and
        disclosure.
      </p>
      <ul id="l4">
        <li data-list-text="–">
          <p
            style={{
              paddingLeft: "41pt",
              textIndent: "-36pt",
              lineHeight: "109%",
              textAlign: "justify"
            }}
          >
            All information you provide to us is stored, if at all, on a secure
            database behind a Virtual Private Network;
          </p>
        </li>
        <li data-list-text="–">
          <p
            style={{
              paddingLeft: "41pt",
              textIndent: "-36pt",
              lineHeight: "14pt",
              textAlign: "justify"
            }}
          >
            All passwords are stored in an encrypted form;
          </p>
        </li>
        <li data-list-text="–">
          <p
            style={{
              paddingLeft: "41pt",
              textIndent: "-36pt",
              lineHeight: "109%",
              textAlign: "left"
            }}
          >
            We restrict access to personal information to employees, contractors and
            agents who need to know that information in order to operate, develop or
            improve our services. These individuals are bound by confidentiality
            obligations and may be subject to discipline, including termination, if
            they fail to meet these obligations;
          </p>
        </li>
        <li data-list-text="–">
          <p
            style={{
              paddingLeft: "41pt",
              textIndent: "-36pt",
              lineHeight: "109%",
              textAlign: "left"
            }}
          >
            We back up all client data in multiple data centers enabling speedy
            recovery in case of a disaster;
          </p>
        </li>
        <li data-list-text="–">
          <p
            style={{
              paddingLeft: "44pt",
              textIndent: "-38pt",
              lineHeight: "14pt",
              textAlign: "left"
            }}
          >
            Whenever possible we will make sure we pseudonymize all personal or
            related data.
          </p>
        </li>
      </ul>
      <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p
        style={{
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "109%",
          textAlign: "left"
        }}
      >
        The safety and security of your information also depends on you. Where we
        have given you a password for access to certain parts of our Platform, you
        are responsible for keeping this password confidential. We ask you not to
        share your password with anyone. We urge you to be careful about giving out
        information in public areas of the Platform like message boards. The
        information you share in public areas may be viewed by any user of the
        Platform.
      </p>
      <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
        INTERNATIONAL USAGE
      </p>
      <p
        style={{
          paddingTop: "1pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "109%",
          textAlign: "left"
        }}
      >
        The Platform is owned by iBouge and may be accessed in the United States and
        abroad. For data protection purposes, iBouge is the controller and, unless
        otherwise noted, is also the processor of data. Information collected may be
        retained, and may be stored, processed, accessed, and used in jurisdictions
        whose privacy laws may be different and less protective than those of your
        home jurisdiction. If you are located outside of the United States, please
        note that the information you provide to us may be transferred to the United
        States. By using the Platform, application and/or website, you consent to
        such transfer. We will take reasonable steps to ensure that your data is
        treated securely and in accordance with this Policy.
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <h5  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
        COMMERICAL AND NON-COMMERICAL COMMUNICATION
      </h5>
      <p
        style={{
          paddingTop: "1pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "109%",
          textAlign: "left"
        }}
      >
        By providing information to the Platform, such as signing up for an account,
        you have agreed to receive e-mail communication from us. However, you may
        unsubscribe from certain communications by notifying iBouge that you no
        longer wish to receive solicitations or commercial information. iBouge will
        remove you from the database, where you have the right to request this under
        the Privacy Policy, Terms of Use, or applicable law, or where iBouge
        voluntarily decides to grant the request.
      </p>
      <h5 
        style={{
          paddingTop: "3pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "left"
        }}
      >
        CHILDREN’S ONLINE PRIVACY PROTECTION ACT
      </h5>
      <p
        style={{
          paddingTop: "1pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "109%",
          textAlign: "left"
        }}
      >
        <a href="mailto:info@ibouge.com" className="a"  >
          The iBouge Platform is directed to adults, over the age of 18. iBouge does
          not knowingly collect information from children under 13 years of age or
          have any reasonable grounds for believing that persons under the age of 13
          are accessing the Platform. If iBouge discovers any inadvertently
          collected Personal Information of a person under the age of 13, that
          information will be swiftly deleted from its’ records. If you believe that
          iBouge may have any information from a person under age 13, please contact
          iBouge at:{" "}
        </a>
        <a href="mailto:info@ibouge.com"  >
          info@ibouge.com.
        </a>
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <h5  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
        YOUR CALIFORNIA PRIVACY RIGHTS
      </h5>
      <p
        style={{
          paddingTop: "1pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "109%",
          textAlign: "left"
        }}
      >
        iBouge welcomes residents of the State of California to use its Platform,
        and complies with the California Business and Professions Code §§
        22575-22579. If you are a California resident you may request certain
        information regarding our disclosure of personal information to any third
        parties for their direct marketing purposes. Please send a written request
        to iBouge Inc 95 Third Street 2nd Floor, San Francisco 94103.
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <h5  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
        CHANGES TO THIS PRIVACY POLICY
      </h5>
      <p
        style={{
          paddingTop: "1pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "109%",
          textAlign: "left"
        }}
      >
        We reserve the right to make changes to this privacy policy at any time. If
        we change our privacy policy, we will notify you via email sent to the email
        address you provide at the time of registration as well as post an updated
        version of this Privacy Policy on our Platform so that you and other users
        are always aware of what information we collect, how we use it, and under
        what circumstances, if any, we disclose it. Changes will not apply
        retroactively and changes addressing new functions for a service or changes
        made for legal reasons will be effective immediately. If you do not agree to
        the modified terms, you should discontinue your use of the Platform. Your
        continued use of the after any such changes constitutes your acceptance of
        the new Privacy Policy. You should also check back from time to time to
        ensure you are aware of any changes. Under California Consumer Privacy Act
        we will be reviewing this Privacy Policy every 12 months.
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <h5  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
        CONTACT INFORMATION
      </h5>
      <p
        style={{
          paddingTop: "1pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "109%",
          textAlign: "left"
        }}
      >
        <a href="mailto:info@ibouge.com" className="a"  >
          If you have any questions, comments, complaints, or concerns regarding
          this Privacy Policy, you can contact us anytime at the following address:
          iBouge Inc, 95 Third Street 2nd Floor, San Francisco 94103 and/or{" "}
        </a>
        info@ibouge.com. We take complaints very seriously and will respond shortly
        after receiving written notice of your complaint.
      </p>
    </>
    
          </div>
            {/* <center>
            <button
              style={{
                width: "315px",
                height: "48px",
                backgroundColor: "#6148A1",
                borderRadius: "8px",
                border: "none",
                color: "white",
                position: "relative",
                bottom: "50px",
              }}
              onClick={() => navigate('/contact')}
            >
              Contact Us
            </button>
            </center> */}
        </div>
      );
}

export default Pp