import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Protected = (props) => {
    const userData = useSelector((state) => state?.auth?.user?.user);
    console.log(userData,'this is user data')
  const navigate = useNavigate()
  const {Component} = props
  useEffect(() =>{
    const prams = window.location.pathname
    if(userData === undefined){
        navigate('/login')
    }else if((userData?.type === false && userData?.activation_status === 3) || (userData?.type === true && userData?.subscription!==undefined) ){
        if(prams==="/dashboard"){
          navigate('/dashboard')
        }else if(prams==="/mapOverview"){
          navigate('/mapOverview')
        }else if(prams==="/myProfile"){
          navigate('/myProfile')
        }else if(prams==="/admin"){
          navigate('/admin')
        }else if(prams==="/setting"){
          navigate("/setting", {
            state: {
              item: "1",
            },
          });
        }else if(userData?.activation_status === 3 && (userData?.agreedToTAC === undefined || userData?.agreedToTAC === false)){
          navigate('/thirdStep')
        }else if(userData?.type === true && userData?.subscription===undefined){
          navigate('/fourthStep')
        }else if(userData?.is_deactivated){
          navigate("/setting", {
            state: {
              item: "1",
            },
          })
        }
        else{
          navigate("/home", {
                state: {
                  item: [
                    userData.location.coordinates[0].coords.lng,
                    userData.location.coordinates[0].coords.lat,
                  ],
                },
              });
        }
    }
  },[]) // eslint-disable-line react-hooks/exhaustive-deps
  
  return (
    <div>
      <Component />
    </div>
  )
}

export default Protected
