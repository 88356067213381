import React, { useState, useEffect, useRef } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/src/css/mapbox-gl.css";
import { FiX } from "react-icons/fi";
import { IoMdCamera } from "react-icons/io";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import axios from "axios";
import { useSelector } from "react-redux";
import { Server2 , Socket } from "../../../redux/actions/auth/auth.actions";

const AddMicroblog = (props) => {
  const [SearchCoord , setSearchCoord] = useState(null);
  console.log(SearchCoord,"searchCoords")
  const [cityName , setCityName] = useState("");
  const [error, setError] = useState("");
  const User = useSelector((state) => state?.auth?.user?.user)
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [zoom] = useState(14);
  mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_KEY;
  useEffect(() => {
    if (map.current) return; //stops map from intializing more than once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: `https://api.maptiler.com/maps/positron/style.json?key=RGierAHokphISswP6JTB`,
      center: [User.location.coordinates[0].coords.lng,
               User.location.coordinates[0].coords.lat],
      zoom: zoom,
    });
    var geolocate = new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true,
      },
      trackUserLocation: true,
    });
    map.current.addControl(geolocate);
    map.current.scrollZoom.disable()
    map.current.dragPan.disable()
    map.current.addControl(new mapboxgl.NavigationControl(), "bottom-right");
    map.current.on("load", function () {
      geolocate.trigger();
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          function (position) {
            // var lat = position.coords.latitude;
            // var lng = position.coords.longitude;
          },
          function (error) {}
        );
      }
    });
    var ctrl = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      marker:false,
    });
    if (mapContainer?.current?.children[0]) {
      mapContainer.current.removeChild(mapContainer.current.children[0]);
    }
    document
    .getElementById("createMicroblogGeocoder")
    .appendChild(ctrl.onAdd(map.current));
    map.current.scrollZoom.disable()
  map.current.dragPan.disable()
    ctrl.on("result", (e) => {
      const coords = e.result.geometry.coordinates;
      setSearchCoord(coords)
      const city = e.result.place_name.split(",")
      setCityName(city[0])
      if(coords){
      const marker = new mapboxgl.Marker()
            .setLngLat([
              coords[0],
              coords[1],
            ])
            .addTo(map.current);
            console.log(coords,"coords")
            setSearchCoord([
              coords[0],
              coords[1]
            ])
            function onDragEnd(event) {
              setSearchCoord(null)
              console.log(event)
              const lngLat = event.lngLat;
              const lat = lngLat.lat
              const lng = lngLat.lng
              console.log({ lng , lat },"lng , lat")
              marker.setLngLat([lng , lat])
              setSearchCoord([ lng , lat ])
              }
              map.current.on('click', onDragEnd);
            }
    });
        
  });
  const [micorName , setMicoName] = useState("")
  // Image Selection Start
  const [Image, setImage] = useState("");
  const [files, setFiles] = useState("");
  const [IsUpload, setIsUpload] = useState(false);
  const inputFile = useRef(null);
  // cover Image Selection Start
  const [CoverImage, setCoverImage] = useState("");
  const [Coverfiles, setCoverFiles] = useState("");
  const [CoverIsUpload, setCoverIsUpload] = useState(false);
  const CoverinputFile = useRef(null);
   // to open file explore
   const openInputFile = (e) => {
    inputFile.current.click();
    setError("")
  };
   // to open file explore
   const openCoverInputFile = (e) => {
    CoverinputFile.current.click();
    setError("")
  };
  // to display image
  const handleImageChange = (e) => {
    if(e.target.files[0].size > 52428800){
      alert("File size is too big");
      return
    }
    inputFile.current.click();
    setFiles(e.target.files[0])
    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader();
      reader.onload = function (e) {
        setImage(e.target.result);
        setIsUpload(true);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  
  // to display Cover image
  const handleCoverImageChange = (e) => {
    CoverinputFile.current.click();
    setCoverFiles(e.target.files[0])
    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader();
      reader.onload = function (e) {
        setCoverImage(e.target.result);
        setCoverIsUpload(true);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  // API Integration
  const MicroblogAPI = () => {
    let data = new FormData();
    data.append("userId", User._id);
    data.append("microblogName", micorName);
    data.append("file",files);
    data.append("filecover",Coverfiles);
    data.append("users", "");
    data.append("room", "");
    data.append("isMicroblog", true);
    data.append("platform", "web");
    data.append("albumName", "microblogs-image");
    data.append("coordinates1", SearchCoord[1]);
    data.append("coordinates0", SearchCoord[0]);
    data.append("city", cityName);
    let config = {
      method: 'post',
      url: Server2 + '/microblogs/create',
      data : data
    };
    
    axios(config)
    .then(function (response) {
      const data = {
        id:User?._id
      }
     Socket.emit("add-new-MicroBlog" , data)
    })
    .catch(function (error) {
      console.log(error,"i am error");
    });
    
  };
  const HandleCreateMicroblog = () => {
    if (Image === "") {
      setError("Please Select the Image");
    } else if(!micorName.trim()){
      setError("Please Add MicroBlog Name");
    } else if(SearchCoord === ""){
      setError("Please Search for an adderess");
    } else{
      setError("");
       MicroblogAPI()
       props.MicroblogClose()
    }
  }
  return (
    <>
     
        <div className="mb-main border rounded-3 shadow ">
          <div className="mb-header py-1 text-white d-flex align-items-center justify-content-between ">
            <p className="fw-bold mx-3 my-2">Add Microblog</p>

            <FiX
              className="fs-4 text-white me-3"
              role="button"
              onClick={props.MicroblogClose}
            />
          </div>
          <div className="text-center pt-3 bg-white position-relative">
          {CoverIsUpload?
            <img className="img-fluid rounded-2" src={CoverImage}  alt="Icon" />
            :
            <img className="img-fluid " src={"/img/mb-banner.png"} alt="Icon" />
          }
            <input
                type="file"
                id="file"
                style={{display: "none"}}
                    accept="image/png, image/jpeg"
                className="rounded-circle photouser"
                ref={CoverinputFile}
                onClick={openCoverInputFile}
                onChange={handleCoverImageChange}
              />
            <IoMdCamera  className="coverCam size-camra-icon " style={{cursor:"pointer"}} onClick={openCoverInputFile}
                onChange={handleCoverImageChange}/>
            <div className="mb-profile d-flex">
              {IsUpload ? (
                <img
                  src={Image}
                  className="microblogProfilePic rounded-circle"
                  style={{
                      borderColor:
                        Image === ""
                          ? "#6148a1"
                          : error === "inputfile"
                          ? "red"
                          : "#6148a1",
                    }}
                  height={110}
                  alt="Icon"
                />
              ) : (
                <div>
                <img
                className=" microblogProfilePic rounded-circle"
                style={{
                      borderColor:
                        Image.length > 0
                          ? "#6148a1"
                          : error === "inputfile"
                          ? "red"
                          : "#6148a1",
                    }}
                src={"/img/mb-profile.png"}
                alt="Icon"
              />
                </div>
              )}
              <input
                type="file"
                id="file"
                style={{
                      borderColor:
                        Image.length > 0
                          ? "#6148a1"
                          : error === "inputfile"
                          ? "red"
                          : "#6148a1",
                          display: "none"
                    }}
                    accept="image/png, image/jpeg"
                className="rounded-circle photouser"
                ref={inputFile}
                onClick={openInputFile}
                onChange={handleImageChange}
              />
              {/* icon */}
              <IoMdCamera
             
                type="file"
                htmlFor="file"
                className="mb-camera p-2 rounded-5 size-microblog-icon "
                onClick={openInputFile}
                onChange={handleImageChange}
              />
              
            </div>
          </div>
          <div className="mb-body ">
            <div className="form-name">
              <p className="mb-0 ms-3">Microblog Name</p>
              <div className=" mt-1 mx-3 d-flex">
              
                <input
                name="Name"
                id="Name"
                  type="text"
                  style={{
                      borderColor:
                      micorName.length > 0
                          ? "#ECECEC"
                          : error === "Please Add MicroBlog Name"
                          ? "red"
                          : "#ECECEC",
                    }}
                  placeholder="Trekking places in St. George"
                  className="search-f1 ps-2 p-2 form-control"
                  aria-label="text"
                  onChange={(e) => setMicoName(e.target.value) }
                  onClick={() =>setError("")}
                />
                </div>
            </div>
            <div className="mt-2 text-center">
              <span className=" location-heading">
                Add location: Click on map
              </span>
            </div>
            <div className="mx-5">
              <span className=" location-heading">
              We need access to your device's location to provide tailored recommendations, relevant content, and improved functionality. Your location is only accessed while the app is open and in use
              </span>
            </div>
            {/* map start */}
            <div className="map-wrap-microblog w-100">
              <div ref={mapContainer} className="map-microblog" />
            </div>
            <div>
              <span className="eventlabel">Search for an adderss</span>
              <div id="createMicroblogGeocoder" ></div>
              {error === "Please Search for an adderess" || error === "Please Select the Image" ? <h6 className="text-center text-danger mb-2">{error}</h6>:""}
              <div className="createBtnMicroblog">
                <button
                  className="Microblog-location-btn fw-bold fs-6 border shadow w-100 p-2 "
                  type="submit"
                  onClick={HandleCreateMicroblog}
                >
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
      {/* </div> */}
    </>
  );
};

export default AddMicroblog;
