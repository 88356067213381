import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { Button, Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { GrFormPrevious } from "react-icons/gr";
import "../css/pagination.css";
import { MdOutlineNavigateNext } from "react-icons/md";
import { Server2 } from "../../redux/actions/auth/auth.actions";
import { BsCaretDownFill } from "react-icons/bs";

const PER_PAGE = 100;

const DeactivateAprovedUser = () => {
  const [personal, setPersonalUser] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const userData = useSelector((state) => state?.auth?.user?.user);
  const [type, setType] = useState(null);
  function searchData(e) {
    if(type!==null){
      return personal.filter((user) => {
        if(user.type == type){
          return user;
        }
      })
    }else{
      return personal;
    }
  }
  // API'S MakeDeletionApproved Starts
  const getDeletionApplied = () => {
    const data = {
      id: userData?._id,
    };
    let config = {
      method: "post",
      url: Server2 + "/users/get-deletion-applied",
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.status === 200) {
          setPersonalUser(response.data.user);
        }
        setLoading(false);
        console.log(response.data, "i am res---");
      })
      .catch(function (error) {
        console.log(error, "error Delete User");
      });
  };

  // API'S Approved request Starts
  const handelDeleteRequest = (e) => {
    console.log(e);
    const data = {
      id: e,
    };
    let config = {
      method: "post",
      url: Server2 + "/admin/disable-user",
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data, "i am res---");
        getDeletionApplied();
      })
      .catch(function (error) {
        console.log(error, "error Delete User");
      });
  };

  useEffect(() => {
    setLoading(true);
    getDeletionApplied();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //pagination function
  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }

  //Remaning Days functions
  function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  const calculate_Days = (applied_date) => {
    const appliedDate = new Date(applied_date);
    let currentDate = addDays(appliedDate, 15);
    const mydate = new Date();
    const difference = currentDate.getTime() - mydate.getTime();
    var days_difference = Math.round(difference / (1000 * 60 * 60 * 24));
    return days_difference;
  };
  //pagination
  const offset = currentPage * PER_PAGE;
  const currentPageData =
    personal.length === 0
      ? null
      : searchData().slice(offset, offset + PER_PAGE).map((user, index) => (
          <tbody key={index}>
            <tr>
              <td>
                <div className="d-flex px-2 py-1">
                  <div>
                    <img
                      src={
                        user.profile_pic
                          ? user.profile_pic
                          : "/img/upload-photo.png"
                      }
                      className="avatar avatar-sm me-3"
                      alt="user1"
                    />
                  </div>
                  <div className="d-flex flex-column justify-content-center">
                    <h6 className="mb-0 text-sm text-capitalize ">
                      {user.fname} {user.lname}
                    </h6>
                    <p className="text-xs text-secondary mb-0">{user.email}</p>
                  </div>
                </div>
              </td>
              <td>
                <p className="align-middle ">
                  {user.type === true ? "Business User" : "Personal User"}
                </p>
              </td>
              <td>
                <p className="align-middle ">
                  {calculate_Days(user.deletion_applied.applied_date)} Days
                  Remaning
                </p>
              </td>
              <td className="allign-middle">
                <Button
                  style={{ background: "#6148a1", border: "none" }}
                  onClick={() => handelDeleteRequest(user._id)}
                >
                  Delete User
                </Button>
              </td>
            </tr>
          </tbody>
        ));

  const pageCount = Math.ceil(searchData().length / PER_PAGE);

  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center">
          <div
            className="spinner-border"
            role="status"
            style={{ width: "6rem", height: "6rem", color: "#6148A1" }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <div className="container-fluid ">
            <div className="row">
              <div className="col-12">
                <div className="card mb-4">
                  <div className="card-header pb-0">
                    <h6>Deletion Requests </h6>
                  </div>
                  <div
                    className="card-body px-0 pt-0 pb-2 task"
                    style={{ height: "73vh", overflow: "auto" }}
                  >
                    <div className="table-responsive p-0">
                        {
                            currentPageData?
                            <table className="table align-items-center mb-0">
                        <thead>
                          <tr>
                            <th className="text-uppercase   ">User</th>
                            <th className="">
                              <Dropdown className="m-0 p-0">
                                <Dropdown.Toggle
                                  className=" border-0"
                                  variant=""
                                  id="dropdown-basic"
                                  aria-expanded="false"
                                >
                                  <span
                                    className="fs-6 fw-bold text-uppercase"
                                    style={{ color: "#67748e" }}
                                  >
                                    Type{" "}
                                    <BsCaretDownFill size={12}/>
                                  </span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-menu">
                                  <Dropdown.Item
                                    onClick={() => {
                                      setType(null);
                                    }}
                                  >
                                    <span className="fw-bold">
                                      All User
                                    </span>
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => {
                                      setType(false);
                                    }}
                                  >
                                    <span className="fw-bold">
                                      Personal User
                                    </span>
                                  </Dropdown.Item>

                                  <Dropdown.Item
                                    onClick={() => {
                                      setType(true);
                                    }}
                                  >
                                    <span className="fw-bold">
                                      Business User
                                    </span>
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                              </th>
                            <th className=" text-uppercase ">Days</th>
                            <th className="text-uppercase">Actions</th>
                          </tr>
                        </thead>
                        {currentPageData}
                      </table>
                      :
                      <div className="d-flex justify-content-center w-100">
                        <p>No Account Deletion Requests</p>
                      </div>
                    }
                    </div>

                    {searchData().length > 99 ? (
                      <div
                        className=" mt-4"
                        style={{
                          padding: 2,
                          boxSizing: "border-box",
                          width: "100%",
                        }}
                      >
                        <ReactPaginate
                          activeClassName={"item active "}
                          breakClassName={"item break-me "}
                          breakLabel={"..."}
                          containerClassName={"pagination"}
                          t
                          disabledClassName={"disabled-page"}
                          marginPagesDisplayed={2}
                          nextClassName={"item next "}
                          nextLabel={
                            <MdOutlineNavigateNext
                              style={{ fontSize: 18, width: 150 }}
                            />
                          }
                          onPageChange={handlePageClick}
                          pageCount={pageCount}
                          pageClassName={"item pagination-page "}
                          pageRangeDisplayed={2}
                          previousClassName={"item previous"}
                          previousLabel={
                            <GrFormPrevious
                              style={{ fontSize: 18, width: 150 }}
                            />
                          }
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DeactivateAprovedUser;
