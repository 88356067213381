import React, { useCallback, useEffect, useRef, useState } from "react";
import Friends from "./Friends";
import Map from "../Dashboard/Aside/Map/Map";
import Events from "../Dashboard/Aside/Events/Events";
import "../../assets/styles/Dashboard/Dashboard.css";
import Microblogs from "./Aside/Microblogs/Microblogs";
import NavbarHome from "../home/NavbarNew/NavbarHome";
import TopCities from "./TopCities/TopCities";
import { Dropdown, Modal } from "react-bootstrap";
import {BsPaperclip,BsThreeDotsVertical,BsChatLeftTextFill,BsHeartFill} from "react-icons/bs";
import AddEvent from "../home/AddEventsPopup/AddEvent";
import AddMicroblog from "../home/AddMicroblogPopup/AddMicroblog";
import InputEmoji from "react-input-emoji";
import { IoMdSend } from "react-icons/io";
import axios from "axios";
import { useSelector } from "react-redux";
import { AiOutlineHeart } from "react-icons/ai";
import { ChatBox } from "../home/NavbarNew/ChatBox";
import { Server2, Socket } from "../../redux/actions/auth/auth.actions";
import FootNav from "../User_Profile/Footer/Footer-nav/FootNav";
import { FiX } from "react-icons/fi";
import ModalImage from "react-modal-image";
import { Loader } from "../../components/Loader";
import moment from "moment/moment";
import MicroblogActivities from "../home/AddMicroblogPopup/MicroblogActivities";
import { useNavigate } from "react-router-dom";
import Linkify from "linkify-react";
import LinkPreview from "../../components/LinkPreview";
import { thumbnailGen } from "../../redux/actions/auth/auth.actions";
import ReactPlayer from 'react-player';
const Dashboard = React.memo(() => {
  console.log("working")
  const userData = useSelector((state) => state?.auth?.user?.user);
  const currentCity = useSelector((state) => state?.auth?.userCity);
  const city1 =userData.location.extraCityToFollow0.cityName.split(",");
  const navigate = useNavigate()
  const [OpenTopCities, setTopCities] = useState(false);
  const [CitiesData, setCitiesData] = useState();
  const [latestCity, setLatestCity] = useState();
  const [cityname, setCityName] = useState(currentCity);
  const [citynameComp, setCityNameComp] = useState(userData.location.extraCityToFollow0.cityName);
  const [PostList, setPostList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [MicroblogOpen, setMicroblogOpen] = useState(false);
  const [date, setdate] = useState("");
  const [comment, setComment] = useState({text:"",index:0,statusId:null});
  const [coords, setCoords] = useState();
  const [chatBox, setChatBox] = useState(false);
  const [chatData, setChatData] = useState();
  const [getgrouproom, setGetGroupRoom] = useState("");
  const [ActivitiesOpen, setActivitiesOpen] = useState(false);
  const [MicroData, setMicroData] = useState([]);
  const [Eventlist, setEventlist] = useState([]);
  const [microlist, setMicrolist] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [EventLoading, setEventLoading] = useState(false);
  const [BtnDisable, setBtnDisable] = useState(false);
  const [LikesPopup, setLikesPopup] = useState({ index: 0, type: false });
  const [LikesComment, setLikesComment] = useState({ index: 0, type: false });
  const [typinglist, SetTypingList] = useState([]);
  const [reportPopup, setReportPopup] = useState(false);
  const [reportData, setReportData] = useState({id: "", type: "", statusBy: ""});
  const [thumbnail, setThumbnail] = useState("");
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [mediaUrl, setMediaUrl] = useState("");
  
  // File Explore Open
  const inputFile = useRef(null);
  const [loader, setLoader] = useState(false);
  const [text, setText] = useState("");
  const [files, setFiles] = useState(null);
  const [image, setImage] = useState("");
  const [IsUpload, setIsUpload] = useState(false);
  const [TypeFile, setTypeFile] = useState("");
  const [videoSrc, setVideoSrc] = useState(null);
   const videoRef = useRef();
  useEffect(() => {
    Socket.on("newStatus", (data) => {
      const newCity = data.city.split(",");
      if (cityname == newCity[0]){
        GetPosts(cityname)
      }
    });

    videoRef.current?.load();
    
    if (TypeFile === "video" && image) {
      thumbnailGen(files).then((thumb) => {
       setThumbnail(thumb)
     })
    }    
    
    Socket.on("com-typing", (data) => {
      const newCity = data?.city?.split(",");
      if (data.statustyping == true && cityname==newCity[0]) {
        SetTypingList((prev) => [...prev, data]);
        isTyping(data.statusId)
      } else if (data.statustyping == false && cityname==newCity[0]) {
        var lists = typinglist.filter((x) => {
          return x.from != data.from && x.index != data.index;
        });
        SetTypingList(lists);
      }
    });
    
    if(image){
      mediaUpload();
    }

  }, [videoSrc , Socket, cityname , image]);

  useEffect(() => {
    const prev = comment.text;
    if (comment.text.length > 0) {
      const data2 = {
        from: userData?._id,
        statustyping: true,
        index: comment.index,
        statusId:comment.statusId,
        city:currentCity,
        name:userData.type?userData?.fname:userData?.fname +" "+ userData?.lname
      };
      Socket.emit("comment-typing", data2);
      const timer = setTimeout(() => {
        if (comment.text == prev) {
          const data2 = {
            from: userData?._id,
            statustyping: false,
            index: comment.index,
            statusId:comment.statusId,
            city:currentCity,
            name:userData.type?userData?.fname:userData?.fname +" "+ userData?.lname
          };
          Socket.emit("comment-typing", data2);
        }
      }, 1000);

      return () => clearTimeout(timer);
    } else {
      const data2 = {
        from: userData?._id,
        statustyping: false,
        index: comment.index,
        statusId:comment.statusId,
        city:currentCity,
        name:userData.type?userData?.fname:userData?.fname +" "+ userData?.lname
      };
      Socket.emit("comment-typing", data2);
    }
    isTyping(comment.statusId)
  }, [comment.text]);
  const isTyping = (postId) => {
    return typinglist.some(item => item.statusId === postId && item.statustyping==true );
  };

  const handleImageChange = useCallback((e) => {
    if(e.target.files[0].size > 52428800){
      alert("File size is too big");
      return
    }
    // inputFile.current.click();
    setFiles(e.target.files[0]);
    const type = e.target.files[0].type.split("/");
    setTypeFile(type[0]);

    if (e.target.files && e.target.files[0]) {
      if (TypeFile === "video") {
        setImage(URL.createObjectURL(e.target.files[0]));
        setIsUpload(true);
        StatusUpdate()
      } else {
        const reader = new FileReader();
        reader.onload = function (event) {
          setImage(event.target.result);
          setIsUpload(true);
        };
        reader.readAsDataURL(e.target.files[0]);
      }
    }
    if (TypeFile === "video" && image) {
      thumbnailGen(files).then((thumb) => {
       setThumbnail(thumb)
     })
   }
  }, [inputFile, setFiles, TypeFile, setImage] , image);

  // media upload before posting
  const mediaUpload = () => {
    if (TypeFile !== "") {

      if (TypeFile === "video" && image) {
        thumbnailGen(files).then((thumb) => {
         setThumbnail(thumb)
       })
      }

      let data = new FormData();
      data.append("from", userData._id);
      data.append("thumbnail",thumbnail?thumbnail:null)
      data.append("file", files !== null ? files : null);
      data.append("filename", "");

      var config = {
        method: "post",
        url: Server2 + "/status/status-media",
        data: data,
      };

      axios(config).then(function (response) {
        setThumbnailUrl(response.data.thumbnail);
        setMediaUrl(response.data.media);
      });

    } else {
      // alert("Please select a file first");
    }
  };

  const clearUpload = () => {
    const url = mediaUrl
    setIsUpload(false);
    setVideoSrc(null);
    setImage("");
    setFiles(null);
    setMediaUrl("");
    setTypeFile("");
    setThumbnailUrl("");

    var config = {
      method: "post",
      url: Server2 + "/status/delete-from-bucket",
      data: {fileName: url},
    };

    axios(config)
    .then((response) => {
      // deteted
    })
    .catch((error) => {
      console.log("er", error);
    });

  }

  // Status Update Api
  const StatusUpdate = () => {
    if (text !== "" || (TypeFile !== "" && mediaUrl !== "")) {
      // if (TypeFile === "video") {
      //   setLoader(true);
      // }
      setIsUpload(false);
      let data = new FormData();
      data.append("from", userData._id);
      data.append("userid", userData._id);
      data.append(
        "profile_pic",
        userData.profile_pic ? userData.profile_pic : ""
      );
      data.append("fname", userData.fname);
      data.append("thumbnail",thumbnailUrl?thumbnailUrl:null)
      data.append("lname", userData.type === false ? userData.lname : "");
      data.append("status_type", files !== null ? TypeFile : "text");
      data.append("message", text);
      data.append("time", date);
      data.append("size", "");
      data.append("filename", mediaUrl ? mediaUrl : "");
      data.append("platform", "web");
      data.append("caption", text);
      data.append("likes", "");
      data.append("location_id", currentCity);

      var config = {
        method: "post",
        url: Server2 + "/status/new-status",
        data: data,
      };

      axios(config)
        .then(function (response) {
          setPostList((current) => [response.data.status[0], ...current])
          setText("");
          setMediaUrl("");
          setFiles(null);
          setTypeFile("");
          setThumbnailUrl("");
          setLoader(false);
          inputFile.current.value = null;
          setIsUpload(false)
          const data = {
            statusId: response.data.status[0]._id,
            from: userData._id,
            userid: userData._id,
            city:currentCity,
          }
          Socket.emit("add-new-status", data);
        })
        .catch(function (error) {
          setLoader(false);
        });
    } else if(TypeFile !== "" && mediaUrl === "") {
      // alert("Please wait for media to upload");
    } else {
      alert("Please Enter Text or Select Image");
    }
  };
  // Get Status By City Name
  const GetPosts = async (cityname) => {
    console.log(cityname , "get")
    var config = {
      method: "GET",
      url: Server2 + `/status/getStatusWithLocation?ID=${cityname}`,
    };
    await axios(config)
      .then((response) => {
        setLoading(true)
        response.data.sort((b, c) => new Date(c.status.time) - new Date(b.status.time));
        let a = [];
        response.data.forEach((oneloop) => {
          a.push(oneloop.status);
        });
        setPostList(a.filter((e) => e.is_hidden !== true));
      })
      .catch(function (error) {
      });
  };
  // Delete Status
  const DeletePost = (e) => {
    var data = JSON.stringify({
      id: e,
      from: userData._id,
      disable:true
    });
    var config = {
      method: "POST",
      url: Server2 + "/status/delete-status",
      data: data,
      headers: { "Content-Type": "application/json" },
    };
    axios(config)
      .then((response) => {
        GetPosts(currentCity);
      })
      .catch((error) => {
        console.log("er", error);
      });
  };
  const handleThreeDots = (e) => {
    DeletePost(e);
  };
  const reportPost = () => {
    var data = JSON.stringify({
      from: userData._id,
      statusId: reportData.id,
      statusBy: reportData.statusBy,
      type: reportData.type,
    });
    var config = {
      method: "POST",
      url: Server2 + "/status/report",
      data: data,
      headers: { "Content-Type": "application/json" },
    };
    axios(config)
      .then((response) => {
        setReportPopup(false)
        setReportData({id: "", type: "", statusBy: ""})
        // setGetStatus((current) => [response.data.status[0], ...current]);
      })
      .catch((error) => {
        console.log("er", error);
      });
  };
  const handleReport = (id, statusby) => {
    // reportPost(e);
    setReportPopup(true)
    setReportData({id: id, type: "Nudity", statusBy: statusby})
  };
  // Update/Post Comments On Status
  const CommentUpdate = (e) => {
    if (comment.text !== "") {
      let data = JSON.stringify({
        status: e._id,
        from: userData._id,
        replyType: "Text",
        message: comment.text,
        fname: userData.fname,
        lname: userData.type === false ? userData.lname : "",
        profile_pic: userData.profile_pic,
      });

      var config = {
        method: "post",
        url: Server2 + "/status/new-comment",
        headers: { "Content-Type": "application/json" },
        data: data,
      };

      axios(config)
        .then(function (response) {
          setComment({text:"",index:0,statusId:null});
          GetPosts(currentCity);
          let data = {
            me: userData._id,
            createdBy:e.from,
            reply:false,
            status:e._id,
            userId:e.userid,
            city:currentCity
        }
        Socket.emit('add-status-comment', data)
        const data2 = {
          to: e.userid,
        };
        Socket.emit("new-notification", data2);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      alert("please add some text in comments");
    }
  };
  // Likes On status
  const Likes = (e) => {
    setBtnDisable(true);
    let data = JSON.stringify({
      status: e.status._id,
      from: userData._id,
      type: e.type,
      statuslike: false,
    });

    var config = {
      method: "post",
      url: Server2 + "/status//new-like",
      headers: { "Content-Type": "application/json" },
      data: data,
    };

    axios(config)
      .then(function (response) {
        let newArr = [...PostList];
        newArr[e.index] = response?.data?.status[0];
        setPostList([]);
        setPostList(newArr);
        setBtnDisable(false);
        GetPosts(currentCity);
        if(e.type === true){
          let data = {
            me: userData._id,
            createdBy:e.status.from,
            reply:false,
            userId:e.status.userid,
            status:e.status._id,
            city:currentCity
        }
        Socket.emit('add-status-like', data)
        const data2 = {
          to: e.status.userid,
        };
        Socket.emit("new-notification", data2);
          }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getlikestatus = (likes, ststus) => {
    let status = false;
    likes.map((item) => {
      if (item.from === userData?._id ) {
        status = true;
      }
      return("");
    });
    return status;
  };

  const CommentLike = (e) => {
    // Socket.broadcast.emit("com-typing", e)
    var data = JSON.stringify({
      from: userData._id,
      status: e.status,
      type: e.type,
      commentId:e.CommentId,
    });
    console.log("Working", data)
    var config = {
      method: "post",
      url: Server2 + "/status/new-comment-like",
      headers: { "Content-Type": "application/json" },
      data: data,
    };

    axios(config)
      .then(function (response) {
        GetPosts(currentCity);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  // Delete Comment
  const DeleteComment = (e) => {
    let data = JSON.stringify({
      status_id: e.status,
      reply_id: e.Comment,
    });

    var config = {
      method: "post",
      url: Server2 + "/status/delete-comment",
      headers: { "Content-Type": "application/json" },
      data: data,
    };

    axios(config)
      .then(function (response) {
        GetPosts(currentCity);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  // Newsfeed  Func End

  const onButtonClick = () => {
    inputFile.current.click();
  };
 
  const UserEvent = async(e) => {
    setEventLoading(true)
    setEventlist([]);
    var config = {
      method: "GET",
      url: Server2 + `/events/getbyid?city=${e}`,
    };
   await axios(config)
      .then((response) => {
        setEventLoading(false)
        setEventlist(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const UserMicro = async(e) => {
    setEventLoading(true)
    setMicrolist([]);
    var config = {
      method: "GET",
      url: Server2 + `/microblogs/getbycity?city=${e}&id=${userData?._id}`,
    };
    await axios(config)
      .then((response) => {
        setEventLoading(false)
        setMicrolist(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const GetStatusBookMarkApi = () => {
    var config = {
      method: "GET",
      url: Server2 + `/microblogs/getbyid?user=${userData?._id}`,
    };
    axios(config)
    .then((response) => {
      if(response.data.message!=="No Bookmarked Microblogs"){
        setMicrolist((prev) => [...prev, ...response.data])
      }
      })
      .catch(function (error) {
        console.log("error micro chat get ", error);
      });
  }
  const BookMarkEventApi=()=>{
    var config = {
      method: "GET",
      url: Server2 + `/events/getBookmarkedEvents?user=${userData?._id}`,
    };
    axios(config)
      .then((response) => {
        if(response.data.message!=="Events list empty"){
          setEventlist((prev)=>[...prev,...response.data])
        }
      })
      .catch(function (error) {
        console.log("error bookmarkapi  ",error);
      });
  }

  useEffect(() => {
    setLoading(false);
    let d = new Date();
    setdate(d.toString());
    GetPosts(latestCity?.city || currentCity);
    UserEvent(latestCity?.city || currentCity);
    UserMicro(latestCity?.city || currentCity);
    GetStatusBookMarkApi();
    BookMarkEventApi();
  }, [latestCity?.city]);

   const reportTypes = [
    "Child Exploitation",
    "Drugs, Weapons, or Regulated Goods",
    "False Information",
    "Fraud or Scam",
    "Hate Organizations",
    "Hate Speech",
    "Harassment",
    "Intellectual Property",
    "Nudity",
    "Privacy Violations",
    "Spam",
    "Violence",
    "Others"
  ]
  return (
    <>
    { reportPopup ?
      <Modal
        show={reportPopup}
        onHide={() => setReportPopup(false)}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title style={{color:"black", fontSize:"20px"}}>Flag Objectionable Content</Modal.Title>
        </Modal.Header>
        <Modal.Body>
         Select report type
          <center>
            <select 
              className="form-select mt-2" aria-label="Default select example"
              onChange={(e) => setReportData({id: reportData.id, type: e.target.value, statusBy: reportData.statusBy})}
            >
              {
                reportTypes.map((data) =>{
                  return(
                    <option value={data}>{data}</option>
                  )
                })
              }
            </select>
            <button type="button" className="btn mx-auto my-4" style={{backgroundColor:"#6148a1", color:"white", width:"100%"}} onClick={() => reportPost()}>submit</button>
          </center>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={reportPost()}>
            Submit
          </Button>
        </Modal.Footer> */}
      </Modal>
      : "" }
    <div className="bg-white">
      <NavbarHome />
      <div className="mx-3 px-3 pb-3 main-dash">
        <div className="row dashboard-row border rounded-3 py-3">
          <div className="col-lg-8 col-md-12 col-sm-12 pe-3 pe-lg-0 pe-xl-0 pb-3 pb-lg-0 pb-xl-0">
            <div className="section border rounded-3">
              <div className="row px-3 py-2">
                <div className="col-8 col-lg-12 col-xl-12 pt-3 ps-3">
                  <p className="fw-bold">Global Newsfeed</p>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <div id="test" className="d-flex mainInput">
                    <InputEmoji
                      value={text}
                      onChange={(e) => {
                        setText(e);
                      }}
                      className="mainInput"
                      onEnter={() => StatusUpdate()}
                      placeholder="What's on your mind?"
                      borderRadius={5}
                    />
                    <BsPaperclip
                      style={{ zIndex: "10", padding: "4px" }}
                      className="Paperclip"
                      size={27}
                      onClick={onButtonClick}
                    />
                    <input
                      type="file"
                      id="file"
                      ref={inputFile}
                      onChange={handleImageChange}
                      style={{ display: "none" }}
                    />
                    <button
                      className="post-btn"
                      type="button"
                      data-bs-toggle="button"
                      onClick={() => StatusUpdate()}
                    >
                      Post
                    </button>
                  </div>
                </div>
              </div>

              <div className="main-posts overflow-auto  ms-3 mt-2">
                {IsUpload === true ? (
                  <div className="position-relative">
                    { TypeFile !== "" && mediaUrl === "" ? ( 
                      <div className="d-flex justify-content-center">
                        <Loader/>
                      </div>
                    ) : TypeFile === "video" && mediaUrl !== "" ? (
                      <div
                        className="position-relative"
                        style={{ width: "95%" }}
                      >
                        <video
                          ref={videoRef}
                          style={{ marginLeft: "20px", borderRadius: "5px" }}
                          width="100%"
                          height="260px"
                          controls
                        >
                          <source src={image} />
                        </video>
                        <FiX
                          className=""
                          color="black"
                          style={{
                            position: "absolute",
                            top: "2px",
                            right: "-13px",
                            zIndex: "1",
                            backgroundColor: "#ECECEC",
                            borderRadius: "10px",
                            padding: "3px",
                            cursor: "pointer",
                          }}
                          size={22}
                          onClick={() => {clearUpload()}}
                        />
                      </div>
                    ) : TypeFile !== "" && TypeFile !== "video" && mediaUrl !== "" ? (
                      <div
                        className="position-relative"
                        style={{ width: "95%" }}
                      >
                        <ModalImage
                        hideDownload	
                          className="modleImage"
                          small={image}
                          large={image}
                          alt={"Uploaded File"}
                        />
                        <FiX
                          className="position-absolute"
                          color="black"
                          style={{
                            right: "0px",
                            top: "-3px",
                            zIndex: "1",
                            backgroundColor: "#ECECEC",
                            borderRadius: "10px",
                            padding: "3px",
                            cursor: "pointer",
                          }}
                          size={22}
                          onClick={() => {clearUpload()}}
                        />
                      </div>
                    ) : "" }
                  </div>
                ) : (
                  ""
                )}
                {loader === true ? (
                  <div style={{ margin: "0 50%" }}>
                    <Loader />
                  </div>
                ) :null}
                  {Loading === false ? <div style={{ display: "flex", justifyContent: "center", margin: "5%" }}><div className="loading2"></div></div> : PostList.length > 0 ? (
                    PostList.map((items, index) => {  
                      const string = items?.message.match(/[a-zA-Z0-9.,/'";:!@$%&*()_+=-±~?#€[{}^><|/]+/)
                      var ranges = items?.message.match([
                        "(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])", // U+1F680 to U+1F6FF
                      ]);
                      // let date = new Date(items.time).toString().split(" ");
                      return (
                        <div
                          className="border rounded-2  jdp  me-3 py-3 mt-2"
                          key={index}
                        >
                          <div className="d-flex  d-flex justify-content-between ">
                            <div
                              className="user-bprofile d-flex justify-content-start align-items-center"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                if (items.from === userData?._id) {
                                  navigate("/myProfile");
                                } else {
                                  navigate("/Profiless", {
                                    state: {
                                      item: items.from,
                                    },
                                  });
                                }
                              }}
                            >
                              <img
                                className="img-profile"
                                src={
                                  items.profile_pic
                                    ? items.profile_pic
                                    : "img/upload-photo.png"
                                }
                                alt="Admin"
                              />
                              <div className="lh-1">
                                <span className="fw-bold lh-1 title">
                                  {items.fname} {items.lname}
                                </span>
                                <br />
                                <span className="lh-1 date">
                                  {/* {`${date[0]}` +
                                    " " +
                                    `${date[1]}` +
                                    " " +
                                    `${date[2]}` +
                                    " " +
                                    `${date[3]}` +
                                    " " +
                                    moment(items.time).format("LT")} */}
                                    {moment(items.time).format("MMMM Do, YYYY  h:mm A")}
                                </span>
                              </div>
                            </div>
                              <Dropdown>
                                <Dropdown.Toggle
                                  className="border-0 bg-transparent togglebtn d-flex bg-danger"
                                  variant=""
                                  id="dropdown-basic"
                                  aria-expanded="false"
                                >
                                  <BsThreeDotsVertical className="mt-3 me-3" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-menu">
                                  {items.from === userData?._id ? (
                                    <Dropdown.Item
                                      onClick={() => handleThreeDots(items._id)}
                                    >
                                      Delete Post
                                    </Dropdown.Item>
                                  ) : ( "" )}
                                  {items.userid !== userData?._id ? (
                                    <Dropdown.Item
                                      onClick={() => handleReport(items._id, items.userid)}
                                    >
                                      Report Post
                                    </Dropdown.Item>
                                  ) : null }
                                </Dropdown.Menu>
                              </Dropdown>
                          </div>
                          <div className="ms-3 ps-5 me-3 ">
                            {items.message.match(/http[s]?:\/\/\S+/) ? 
                              <>
                                <Linkify> {items.message} </Linkify>
                                <LinkPreview url={items.message} />
                              </>
                            :
                              <p className="desc text-dark"
                                style={{
                                  wordWrap: "break-word",
                                  fontSize: string ? "13px" : ranges?.input.length < 7 ? "60px" : "13px",
                                }}
                              >
                                {items.message}
                              </p>
                            }
                            {items.filename ? (
                              items.status_type === "video" ? (
                                // <video width="95%" height="350px" controls>
                                //   <source src={items.filename} />
                                // </video>
                                <div className="d-flex justify-content-center">
                                  <ReactPlayer url={items.filename} controls={true} style={{borderRadius:"6px" , maxWidth:"100%" , maxHeight:"350px"}} />
                                </div>
                              ) : (
                                <div className="">
                                  <ModalImage
                                  hideDownload	
                                    className="pro-img border rounded-3 mt-0 DashImage"
                                    small={items.filename}
                                    large={items.filename}
                                    alt={"Status Updates"}
                                  />
                                </div>
                              )
                            ) : null}
                            {/* <img
                                  className="pro-img border rounded-3 "
                                  src={items.filename}
                                  alt="Admin"
                                  style={{
                                    height: "50%",
                                    width: "95%",
                                    marginTop: "0px",
                                  }} /> */}
                            <div className="desc-icons p-1 d-flex justify-content-end align-items-center position-relative" onMouseLeave={() => {
                              setLikesPopup({
                                index: 0,
                                type: false
                              })
                            }}>
                              {items.replies.length} <BsChatLeftTextFill className="icons-chat m-2" />
                              <div style={{cursor:"pointer"}} onMouseEnter={() => {
                                setLikesPopup({
                                  index: index,
                                  type: true
                                })
                              }}>{items.likes.length}</div>
                              {items.likes.length > 0 ? (
                                <div>
                                  <button
                                    style={{ border: "none", backgroundColor: "transparent" }}
                                    disabled={BtnDisable === true ? true : false}
                                    onClick={(e) => {
                                      Likes({
                                        status: items,
                                        type: false,
                                      });
                                    }}

                                  >
                                    <BsHeartFill
                                      size={14}
                                      className="icons-heart m-2"
                                      color="#D84076"

                                    />
                                  </button>
                                  {
                                    LikesPopup.type === true && LikesPopup.index === index ?
                                      <div style={{
                                        position: "absolute",
                                        top: "30px",
                                        right: "1px",
                                        backgroundColor: "#fff",
                                        width: "100px",
                                        height: "fit-content",
                                        maxHeight: "150px",
                                        borderRadius: "4px",
                                        overflow: "auto",
                                        zIndex: 100,
                                        padding: "5px 5px",
                                        textAlign: "center"
                                      }}>
                                        {
                                          items.likes.map((e, index) => {
                                            return (
                                              <>
                                                <span style={{ color: "#000000", lineHeight: 0.7, marginbottom: "0px" }}>{e.fname} {e.lname}</span><br />
                                              </>
                                            )
                                          })
                                        }
                                      </div>
                                      :
                                      null
                                  }
                                </div>
                              ) : (
                                <button
                                  style={{ border: "none", backgroundColor: "transparent" }}
                                  disabled={BtnDisable === true ? true : false}
                                  onClick={(e) => {
                                    Likes({
                                      status: items,
                                      type: true,
                                    });
                                    // setLike(true);
                                  }}
                                >
                                  <AiOutlineHeart
                                    color="#D84076"
                                    className="icons-heart m-2"
                                    size={16}
                                  />
                                </button>
                              )}
                            </div>
                            {items.replies.map((Com, i) => {
                              const string = items?.message.match(/[a-zA-Z0-9.,/'";:!@$%&*()_+=-±~?#€[{}^><|/]+/)
                              var ranges = Com?.message.match([
                                "(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])", // U+1F680 to U+1F6FF
                              ]);
                              return (
                                <div key={i}>
                                  <div className="d-flex justify-content-between ">
                                    <div
                                      className="user-bprofile d-flex justify-content-start align-items-center"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        if (Com.from === userData?._id) {
                                          navigate("/myProfile");
                                        } else {
                                          navigate("/Profiless", {
                                            state: {
                                              item: Com.from,
                                            },
                                          });
                                        }
                                      }}
                                    >
                                      <img
                                        className="img-profile"
                                        src={
                                          Com.profile_pic
                                            ? Com.profile_pic
                                            : "/img/upload-photo.png"
                                        }
                                        alt="Admin"
                                      />
                                      <div className="lh-1">
                                        <span className="fw-bold lh-1 title">
                                          {Com.fname} {Com.lname}
                                        </span>
                                      </div>
                                    </div>
                                      <Dropdown>
                                        <Dropdown.Toggle
                                          className="border-0 bg-transparent togglebtn d-flex"
                                          variant=""
                                          id="dropdown-basic"
                                          aria-expanded="false"
                                          >
                                          <BsThreeDotsVertical className="mt-3 me-3" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="dropdown-menu">
                                          {userData?._id === Com.from ? (
                                          <Dropdown.Item
                                            onClick={() =>
                                              DeleteComment({
                                                status: items._id,
                                                Comment: Com._id,
                                              })
                                            }
                                          >
                                            Delete Comment
                                          </Dropdown.Item>
                                          ) : ( "" )}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                  </div>
                                  <div className="d-flex justify-content-between">
                                    <p
                                      className="text-start ms-5 ps-3"
                                      style={{
                                        wordWrap: "break-word",
                                        fontSize: string ? "13px" : ranges?.input.length < 7 ? "60px" : "13px",
                                      }}
                                    >
                                      {Com.message}
                                    </p>
                                    <div className="desc-icons mt-0 pt-0 d-flex justify-content-end align-items-center posistion-relative" style={{top: "-15px"}}
                                      // onMouseLeave={() => { setLikesComment({ index: 0, type: false }) }}
                                      // onMouseEnter={() => { setLikesComment({ index: i, type: true }) }}
                                    >
                                      {Com.likes.length}
                                      {Com.likes.length > 0 ? (
                                        <button
                                          style={{
                                            border: "none",
                                            backgroundColor: "transparent",
                                          }}
                                          onClick={() => {
                                            CommentLike({
                                              userId: items.userid,
                                              status: items._id,
                                              CommentId: Com._id,
                                              type: false
                                            });
                                          }}
                                        >
                                          <BsHeartFill
                                            // key={index}
                                            size={14}
                                            className="icons-heart m-2"
                                            color="#D84076"
                                          />
                                        </button>
                                      ) : (
                                        <button
                                          style={{
                                            border: "none",
                                            backgroundColor: "transparent",
                                          }}
                                          onClick={() => {
                                            CommentLike({
                                              userId: items.userid,
                                              status: items._id,
                                              CommentId: Com._id,
                                              type: true
                                            });
                                            // setLike(true);
                                          }}
                                        >
                                          <AiOutlineHeart
                                            color="#D84076"
                                            className="icons-heart m-2"
                                            size={16}
                                          />
                                        </button>
                                      )}
                                      {
                                        LikesComment.type === true && LikesComment.index === i ?
                                          <div style={{
                                            backgroundColor: "#fff",
                                            width: "100px",
                                            height: "20px",
                                            maxHeight: "150px",
                                            borderRadius: "4px",
                                            overflow: "auto",
                                            zIndex: 1,
                                            padding: "5px 5px",
                                            textAlign: "center"
                                          }}>
                                            {
                                              Com.likes.map((e, index) => {
                                                return (
                                                  <>
                                                    <span style={{ color: "#000000", lineHeight: 0.7, marginbottom: "0px" }}>{e.fname} {e.lname}</span><br />
                                                  </>
                                                )
                                              })
                                            }
                                          </div>
                                        :
                                        null
                                      }
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                            {isTyping(items._id) ? (
                              <div className="typing">
                                <div className="dot"></div>
                                <div className="dot"></div>
                                <div className="dot"></div>
                              </div>
                            ) : (
                                <div className="typing"></div>
                            )}
                            <div
                              id="comment"
                              className="d-flex align-items-center position-relative"
                            >
                              <InputEmoji
                                type="search"
                                className="mainInput"
                                onChange={(e) => setComment({text:e,index:index,statusId:items._id})}
                                onEnter={() => CommentUpdate(items)}
                                value={
                                  index === comment.index ? comment.text : ""
                                }
                                cleanOnEnter
                                placeholder={"Write a comment"}
                                borderRadius={5}
                              />
                              <IoMdSend
                                className="send-btn"
                                size={39}
                                onClick={() => {
                                  CommentUpdate(items);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="posts border rounded-2 mx-3 mb-3">
                      <p className="posts-text d-flex justify-content-center mt-5 p-5">
                        Sorry! No Posts available for this location yet. Check out
                        another Location.
                      </p>
                    </div>
                  )}
              </div>
            </div>
            <div className="friends-div ">
              <Friends
                setChatBox={() => setChatBox((e) => !e)}
                setChatData={(item) => setChatData(item)}
                setGetGroupRoom={(text) => setGetGroupRoom(text)}
              />
            </div>
            {/* NewsFeed End */}

            {/* ^ All News Feed Div */}
            {/* Friends */}

            {/* ^ Friends*/}
          </div>
          {/* ^ Col-8 */}

          <div className="aside col-lg-4 col-md-12 col-sm-12">
            {/* Map Start */}
            <Map
             setLatestCity={(e) => setLatestCity(e)}
              latestCity={latestCity}
              setCoords={(e) => setCoords(e)}
              setTopCities={() => setTopCities((e) => !e)}
              SetCitiesData={(e) => setCitiesData(e)}
              CityName={(e) => setCityName(e)}
              CityNameComp={(e) => setCityNameComp(e)}
              GetPosts = {(e) => GetPosts(e)}
              UserEvent={(e) =>UserEvent(e)}
              UserMicro={(e) =>UserMicro(e)}
              GetStatusBookMarkApi={() =>GetStatusBookMarkApi()}
              BookMarkEventApi={() =>BookMarkEventApi()}
            />
            {/* ^ Map End */}

            {/*  Events Start */}
            <Events isopen={() => setIsOpen((e) => !e)} Eventlist={Eventlist} EventLoading={EventLoading} />
            {/* ^ Events End */}

            {/*  Microblogs Start */}
            <Microblogs
              isMicro={() => setMicroblogOpen((e) => !e)}
              isActiv={() => setActivitiesOpen((e) => !e)}
              MicroData={(item) => setMicroData(item)}
              microlist={microlist}
              EventLoading={EventLoading}
            // stShow={() => setShow(true)}
            />
            {/* ^ Microblogs     End */}
          </div>
          {OpenTopCities && (
            <TopCities
              handleClose={() => setTopCities((e) => !e)}
              CitiesData={CitiesData}
              coords={coords}
              setLatestCity = {(e) => setLatestCity(e)}
            />
          )}
          <div className="">
            {chatBox && (
              <ChatBox
                setChatBox={() => setChatBox(false)}
                Data={chatData}
                Room={getgrouproom}
              />
            )}
          </div>
          {isOpen && (
            <div className="main-event-bg-dash">
              <AddEvent
                isOpen={isOpen}
                setIsOpen={() => setIsOpen((e) => !e)}
                handleClose={() => setIsOpen((e) => !e)}
              />
            </div>
          )}
          {MicroblogOpen && (
            <div className="main-event-bg-dash">
              <AddMicroblog
                MicroblogClose={() => setMicroblogOpen((e) => !e)}
              />
            </div>
          )}
          {ActivitiesOpen && (
            <div className="main-event-bg-dash">
              <MicroblogActivities
                ActivitiesClose={(e) => setActivitiesOpen(e)}
                MicroData={MicroData}
              />
            </div>
          )}
        </div>
      </div>
      <FootNav />
    </div>
    </>
  );
});
export default Dashboard;