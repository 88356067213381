import * as authtype from "../actions/auth/auth.types";

const initialState = {
  loading: false,
  user: [],
  error: [],
  linkuser: [],
  forgotPassword: [],
  newPassState: [],
  userCity:[]
};
//Auth Reducer
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case authtype.LOADING:
      return {
        ...state,
        loading: true,
      };
    case authtype.USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };
    case authtype.USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case authtype.NEW_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };
    case authtype.NEW_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case authtype.LOGOUT:
      return {
        ...state,
        user: [],
        forgotPassword: [],
        error: [],
        linkuser: [],
        newPassState: [],
      };
    case authtype.LINK_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        linkuser: action.payload,
      };

    case authtype.LINK_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case authtype.FORGOT_PASSWORD_SUCCESSS:
      return {
        ...state,
        loading: false,
        forgotPassword: action.payload,
      };
    case authtype.FORGOT_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case authtype.NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        newPassState: action.payload,
      };
    case authtype.NEW_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case authtype.FOURTH_BUSSINESS_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };
    case authtype.FOURTH_BUSSINESS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case authtype.GOOGLE_SIGNUP_SUCCESS:
      return {
        ...state, 
        loading: false,
        user: action.payload,
      };
    case authtype.GOOGLE_SIGNUP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case authtype.FACEBOOK_SIGNUP_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };
    case authtype.FACEBOOK_SIGNUP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case authtype.GOOGLE_AUTH_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };
    case authtype.GOOGLE_AUTH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case authtype.FACEBOOK_AUTH_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };
    case authtype.FACEBOOK_AUTH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      case authtype.FIRST_BUSSINESS_SUCCESS:
        return {
          ...state,
          loading: false,
          user: action.payload,
        };
      case authtype.FIRST_BUSSINESS_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case authtype.FIRST_PRESONAL_SUCCESS:
        return {
          ...state,
          loading: false,
          user: action.payload,
        };
      case authtype.FIRST_PRESONAL_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case authtype.SECOND_PRESONAL_SUCCESS:
        return {
          ...state,
          loading: false,
          user: action.payload,
        };
      case authtype.SECOND_PRESONAL_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case authtype.THIRD_PRESONAL_SUCCESS:
        return {
          ...state,
          loading: false,
          user: action.payload,
        };
      case authtype.THIRD_PRESONAL_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case authtype.AGREED_TP_SUCCESS:
        return {
          ...state,
          loading: false,
          user: action.payload,
        };
      case authtype.AGREED_TP_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
        case authtype.COVERPHOTO:
          return {
            ...state,
            user: action.payload,
          };
        case authtype.PROFILEPHOTO:
          return {
            ...state,
            user: action.payload,
          };
      case authtype.SETTING_PRO_PERSONAL_SUCCESS:
        return {
          ...state,
          loading: false,
          user: action.payload,
        };
      case authtype.SETTING_PRO_PERSONAL_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case authtype.SETTING_PRO_BUSINESS_SUCCESS:
        return {
          ...state,
          loading: false,
          user: action.payload,
        };
      case authtype.SETTING_PRO_BUSINESS_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case authtype.SETTING_ACC_BUSINESS_SUCCESS:
        return {
          ...state,
          loading: false,
          user: action.payload,
        };
      case authtype.SETTING_ACC_BUSINESS_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case authtype.SETTING_ACC_PERSONAL_SUCCESS:
        return {
          ...state,
          loading: false,
          user: action.payload,
        };
      case authtype.SETTING_ACC_PERSONAL_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case authtype.SETTING_LOCATION_SUCCESS:
        return {
          ...state,
          loading: false,
          user: action.payload,
        };
      case authtype.SETTING_LOCATION_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case authtype.SETTING_PRIVACY_SUCCESS:
        return {
          ...state,
          loading: false,
          user: action.payload,
        };
      case authtype.SETTING_PRIVACY_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      
      case authtype.SETTING_NOTIFICATIONS_SUCCESS:
        return {
          ...state,
          loading: false,
          user: action.payload,
        };
      case authtype.SETTING_NOTIFICATIONS_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case authtype.UPDATE_CITY_SUCCESS:
        return {
          ...state,
          loading: false,
          user: action.payload,
        };
      case authtype.UPDATE_CITY_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case authtype.PROFILE_UPDATE_SUCCESS:
        return {
          ...state,
          loading: false,
          user: action.payload,
        };
      case authtype.PROFILE_UPDATE_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case authtype.USER_DEACTIVATE_SUCCESS:
        return {
          ...state,
          loading: false,
          user: action.payload,
        };
      case authtype.USER_DEACTIVATE_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
        case authtype.DASH_CITY_SUCCESS:
          return {
            ...state,
            loading: false,
            userCity: action.payload,
          };
        case authtype.DASH_CITY_FAIL:
          return {
            ...state,
            loading: false,
            error: action.payload,
          };
      
    default:
      return state;
  }
};

export default authReducer;
