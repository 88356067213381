import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { BsCaretDownFill, BsThreeDotsVertical } from "react-icons/bs";
import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import "../css/pagination.css";
import { GrFormPrevious } from "react-icons/gr";
import { MdOutlineNavigateNext } from "react-icons/md";
import { Server2 } from "../../redux/actions/auth/auth.actions";
import { LoadBoot } from "../../components/LoadBoot";

const PER_PAGE = 100;

const PersonalUserTable = () => {
  const [personal, setPersonalUser] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const userData = useSelector((state) => state?.auth?.user?.user);
  const [q, setQ] = useState("");
  const [type, setType] = useState(null);

  //search function
  function searchData(e) {
    if(type==null){
      return personal.filter((user) => {
        if (q === "") {
          return user;
        } else if (user.fname.toLowerCase().includes(q.toLowerCase())) {
          return user;
        } else if (user.lname.toLowerCase().includes(q.toLowerCase())) {
          return user;
        } else if (user.email.toLowerCase().includes(q.toLowerCase())) {
          return user;
        }
      });
    }else{
      return personal.filter((user) => {
        if(user.type == type){
          return user;
        }
      })
    }
  }
  // API'S MakeAdminChange Starts
  const handelChangAdmin = (e) => {
    const data = {
      email: e?.email,
      id: e?._id,
      role: e?.role === "user" ? "admin" : "user",
    };
    let config = {
      method: "post",
      url: Server2 + "/users/change-role",
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data, "i am res---");
        getUsersData();
      })
      .catch(function (error) {
        console.log(error, "error Delete User");
      });
  };

  const disableUser = (e) => {
    const data = {
      email: e?.email,
      id: e?._id,
    };
    let config = {
      method: "post",
      url: Server2 + "/admin/disable-user",
      data: data,
    };

    axios(config)
      .then(function (response) {
        getUsersData();
        console.log(response.data, "i am res---");
      })
      .catch(function (error) {
        console.log(error, "error Delete User");
      });
  };

  const enableUser = (e) => {
    const data = {
      email: e?.email,
      id: e?._id,
      disable:true
    };
    let config = {
      method: "post",
      url: Server2 + "/admin/enable-user",
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data, "i am res---");
        getUsersData();
      })
      .catch(function (error) {
        console.log(error, "error Delete User");
      });
  };

  // Get User data Api
  const getUsersData = (e) => {
    const data = {
      email: userData?.email,
      id: userData?._id,
    };
    let config = {
      method: "post",
      url: Server2 + "/admin/get-all",
      data: data,
    };
    axios(config)
      .then((response) => {
        if (e !== undefined) {
          let a = [];
          response.data.filter((user) => {
            if (user.type == e) {
              a.push(user);
            }
            setPersonalUser(a);
          });
        } else {
          setPersonalUser(response.data);
        }
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    setLoading(true);
    getUsersData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //Pagination handel
  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }
  //pagination
  const offset = currentPage * PER_PAGE;
  const currentPageData = searchData()
    .slice(offset, offset + PER_PAGE)
    .map((user, index) => (
      <tbody key={index}>
        <tr>
          <td>
            <div className="d-flex px-3 py-1">
              <div>
                <img
                  src={
                    user.profile_pic
                      ? user.profile_pic
                      : "/img/upload-photo.png"
                  }
                  className="avatar avatar-sm me-3"
                  alt="user1"
                />
              </div>
              <div className="d-flex flex-column justify-content-center">
                <h6 className="mb-0 text-sm text-capitalize ">
                  {user.fname} {user.lname}
                </h6>
                <p className="text-xs text-secondary mb-0">{user.email}</p>
              </div>
            </div>
          </td>
          <td className="align-middle">
            <span
              className={` badge badge-sm ${
                user.type === true
                  ? "bg-gradient-primary"
                  : "bg-gradient-secondary"
              }`}
            >
              {user.type === true ? "Business User" : "Personal User"}
            </span>
          </td>
          <td className="align-middle">
            <span
              className={`fs-6 fw-bold ${
                user.deactivated_by_admin === true
                  ? "text-danger"
                  : "text-success"
              }`}
            >
              {user.deactivated_by_admin ? "Disabled" : "Live"}
            </span>
          </td>
          <td className="align-middle ">
            <span
              className={`badge badge-sm ${
                user.is_online ? "bg-gradient-success" : "bg-gradient-secondary"
              }`}
            >
              {user.is_online ? "Online" : "offline"}
            </span>
          </td>

          <td className="">
            <Dropdown className=" mt-n2">
              <Dropdown.Toggle
                className="border-0 bg-transparent togglebtn d-flex "
                variant=""
                id="dropdown-basic"
                aria-expanded="false"
              >
                <BsThreeDotsVertical className="mt-3 me-3" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu ">
                {user.deactivated_by_admin === true ? (
                  <Dropdown.Item onClick={() => enableUser(user)}>
                    Unblock
                  </Dropdown.Item>
                ) : (
                  <Dropdown.Item onClick={() => disableUser(user)}>
                    Block
                  </Dropdown.Item>
                )}
                {/* {userData?.role === "super-admin" ? (
                  <Dropdown.Item onClick={() => handelChangAdmin(user)}>
                    {user?.role === "admin" ? "Remove Admin" : "Make Admin"}
                  </Dropdown.Item>
                ) : null} */}
              </Dropdown.Menu>
            </Dropdown>
          </td>
        </tr>
      </tbody>
    ));

  const pageCount = Math.ceil(searchData().length / PER_PAGE);

  return (
    <>
      {loading ? (
        <LoadBoot />
      ) : (
        <>
          <div className="container-fluid  ">
            <div className="row ">
              <div className="col-12 ">
                <div className="card  ">
                  <div className="card-header pb-0 d-flex justify-content-between">
                    <h6 className="pt-2"> Users </h6>
                    <div className="wrapper  ">
                      <div className="search-wrappe  ">
                        <label htmlFor="search-form">
                          <input
                            type="search"
                            name="search-form"
                            id="search-form"
                            className="form-control border-0 rounded  admin_search_bar"
                            style={{ backgroundColor: "#ECECEC" }}
                            placeholder=" Type to Search ..."
                            value={q}
                            onChange={(e) => setQ(e.target.value)}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div
                    className="card-body px-0 pt-0 pb-2 task "
                    style={{ height: "73vh", overflow: "auto" }}
                  >
                    <div className="table-responsive p-0">
                      <table className="table align-items-center mb-0">
                        <thead>
                          <tr>
                            <th className="text-uppercase">User</th>
                            <th className="">
                              <Dropdown className="m-0 p-0">
                                <Dropdown.Toggle
                                  className=" border-0"
                                  variant=""
                                  id="dropdown-basic"
                                  aria-expanded="false"
                                >
                                  <span
                                    className="fs-6 fw-bold text-uppercase"
                                    style={{ color: "#67748e" }}
                                  >
                                    Type{" "}
                                    <BsCaretDownFill size={12}/>
                                  </span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-menu">
                                  <Dropdown.Item
                                    onClick={() => {
                                      setType(null);
                                    }}
                                  >
                                    <span className="fw-bold">
                                      All User
                                    </span>
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => {
                                      setType(false);
                                    }}
                                  >
                                    <span className="fw-bold">
                                      Personal User
                                    </span>
                                  </Dropdown.Item>

                                  <Dropdown.Item
                                    onClick={() => {
                                      setType(true);
                                    }}
                                  >
                                    <span className="fw-bold">
                                      Business User
                                    </span>
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                              
                            </th>
                            <th className="text-uppercase">
                              <span className="ms-n3 mt-2 fs-6 fw-bold">
                                Account
                              </span>
                            </th>
                            <th className="text-uppercase">
                              <span className="ms-n3 mt-2 fs-6 fw-bold">
                                Avaliabity
                              </span>
                            </th>
                            <th className="text-uppercase">Actions</th>
                          </tr>
                        </thead>
                        {currentPageData}
                      </table>
                    </div>

                    {searchData().length > 99 ? (
                      <div
                        className=" mt-4"
                        style={{
                          padding: 2,
                          boxSizing: "border-box",
                          width: "100%",
                        }}
                      >
                        <ReactPaginate
                          onPageChange={handlePageClick}
                          activeClassName={"item active "}
                          breakClassName={"item break-me "}
                          breakLabel={"..."}
                          containerClassName={"pagination"}
                          disabledClassName={"disabled-page"}
                          marginPagesDisplayed={2}
                          nextClassName={"item next "}
                          nextLabel={
                            <MdOutlineNavigateNext
                              style={{ fontSize: 18, width: 150 }}
                            />
                          }
                          pageCount={pageCount}
                          pageClassName={"item pagination-page "}
                          pageRangeDisplayed={2}
                          previousClassName={"item previous"}
                          previousLabel={
                            <GrFormPrevious
                              style={{ fontSize: 18, width: 150 }}
                            />
                          }
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PersonalUserTable;
