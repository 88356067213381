import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { Button, Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { GrFormPrevious } from "react-icons/gr";
import "../css/pagination.css";
import { MdOutlineNavigateNext } from "react-icons/md";
import { Server2 } from "../../redux/actions/auth/auth.actions";
import MicroblogActivities from "../../Pages/home/AddMicroblogPopup/MicroblogActivities";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import { BsCaretDownFill } from "react-icons/bs";

const PER_PAGE = 100;

const DeletionActivities = ({setPopupStatus, setPopupData}) => {
  const userData = useSelector((state) => state?.auth?.user?.user);
  const [deletions, setDeletions] = useState([]);
  const navigate = useNavigate()
  console.log(deletions,"i am deletions")
  const [currentPage, setCurrentPage] = useState(0);
  const [MicroData, setMicroData] = useState([]);
  const [microblogOpen, setMicroblogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [q, setQ] = useState("");

  function searchData() {
    // return activities;
    return deletions.filter((user) => {
      if (q === "") {
        return user;
      } else if (user.fname.toLowerCase().includes(q.toLowerCase())) {
        return user;
      } else if (user.lname.toLowerCase().includes(q.toLowerCase())) {
        return user;
      } else if (user.email.toLowerCase().includes(q.toLowerCase())) {
        return user;
      } else if (user.type.toLowerCase().includes(q.toLowerCase())) {
        return user;
      }
    });
  }

  // API'S MakeDeletionApproved Starts
  const getDeletionActivities = () => {
    const data = {
      id: userData?._id,
    };
    let config = {
      method: "get",
      url: Server2 + "/admin/get-deletions",
      data: data,
    };

    axios(config)
      .then(function (response) {
        setDeletions(response.data);
        setLoading(false);
        // console.log(response.data)
      })
      .catch(function (error) {
        console.log(error, "error Delete User");
      });
  };

  // API'S Approved request Starts

  const handelUndoDeletion = (e) =>{
    const endPoint = e.type === 'Status Deleted'?"/status/delete-status":e.type === 'Microblog Deleted'?"/microblogs/disable":e.type === 'Event Deleted'?"/events/disable":"/admin/enable-user"
      const data = {
        room: e.activity_id,
        id: e.activity_id,
        eventId: e.activity_id,
        disable:false
      };
      let config = {
        method: "post",
        url: Server2 + endPoint,
        data: data,
      };
        axios(config)
          .then((response) => {
            getDeletionActivities() 
          })
          .catch((error) => console.error("Error fetching data:", error));
  }

  const handleView = (e) =>{
    if(e.type === 'Status Deleted'){
      setPopupData(e.activity_id)
      setPopupStatus(true)
    } else if (e.type === 'Microblog Deleted'){
      var config = {
        method: "GET",
        url:
          Server2 +
          `/microblogs/room?room=${e.activity_id}`,
      };
      axios(config)
        .then((response) => {
          if (response.data) {
            setMicroData([response.data])
            setMicroblogOpen(true)
          }
        })
        .catch(function (error) {
          console.log("error micro chat get ", error);
        });

    } else if (e.type === "Event Deleted"){
      navigate("/Event", {
        state: {
          item: {
            _id:e.activity_id
          },
        },
      });
    }
    else{
      navigate("/Profiless", {
        state: {
          item: e.activity_id,
        },
      })
    }
  }

  useEffect(() => {
    setLoading(true);
    getDeletionActivities();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //pagination function
  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }

  //pagination
  const offset = currentPage * PER_PAGE;
  const currentPageData =
    deletions.length === 0
      ? null
      : searchData().slice(offset, offset + PER_PAGE).map((user, index) => (
          <tbody key={index}>
            <tr>
              <td>
                <div className="d-flex px-2 py-1">
                  <div>
                    <img
                      src={
                        user?.profile_pic
                          ? user?.profile_pic
                          : "/img/upload-photo.png"
                      }
                      className="avatar avatar-sm me-3"
                      alt="user?1"
                    />
                  </div>
                  <div className="d-flex flex-column justify-content-center">
                    <h6 className="mb-0 text-sm text-capitalize ">
                      {user?.fname} {user?.lname}
                    </h6>
                    <p className="text-xs text-secondary mb-0">{user?.email}</p>
                  </div>
                </div>
              </td>
              <td>
                <div className="d-flex px-2 py-1">
                  <div>
                    <img
                      src={
                        user?.profile_pic_by
                          ? user?.profile_pic_by
                          : "/img/upload-photo.png"
                      }
                      className="avatar avatar-sm me-3"
                      alt="user?1"
                    />
                  </div>
                  <div className="d-flex flex-column justify-content-center">
                    <h6 className="mb-0 text-sm text-capitalize ">
                      {user?.fname_by} {user?.lname_by}
                    </h6>
                    <p className="text-xs text-secondary mb-0">{user?.email_by}</p>
                  </div>
                </div>
              </td>
              <td>
                <p className="align-middle">
                  {user?.type}
                </p>
              </td>
              <td>
                <p className="align-middle">
                <span style={{fontSize:"14px" , fontWeight:500}}>{moment(user?.time).format("MMMM Do, YYYY  h:mm A")}</span>
                </p>
              </td>
              <td  className="align-middle ">
                <div className="d-flex justify-content-center">

                <Button style={{ background: '#6148a1', border: 'none', marginRight:"10px" }} 
                    onClick={() =>{
                      handleView(user)
                  } }
                >View</Button>
                <Dropdown className="border-0 rounded-2 add-btn fw-bold">
                  <Dropdown.Toggle className="border-0" id="dropdown-basic" aria-expanded="false" style={{backgroundColor: "#6148a1"}}>
                    <button type="button" className="bg-transparent border-0 text-white" data-bs-toggle="button" >
                      <spam className="d-inline postition-relative text-white">
                          Take Action
                      </spam>
                    </button>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="w-100 text-center">
                    <Dropdown.Item eventKey="1" onClick={() => {handelUndoDeletion(user)}} style={{fontSize: 13}}>
                      Undo Deletion
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                      </div>
              </td>
            </tr>
          </tbody>
        ));

  const pageCount = Math.ceil(searchData().length / PER_PAGE);

  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center">
          <div
            className="spinner-border"
            role="status"
            style={{ width: "6rem", height: "6rem", color: "#6148A1" }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <div className="container-fluid ">
            <div className="row">
              <div className="col-12">
                <div className="card mb-4">
                  <div className="card-header pb-0">
                    <h6>Deletion Activities</h6>
                  </div>
                  <div
                    className="card-body px-0 pt-0 pb-2 task"
                    style={{ height: "73vh", overflow: "auto" }}
                  >
                    <div className="table-responsive p-0">
                      {currentPageData ? (
                        <table className="table align-items-center mb-0">
                          <thead>
                            <tr>
                              <th className="text-uppercase">Created User</th>
                              <th className="text-uppercase">Deleted User</th>
                              <th className="">
                              <Dropdown className="">
                                <Dropdown.Toggle
                                  className=" border-0"
                                  variant=""
                                  id="dropdown-basic"
                                  aria-expanded="false"
                                >
                                  <span
                                    className="fs-6 fw-bold text-uppercase ms-5"
                                    style={{ color: "#67748e" }}
                                  >
                                    Type{" "}
                                    <BsCaretDownFill size={12}/>
                                  </span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-menu">
                                  <Dropdown.Item
                                    onClick={() => {
                                      setQ("");
                                    }}
                                  >
                                    <span className="fw-bold">All</span>
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => {
                                      setQ("Post Deleted");
                                    }}
                                  >
                                    <span className="fw-bold">Post</span>
                                  </Dropdown.Item>

                                  <Dropdown.Item
                                    onClick={() => {
                                      setQ("Microblog Deleted");
                                    }}
                                  >
                                    <span className="fw-bold">Microblogs</span>
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => {
                                      setQ("Event Deleted");
                                    }}
                                  >
                                    <span className="fw-bold">Events</span>
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </th>
                              <th className="text-uppercase">Days</th>
                              <th className="text-uppercase">Actions</th>
                            </tr>
                          </thead>
                          {currentPageData}
                        </table>
                      ) : (
                        <div className="d-flex justify-content-center w-100">
                          <p>No Deletion Activities</p>
                        </div>
                      )}
                    </div>

                    {searchData().length > 99 ? (
                      <div
                        className=" mt-4"
                        style={{
                          padding: 2,
                          boxSizing: "border-box",
                          width: "100%",
                        }}
                      >
                        <ReactPaginate
                          activeClassName={"item active "}
                          breakClassName={"item break-me "}
                          breakLabel={"..."}
                          containerClassName={"pagination"}
                          t
                          disabledClassName={"disabled-page"}
                          marginPagesDisplayed={2}
                          nextClassName={"item next "}
                          nextLabel={
                            <MdOutlineNavigateNext
                              style={{ fontSize: 18, width: 150 }}
                            />
                          }
                          onPageChange={handlePageClick}
                          pageCount={pageCount}
                          pageClassName={"item pagination-page "}
                          pageRangeDisplayed={2}
                          previousClassName={"item previous"}
                          previousLabel={
                            <GrFormPrevious
                              style={{ fontSize: 18, width: 150 }}
                            />
                          }
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {microblogOpen && (
            <div className="main-event-bg-dash" style={{left:0}}>
              <MicroblogActivities
                ActivitiesClose={(e) => setMicroblogOpen(e)}
                MicroData={MicroData}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default DeletionActivities;