import React, { useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { AiOutlineHeart } from 'react-icons/ai'
import { BsChatLeftTextFill, BsHeartFill, BsThreeDotsVertical } from 'react-icons/bs'
import { FiX } from 'react-icons/fi'
import { IoMdSend } from 'react-icons/io'
import InputEmoji from 'react-input-emoji'
import ModalImage from 'react-modal-image'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import moment from "moment/moment";
import { Server2, Socket } from '../../../redux/actions/auth/auth.actions'
import axios from 'axios'
import { useEffect } from 'react'
import Linkify from 'linkify-react'
import LinkPreview from '../../../components/LinkPreview'

export const StatusPopup = ({ Items, setPopupStatus }) => {
  const userData = useSelector((state) => state?.auth?.user?.user)
  const citynew = userData?.location.extraCityToFollow0.cityName?userData?.location.extraCityToFollow0.cityName.split(","):null
  const cityname2 = citynew?citynew[0]:null
  const navigate = useNavigate();
  //   states Calling
  const [BtnDisable, setBtnDisable] = useState(false);
  // const [Loader, setLoader] = useState(true);
  const [Data, setData] = useState(null);
  const [Loading, setLoading] = useState(true);
  const [Comment, setComment] = useState("");
  const [LikesPopup, setLikesPopup] = useState(false);
  const string = Data?.message.match(/[a-zA-Z0-9.,/'";:!@$%&*()_+=-±~?#€[{}^><|/]+/)
  var ranges = Data?.message.match([
    "(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])", // U+1F680 to U+1F6FF
  ]);
  useEffect(() =>{
    Get();
  },[]) // eslint-disable-line react-hooks/exhaustive-deps
  const Get =() =>{
    var config = {
      method: "get",
      url: Server2+`/status/getStatusByStatusId?id=${Items}`,
    };

    axios(config)
      .then(function (response) {
        if(response.data){
         setData(response.data)
         setLoading(false)
        }else{
         setLoading(false)
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const Likes = (e) => {
    setBtnDisable(true);
    let data = JSON.stringify({
      status: e.status._id,
      from: userData._id,
      type: e.type,
      statuslike: false,
    });

    var config = {
      method: "post",
      url: Server2 + "/status//new-like",
      headers: { "Content-Type": "application/json" },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Get();
        setBtnDisable(false);
        if (e.type === true && userData._id !== e.status.userid) {
          let data = {
            me: userData._id,
            createdBy: e.status.from,
            reply: false,
            userId: e.status.userid,
            status: e.status._id,
            city:cityname2
          }
          Socket.emit('add-status-like', data)
            const data2 = {
              to: Data.userid,
            };
            Socket.emit("new-notification", data2);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const CommentUpdate = (e) => {
    if (Comment.length !== "") {
      let data = JSON.stringify({
        status: e._id,
        userId: e.userid,
        from: userData._id,
        replyType: "Text",
        message: Comment,
        fname: userData.fname,
        lname: userData.type === false ? userData.lname : "",
        profile_pic: userData.profile_pic,
      });

      var config = {
        method: "post",
        url: Server2 + "/status/new-comment",
        headers: { "Content-Type": "application/json" },
        data: data,
      };

      axios(config)
        .then(function (response) {
          if (response.data) {
            setComment("");
            Get();
            if(userData._id !== Data.userid){
            let data = {
              me: userData._id,
              createdBy: e.from,
              reply: false,
              status: e._id,
              userId: e.userid,
              city:cityname2
            }
            Socket.emit('add-status-comment', data)
              const data2 = {
                to: Data.from,
              };
              Socket.emit("new-notification", data2);
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      alert("please add some text in comments");
    }
  };
  // const DeletePost = (e) => {
  //   var data = JSON.stringify({
  //     id: e,
  //     from: userData._id,
  //   });
  //   var config = {
  //     method: "POST",
  //     url: Server2 + "/status/delete-status",
  //     data: data,
  //     headers: { "Content-Type": "application/json" },
  //   };
  //   axios(config)
  //     .then((response) => {
  //       if(response.data){
  //         setPopupStatus(false)
  //       }
  //     })
  //     .catch((error) => {
  //       console.log("er", error);
  //     });
  // };
  // const handleThreeDots = (e) => {
  //   DeletePost(e);
  // };
  const DeleteComment = (e) => {
    let data = JSON.stringify({
      status_id: e.status,
      reply_id: e.Comment,
    });

    var config = {
      method: "post",
      url: Server2 + "/status/delete-comment",
      headers: { "Content-Type": "application/json" },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Get();
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <>
      <div
        style={{
          height: "100vh",
          width: "100%",
          position: "absolute",
          top: "0px",
          zIndex: "1000",
          backdropFilter: "blur(5px)",
          backgroundColor: "rgba(85, 71, 119, 0.6)",
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div className="col-md-6 col-sm-12 col-12">
            <div
              className="border rounded-2 statusMain"
            >
              {Loading === true?
              <>

                <div className="rounded-1 py-2 text-white d-flex align-items-center justify-content-between"
                style={{ backgroundColor: "#6148a1" }}>
                <p className="fw-bold mx-3 my-2"></p>

                <FiX
                  className="fs-4 text-white me-3"
                  role="button"
                  onClick={() => setPopupStatus(false)}
                />
              </div>
              <div style={{display:"flex", justifyContent:"center",margin:"5%"}}><div className="loading2"></div></div>
              </>
              :
              <>
              {Data===null?
              <>
              <div className="rounded-1 py-2 text-white d-flex align-items-center justify-content-between"
                style={{ backgroundColor: "#6148a1" }}>
                <p className="fw-bold mx-3 my-2"></p>

                <FiX
                  className="fs-4 text-white me-3"
                  role="button"
                  onClick={() => setPopupStatus(false)}
                />
              </div>
              <div className='text-center py-5 my-3 mx-5 fs-5 fw-bold' style={{background:"#ECECEC"}}>
               This Post has been deleted 
              </div>
              </>
              :
              <>
              <div className="rounded-1 py-1 text-white d-flex align-items-center justify-content-between"
                style={{ backgroundColor: "#6148a1" }}>
                <p className="fw-bold text-white mx-3 my-2 text-white">{`${Data?.fname}'s Post`}</p>

                <FiX
                  className="fs-4 text-white me-3"
                  role="button"
                  onClick={() => setPopupStatus(false)}
                />
              </div>
              <div style={{overflowY:"auto" , height:"68vh" , margin:"1px 0px"}}>
              <div className="d-flex  d-flex justify-content-between ">
                <div
                  className="user-bprofile d-flex justify-content-start align-items-center"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (Data.userid === userData?._id) {
                      navigate("/myProfile");
                    } else {
                      navigate("/Profiless", {
                        state: {
                          item: Data.userid,
                        },
                      });
                    }
                  }}
                >
                  <img
                    className="img-profile"
                    src={
                      Data.profile_pic
                        ? Data.profile_pic
                        :
                        "/img/upload-photo.png"
                    }
                    alt="Admin"
                  />
                  <div className="lh-1">
                    <span className="fw-bold lh-1 title">
                      {Data.fname}&nbsp; {Data.lname}
                    </span>{" "}
                    <br />
                    <span className="lh-1 date">
                      {moment(Data.time).format("MMMM Do, YYYY  h:mm A")}
                    </span>
                  </div>
                </div>

                {/* {Data.userid == userData?._id ? (
                                     <Dropdown>
                                    <Dropdown.Toggle
                                        className="border-0 bg-transparent togglebtn d-flex"
                                        variant=""
                                        id="dropdown-basic"
                                        aria-expanded="false"
                                    >
                                        <BsThreeDotsVertical className="mt-3 me-3" />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdown-menu">
                                        <Dropdown.Item
                                          onClick={() => handleThreeDots(Data._id)}
                                        >
                                            Delete Post
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown> 
                                ):null} */}
              </div>

              <div className="ms-3 ps-5 me-3 ">
              {Data.message.match(/http[s]?:\/\/\S+/)? 
                     <>
                     <Linkify >{Data.message}</Linkify>
                      <LinkPreview url={Data.message} />
                     </>
                    :  <p
                    className="desc"
                    style={{
                      wordWrap: "break-word",
                      fontSize: string ? "13px" : ranges?.input.length < 7 ? "60px" : "13px",
                    }}
                  >
                    {Data.message}
                  </p> }
                {Data.filename ? (
                  Data.status_type === "video" ? (
                    <video
                      width="100%"
                      height={"350px"}
                      style={{ borderRadius: "6px" }}
                      controls
                    >
                      <source src={Data.filename} />
                    </video>
                  ) : (
                    <div className="">
                      <ModalImage
                      hideDownload	
                        className="pro-img border rounded-3 w-100 mt-0"
                        small={Data.filename}
                        large={Data.filename}
                        alt={"Status Updates"}
                      />
                    </div>
                  )
                ) : null
                }

                <div className="desc-icons p-1 d-flex justify-content-end align-items-center position-relative" onMouseLeave={() => {setLikesPopup(false)}}>
                      {Data.replies.length}
                      <BsChatLeftTextFill className="icons-chat m-2" />
                      {<div style={{cursor:"pointer"}} onMouseEnter={() => {
                          setLikesPopup(true)}}
                         >{Data.likes.length}</div>}
                      {Data.likes.length > 0 ? (
                        <div>

                          <button
                            style={{ border: "none", backgroundColor: "transparent" }}
                            disabled={BtnDisable === true ? true : false}
                            onClick={(e) => {
                              Likes({
                                status: Data,
                                type: false,
                                event: e,
                              });
                            }}

                          >

                            <BsHeartFill
                              size={14}
                              className="icons-heart m-2"
                              color="#D84076"

                            />
                          </button>
                          {
                            LikesPopup === true ?
                              <div style={{
                                position: "absolute",
                                top: "30px",
                                right: "1px",
                                backgroundColor: "#fff",
                                width: "100px",
                                height: "fit-content",
                                maxHeight: "150px",
                                borderRadius: "4px",
                                overflow: "auto",
                                zIndex: 100,
                                padding: "5px 5px",
                                textAlign: "center"
                              }}>
                                {
                                  Data.likes.map((e, index) => {
                                    return (
                                      <>
                                        <span style={{ color: "#000000", lineHeight: 0.7, marginbottom: "0px" }}>{e.fname} {e.lname}</span><br />
                                      </>

                                    )
                                  })
                                }
                              </div>
                              :
                              null
                          }
                        </div>
                      ) : (
                        <button
                          style={{ border: "none", backgroundColor: "transparent" }}
                          disabled={BtnDisable === true ? true : false}
                          onClick={(e) => {
                            Likes({
                              status: Data,
                              type: true,
                              event: e,
                            });
                            // setLike(true);
                          }}
                        >
                          <AiOutlineHeart
                            color="#D84076"
                            className="icons-heart m-2"
                            size={16}
                          />
                        </button>
                      )}
                    </div>
                {Data.replies.length > 0 ? (
                  <div className="border"></div>
                ) : (
                  ""
                )}
                {Data.replies.map((Com , index) => {
                  const string=Com?.message.match(/[a-zA-Z0-9.,/'";:!@$%&*()_+=-±~?#€[{}^><|/]+/)
                      var ranges = Com?.message.match([
                        "(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])", // U+1F680 to U+1F6FF
                      ]);
                      return (
                <div key={index}>
                  <div className="d-flex justify-content-between ">
                    <div
                      className="user-bprofile d-flex justify-content-start align-items-center"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (Com.from !== userData._id) {
                          navigate("/Profiless", {
                            state: {
                              item: Com.from,
                            },
                          });
                        }else{
                          navigate("/myProfile")
                        }
                      }}
                    >
                      <img
                        className="img-profile"
                        src={
                            Com.profile_pic
                              ? Com.profile_pic
                              :
                          "/img/upload-photo.png"
                        }
                        alt="Admin"
                      />
                      <div className="lh-1">
                        <span className="fw-bold lh-1 title">
                          {Com.fname}&nbsp; {Com.lname}
                        </span>
                      </div>
                    </div>
                    {userData?._id === Com.from ? (
                              <Dropdown>
                                <Dropdown.Toggle
                                  className="border-0 bg-transparent togglebtn d-flex"
                                  variant=""
                                  id="dropdown-basic"
                                  aria-expanded="false"
                                >
                                  <BsThreeDotsVertical className="mt-3 me-3" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-menu">
                                  <Dropdown.Item
                                    onClick={() =>
                                      DeleteComment({
                                        status: Data._id,
                                        Comment: Com._id,
                                      })
                                    }
                                  >
                                    Delete Comment
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            ) : (
                              ""
                            )}
                  </div>
                  <p
                    className="text-start ms-5  ps-3"
                    style={{
                      fontSize:
                       string?"13px": ranges?.input.length < 8 ? "60px" : "13px",
                      wordWrap: "break-word",
                    }}
                  >
                    {Com.message}
                  </p>
                  <div></div>
                </div>
                );
                    })}

                <div
                  id="comment"
                  className="d-flex align-items-center position-relative"
                >
                  <InputEmoji
                    onEnter={() => CommentUpdate(Data)}
                    cleanOnEnter
                    value={Comment}
                    onChange={(e) => setComment(e)}
                    placeholder="Write a Comment!"
                    borderRadius={5}
                  />
                  <IoMdSend
                    className="send-btn p-2"
                    style={{ width: "40px" }}
                    size={40}
                  onClick={() => CommentUpdate(Data)}
                  />
                </div>
              </div>
            </div>
              </>
              }
              </>
              }
          </div>
          </div>
        </div>
      </div>
    </>
  )
}


